body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.mb-4 {
    position: relative;
}

.block {
    position: relative;
}

.transaction-block {
    position: absolute;
    right: 10px;
    margin-top: 10px;
}

.preloader-data:only-of-type {
    display: none;
}

.preloader-data.enable {
    display: block;
}

.preloader-data {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9998;
    width: 100%;
    height: 100%;
    display: none;
}

.preloader-data-enable {
    position: absolute;
    left: 50%;
    z-index: 9998;
    top: 50%;
    transform: translate(-50%,-50%);
}

.disabled-div {
    filter: blur(4px);
    pointer-events: none;
    animation: blur 1s ease 1s infinite;
    -webkit-animation: blur 1s ease 1s infinite;
    -moz-animation: blur 1s ease 1s infinite;
}

.enable-div {
    display: none !important;
}

.block-link {
    width: 48px;
    height: 48px;
}

#blockchainMegaMenu {
    cursor: pointer;
}

main {
    min-height: 651px;
}
.enrollment-icon {
    color: green;
}

.take-off-icon {
    color: red;
}

.hash-tag-main {
    max-width: 150px;
    display: inline-block;
    vertical-align: bottom
}

@media (max-width: 575px) {
    .hash-tag-main {
        max-width: 100%;
    }
}

.transaction-main-block-scrollbar {
    overflow: auto;
}

.text-counter {
    font-size: 35px;
    text-align: center;
    display: block;
}

.hr-space-text-counter {
    margin-top: 2px;
    margin-bottom: 10px;
}

.bg-light {
    background: url('../images/bg.png') no-repeat;
    background-size: initial;
    background-position: center top;
}

.lang-button {
    margin-left: 10px;
    font-size: 23px;
    height: 34px;
    cursor: pointer;
}

@keyframes blur {
    100% {
        -webkit-filter: blur(3px);
        -moz-filter: blur(3px);
        -o-filter: blur(3px);
        -ms-filter: blur(3px);
    }
    50% {
        -webkit-filter: blur(5px);
        -moz-filter: blur(5px);
        -o-filter: blur(5px);
        -ms-filter: blur(5px);
    }
    0% {
        -webkit-filter: blur(3px);
        -moz-filter: blur(3px);
        -o-filter: blur(3px);
        -ms-filter: blur(3px);
    }
}

.js-counter-loading:after {
    content: "...";
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    animation: ellipsis-dot 1s infinite;
    animation-fill-mode: fowards;
    width: 1.25em;
}

@keyframes ellipsis-dot {
    25% {
        content: "";
    }
    50% {
        content: ".";
    }
    75% {
        content: "..";
    }
    100% {
        content: "...";
    }
}

/** Preloader **/
jpZaXn {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.3s linear, visibility 0.2s linear 0.3s;
    transition: opacity 0.3s linear, visibility 0.2s linear 0.3s;
    height: 100vh;
    width: 100vw;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background: #01263F;
}

.hNaetI {
    z-index: 9999;
    position: fixed;
    top: 0;
    left: 0;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: opacity 0.3s linear, visibility 0.2s linear 0.3s;
    transition: opacity 0.3s linear, visibility 0.2s linear 0.3s;
    height: 100vh;
    width: 100vw;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    background: #01263F;
}

.VdKZR {
    width: 200px;
    height: 200px;
    position: relative;
    border: 3px solid transparent;
    border-radius: 50%;
    border-top-color: #fff;
    -webkit-animation: fabKkN 0.6s cubic-bezier(0.44, 0.39, 0.32, 1.28) infinite;
    animation: fabKkN 0.6s cubic-bezier(0.44, 0.39, 0.32, 1.28) infinite;
}

.VdKZR:after, .VdKZR:before {
    content: '';
    display: block;
    position: absolute;
    border: 3px solid transparent;
    border-radius: 50%;
}

.VdKZR:before {
    -webkit-animation: fPqJzg 1s linear infinite;
    animation: fPqJzg 1s linear infinite;
    top: 20px;
    bottom: 20px;
    left: 20px;
    right: 20px;
    border-top-color: inherit;
    -webkit-filter: brightness(150%);
    filter: brightness(150%);
}

.VdKZR:after {
    -webkit-animation: fPqJzg 2s linear infinite;
    animation: fPqJzg 2s linear infinite;
    top: 43px;
    bottom: 43px;
    left: 43px;
    right: 43px;
    -webkit-filter: brightness(180%);
    filter: brightness(180%);
    border-top-color: inherit;
}

@-webkit-keyframes fabKkN {
    from {
        -webkit-transform: rotate(0deg) scale(0.4, 0.4);
        -ms-transform: rotate(0deg) scale(0.4, 0.4);
        transform: rotate(0deg) scale(0.4, 0.4);
    }
    to {
        -webkit-transform: rotate(360deg) scale(0.4, 0.4);
        -ms-transform: rotate(360deg) scale(0.4, 0.4);
        transform: rotate(360deg) scale(0.4, 0.4);
    }
}

@keyframes fabKkN {
    from {
        -webkit-transform: rotate(0deg) scale(0.4, 0.4);
        -ms-transform: rotate(0deg) scale(0.4, 0.4);
        transform: rotate(0deg) scale(0.4, 0.4);
    }
    to {
        -webkit-transform: rotate(360deg) scale(0.4, 0.4);
        -ms-transform: rotate(360deg) scale(0.4, 0.4);
        transform: rotate(360deg) scale(0.4, 0.4);
    }
}

@-webkit-keyframes fPqJzg {
    from {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

@keyframes fPqJzg {
    from {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(-360deg);
        -ms-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

/** Preloader **/

