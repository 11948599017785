body, html {
    height: 100%
}

.wrapper {
    height: auto !important;
    height: 100%;
    margin: 0 auto -99px;
    min-height: 100%;
    background-color: #f8f9fa !important
}

#push {
    height: 99px
}

body.dark-mode .wrapper {
    background-color: #01263f !important
}

.ui-autocomplete {
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 1px;
    z-index: 91199
}

body.dark-mode .ui-autocomplete {
    background-color: #01263f !important;
    border-color: #013558
}

.ui-autocomplete .left {
    float: left
}

.ui-autocomplete .right {
    float: right
}

.ui-autocomplete-category {
    padding: 5px 15px;
    margin: 0;
    font-weight: 700
}

.jvectormap-container {
    width: 100%;
    height: 100%
}

.hd-doc-search__category {
    display: block;
    color: #377dff
}

.ui-autocomplete-input.form-control:not(:last-child) {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem
}

.anomaly {
    background: #f9f9f9 !important
}

body.dark-mode .anomaly {
    background: #012c49 !important
}

tspan {
    text-decoration: none;
    text-shadow: none !important
}

body.dark-mode .highcharts-data-labels text {
    color: #a2b9c8 !important;
    fill: #a2b9c8 !important
}

body.dark-mode .u-ver-divider-left::before {
    border-color: #013558 !important
}

.u-ver-divider-left {
    position: relative
}

.u-ver-divider-left::before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-right: 1px solid #e7eaf3;
    content: ""
}

@media (max-width: 575.98px) {
    .u-ver-divider-left--none-sm::after {
        display: none
    }
}

@media (max-width: 767.98px) {
    .u-ver-divider-left--none-md::after {
        display: none
    }
}

@media (max-width: 991.98px) {
    .u-ver-divider-left--none-lg::after {
        display: none
    }
}

.u-label-inline {
    display: inline !important
}

.mn-3 {
    margin-top: -3px;
    margin-bottom: -3px
}

.mn-b3 {
    margin-bottom: -3px
}

.wordwrap {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word
}

a.address-tag {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block
}

@media (max-width: 760px) {
    .dropdown-menu-sm-right {
        right: 0;
        left: 0 !important
    }
}

.witget {
    height: 106px
}

.page-item-disabled {
    display: none
}

.container-banner {
    margin-top: 15px;
}

.container-banner .banner img {

}