/*!
/*!
 * Bootstrap v4.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2019 The Bootstrap Authors
 * Copyright 2011-2019 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */
:root {
    --blue: #3498db;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #de4437;
    --orange: #fd7e14;
    --yellow: #db9a04;
    --green: #28a745;
    --teal: #00c9a7;
    --cyan: #00dffc;
    --gray: #8c98a4;
    --gray-dark: #3e444a;
    --primary: #3498db;
    --secondary: #77838f;
    --success: #00c9a7;
    --info: #3498db;
    --warning: #db9a04;
    --danger: #de4437;
    --light: #f8f9fa;
    --dark: #1e2022;
    --white: #fff;
    --indigo: #2d1582;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: "Helvetica Neue", Helvetica, Arial, sans-serif;
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

*, :after, :before {
    box-sizing: border-box
}

html {
    font-family: sans-serif;
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: transparent
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block
}

body {
    margin: 0;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    text-align: left;
    background-color: #fff
}

[tabindex="-1"]:focus {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: .5rem
}

p {
    margin-top: 0;
    margin-bottom: 1rem
}

abbr[data-original-title], abbr[title] {
    text-decoration: underline;
    -webkit-text-decoration: underline dotted;
    text-decoration: underline dotted;
    cursor: help;
    border-bottom: 0;
    text-decoration-skip-ink: none
}

address {
    font-style: normal;
    line-height: inherit
}

address, dl, ol, ul {
    margin-bottom: 1rem
}

dl, ol, ul {
    margin-top: 0
}

ol ol, ol ul, ul ol, ul ul {
    margin-bottom: 0
}

dt {
    font-weight: 600
}

dd {
    margin-bottom: .5rem;
    margin-left: 0
}

blockquote {
    margin: 0 0 1rem
}

b, strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: #3498db;
    background-color: transparent
}

a, a:hover {
    text-decoration: none
}

a:hover {
    color: #1d6fa5
}

a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
    color: inherit;
    text-decoration: none
}

a:not([href]):not([tabindex]):focus {
    outline: 0
}

code, kbd, pre, samp {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
    font-size: 1em
}

pre {
    margin-top: 0;
    margin-bottom: 1rem;
    overflow: auto
}

figure {
    margin: 0 0 1rem
}

img {
    border-style: none
}

img, svg {
    vertical-align: middle
}

svg {
    overflow: hidden
}

table {
    border-collapse: collapse
}

caption {
    padding-top: .625rem;
    padding-bottom: .625rem;
    color: #8c98a4;
    text-align: left;
    caption-side: bottom
}

th {
    text-align: inherit
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button {
    border-radius: 0
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button, input, optgroup, select, textarea {
    margin: 0;
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

select {
    word-wrap: normal
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button
}

[type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled), button:not(:disabled) {
    cursor: pointer
}

[type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner, button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=date], input[type=datetime-local], input[type=month], input[type=time] {
    -webkit-appearance: listbox
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0;
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 0;
    margin-bottom: .5rem;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

output {
    display: inline-block
}

summary {
    display: list-item;
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-bottom: .5rem;
    font-weight: 300;
    line-height: 1.5
}

.h1, h1 {
    font-size: 2.03125rem
}

.h2, h2 {
    font-size: 1.625rem
}

.h3, h3 {
    font-size: 1.42188rem
}

.h4, h4 {
    font-size: 1.21875rem
}

.h5, h5 {
    font-size: 1.01563rem
}

.h6, h6 {
    font-size: .8125rem
}

.lead {
    font-size: 1.01563rem;
    font-weight: 300
}

.display-1 {
    font-size: 6rem
}

.display-1, .display-2 {
    font-weight: 300;
    line-height: 1.2
}

.display-2 {
    font-size: 5.5rem
}

.display-3 {
    font-size: 4.5rem
}

.display-3, .display-4 {
    font-weight: 300;
    line-height: 1.2
}

.display-4 {
    font-size: 3.5rem
}

hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #e7eaf3
}

.small, small {
    font-size: 80%;
    font-weight: 400
}

.mark, mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-inline, .list-unstyled {
    padding-left: 0;
    list-style: none
}

.list-inline-item {
    display: inline-block
}

.list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.blockquote {
    margin-bottom: 1rem;
    font-size: .8125rem
}

.blockquote-footer {
    display: block;
    font-size: 80%;
    color: #8c98a4
}

.blockquote-footer:before {
    content: "\2014\00A0"
}

.img-fluid, .img-thumbnail {
    max-width: 100%;
    height: auto
}

.img-thumbnail {
    padding: .25rem;
    background-color: #fff;
    border: 1px solid #e7eaf3;
    border-radius: .25rem
}

.figure {
    display: inline-block
}

.figure-img {
    margin-bottom: .5rem;
    line-height: 1
}

.figure-caption {
    font-size: 90%;
    color: #8c98a4
}

code {
    font-size: 87.5%;
    color: #e83e8c;
    word-break: break-word
}

a > code {
    color: inherit
}

kbd {
    padding: .2rem .4rem;
    font-size: 87.5%;
    color: #fff;
    background-color: #1e2022;
    border-radius: .125rem
}

kbd kbd {
    padding: 0;
    font-size: 100%;
    font-weight: 700
}

pre {
    display: block;
    font-size: 87.5%;
    color: #1e2022
}

pre code {
    font-size: inherit;
    color: inherit;
    word-break: normal
}

.pre-scrollable {
    max-height: 340px;
    overflow-y: scroll
}

.container {
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
    margin-right: auto;
    margin-left: auto
}

.VdKZR {
    border-top-color: #381b40 !important
}

/*.h1:not(.alert-heading), .h2:not(.alert-heading), .h3:not(.alert-heading), .h4:not(.alert-heading), .h5:not(.alert-heading), .h6:not(.alert-heading), h1:not(.alert-heading), h2:not(.alert-heading), h3:not(.alert-heading), h4:not(.alert-heading), h5:not(.alert-heading), h6:not(.alert-heading) {*/
    /*color: #000 !important;*/
/*}*/

@media (min-width: 576px) {
    .container {
        max-width: 540px
    }
}

@media (min-width: 768px) {
    .container {
        max-width: 720px
    }
}

@media (min-width: 992px) {
    .container {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container {
        max-width: 1140px
    }
}

.container-fluid {
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px;
    margin-right: auto;
    margin-left: auto
}

.row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -7.5px;
    margin-left: -7.5px
}

.no-gutters {
    margin-right: 0;
    margin-left: 0
}

.no-gutters > .col, .no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 7.5px;
    padding-left: 7.5px
}

.col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%
}

.col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    -ms-flex: 0 0 8.33333%;
    flex: 0 0 8.33333%;
    max-width: 8.33333%
}

.col-2 {
    -ms-flex: 0 0 16.66667%;
    flex: 0 0 16.66667%;
    max-width: 16.66667%
}

.col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    -ms-flex: 0 0 33.33333%;
    flex: 0 0 33.33333%;
    max-width: 33.33333%
}

.col-5 {
    -ms-flex: 0 0 41.66667%;
    flex: 0 0 41.66667%;
    max-width: 41.66667%
}

.col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    -ms-flex: 0 0 58.33333%;
    flex: 0 0 58.33333%;
    max-width: 58.33333%
}

.col-8 {
    -ms-flex: 0 0 66.66667%;
    flex: 0 0 66.66667%;
    max-width: 66.66667%
}

.col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    -ms-flex: 0 0 83.33333%;
    flex: 0 0 83.33333%;
    max-width: 83.33333%
}

.col-11 {
    -ms-flex: 0 0 91.66667%;
    flex: 0 0 91.66667%;
    max-width: 91.66667%
}

.col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    -ms-flex-order: -1;
    order: -1
}

.order-last {
    -ms-flex-order: 13;
    order: 13
}

.order-0 {
    -ms-flex-order: 0;
    order: 0
}

.order-1 {
    -ms-flex-order: 1;
    order: 1
}

.order-2 {
    -ms-flex-order: 2;
    order: 2
}

.order-3 {
    -ms-flex-order: 3;
    order: 3
}

.order-4 {
    -ms-flex-order: 4;
    order: 4
}

.order-5 {
    -ms-flex-order: 5;
    order: 5
}

.order-6 {
    -ms-flex-order: 6;
    order: 6
}

.order-7 {
    -ms-flex-order: 7;
    order: 7
}

.order-8 {
    -ms-flex-order: 8;
    order: 8
}

.order-9 {
    -ms-flex-order: 9;
    order: 9
}

.order-10 {
    -ms-flex-order: 10;
    order: 10
}

.order-11 {
    -ms-flex-order: 11;
    order: 11
}

.order-12 {
    -ms-flex-order: 12;
    order: 12
}

.offset-1 {
    margin-left: 8.33333%
}

.offset-2 {
    margin-left: 16.66667%
}

.offset-3 {
    margin-left: 25%
}

.offset-4 {
    margin-left: 33.33333%
}

.offset-5 {
    margin-left: 41.66667%
}

.offset-6 {
    margin-left: 50%
}

.offset-7 {
    margin-left: 58.33333%
}

.offset-8 {
    margin-left: 66.66667%
}

.offset-9 {
    margin-left: 75%
}

.offset-10 {
    margin-left: 83.33333%
}

.offset-11 {
    margin-left: 91.66667%
}

@media (min-width: 576px) {
    .col-sm {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-sm-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-sm-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-sm-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-sm-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-sm-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-sm-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-sm-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-sm-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-sm-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-sm-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-sm-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-sm-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-sm-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-sm-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-sm-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-sm-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-sm-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-sm-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-sm-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-sm-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-sm-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-sm-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-sm-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-sm-0 {
        margin-left: 0
    }

    .offset-sm-1 {
        margin-left: 8.33333%
    }

    .offset-sm-2 {
        margin-left: 16.66667%
    }

    .offset-sm-3 {
        margin-left: 25%
    }

    .offset-sm-4 {
        margin-left: 33.33333%
    }

    .offset-sm-5 {
        margin-left: 41.66667%
    }

    .offset-sm-6 {
        margin-left: 50%
    }

    .offset-sm-7 {
        margin-left: 58.33333%
    }

    .offset-sm-8 {
        margin-left: 66.66667%
    }

    .offset-sm-9 {
        margin-left: 75%
    }

    .offset-sm-10 {
        margin-left: 83.33333%
    }

    .offset-sm-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 768px) {
    .col-md {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-md-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-md-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-md-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-md-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-md-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-md-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-md-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-md-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-md-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-md-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-md-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-md-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-md-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-md-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-md-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-md-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-md-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-md-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-md-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-md-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-md-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-md-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-md-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-md-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-md-0 {
        margin-left: 0
    }

    .offset-md-1 {
        margin-left: 8.33333%
    }

    .offset-md-2 {
        margin-left: 16.66667%
    }

    .offset-md-3 {
        margin-left: 25%
    }

    .offset-md-4 {
        margin-left: 33.33333%
    }

    .offset-md-5 {
        margin-left: 41.66667%
    }

    .offset-md-6 {
        margin-left: 50%
    }

    .offset-md-7 {
        margin-left: 58.33333%
    }

    .offset-md-8 {
        margin-left: 66.66667%
    }

    .offset-md-9 {
        margin-left: 75%
    }

    .offset-md-10 {
        margin-left: 83.33333%
    }

    .offset-md-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 992px) {
    .col-lg {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-lg-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-lg-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-lg-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-lg-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-lg-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-lg-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-lg-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-lg-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-lg-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-lg-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-lg-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-lg-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-lg-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-lg-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-lg-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-lg-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-lg-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-lg-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-lg-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-lg-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-lg-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-lg-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-lg-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-lg-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-lg-0 {
        margin-left: 0
    }

    .offset-lg-1 {
        margin-left: 8.33333%
    }

    .offset-lg-2 {
        margin-left: 16.66667%
    }

    .offset-lg-3 {
        margin-left: 25%
    }

    .offset-lg-4 {
        margin-left: 33.33333%
    }

    .offset-lg-5 {
        margin-left: 41.66667%
    }

    .offset-lg-6 {
        margin-left: 50%
    }

    .offset-lg-7 {
        margin-left: 58.33333%
    }

    .offset-lg-8 {
        margin-left: 66.66667%
    }

    .offset-lg-9 {
        margin-left: 75%
    }

    .offset-lg-10 {
        margin-left: 83.33333%
    }

    .offset-lg-11 {
        margin-left: 91.66667%
    }
}

@media (min-width: 1200px) {
    .col-xl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%
    }

    .col-xl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-xl-1 {
        -ms-flex: 0 0 8.33333%;
        flex: 0 0 8.33333%;
        max-width: 8.33333%
    }

    .col-xl-2 {
        -ms-flex: 0 0 16.66667%;
        flex: 0 0 16.66667%;
        max-width: 16.66667%
    }

    .col-xl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-xl-4 {
        -ms-flex: 0 0 33.33333%;
        flex: 0 0 33.33333%;
        max-width: 33.33333%
    }

    .col-xl-5 {
        -ms-flex: 0 0 41.66667%;
        flex: 0 0 41.66667%;
        max-width: 41.66667%
    }

    .col-xl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-xl-7 {
        -ms-flex: 0 0 58.33333%;
        flex: 0 0 58.33333%;
        max-width: 58.33333%
    }

    .col-xl-8 {
        -ms-flex: 0 0 66.66667%;
        flex: 0 0 66.66667%;
        max-width: 66.66667%
    }

    .col-xl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-xl-10 {
        -ms-flex: 0 0 83.33333%;
        flex: 0 0 83.33333%;
        max-width: 83.33333%
    }

    .col-xl-11 {
        -ms-flex: 0 0 91.66667%;
        flex: 0 0 91.66667%;
        max-width: 91.66667%
    }

    .col-xl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-xl-first {
        -ms-flex-order: -1;
        order: -1
    }

    .order-xl-last {
        -ms-flex-order: 13;
        order: 13
    }

    .order-xl-0 {
        -ms-flex-order: 0;
        order: 0
    }

    .order-xl-1 {
        -ms-flex-order: 1;
        order: 1
    }

    .order-xl-2 {
        -ms-flex-order: 2;
        order: 2
    }

    .order-xl-3 {
        -ms-flex-order: 3;
        order: 3
    }

    .order-xl-4 {
        -ms-flex-order: 4;
        order: 4
    }

    .order-xl-5 {
        -ms-flex-order: 5;
        order: 5
    }

    .order-xl-6 {
        -ms-flex-order: 6;
        order: 6
    }

    .order-xl-7 {
        -ms-flex-order: 7;
        order: 7
    }

    .order-xl-8 {
        -ms-flex-order: 8;
        order: 8
    }

    .order-xl-9 {
        -ms-flex-order: 9;
        order: 9
    }

    .order-xl-10 {
        -ms-flex-order: 10;
        order: 10
    }

    .order-xl-11 {
        -ms-flex-order: 11;
        order: 11
    }

    .order-xl-12 {
        -ms-flex-order: 12;
        order: 12
    }

    .offset-xl-0 {
        margin-left: 0
    }

    .offset-xl-1 {
        margin-left: 8.33333%
    }

    .offset-xl-2 {
        margin-left: 16.66667%
    }

    .offset-xl-3 {
        margin-left: 25%
    }

    .offset-xl-4 {
        margin-left: 33.33333%
    }

    .offset-xl-5 {
        margin-left: 41.66667%
    }

    .offset-xl-6 {
        margin-left: 50%
    }

    .offset-xl-7 {
        margin-left: 58.33333%
    }

    .offset-xl-8 {
        margin-left: 66.66667%
    }

    .offset-xl-9 {
        margin-left: 75%
    }

    .offset-xl-10 {
        margin-left: 83.33333%
    }

    .offset-xl-11 {
        margin-left: 91.66667%
    }
}

.table {
    width: 100%;
    margin-bottom: 1rem;
    color: #1e2022
}

.table td, .table th {
    padding: .625rem;
    vertical-align: top;
    border-top: 1px solid #e7eaf3
}

.table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid #e7eaf3
}

.table tbody + tbody {
    border-top: 2px solid #e7eaf3
}

.table-sm td, .table-sm th {
    padding: .3rem
}

.table-bordered, .table-bordered td, .table-bordered th {
    border: 1px solid #e7eaf3
}

.table-bordered thead td, .table-bordered thead th {
    border-bottom-width: 2px
}

.table-borderless tbody + tbody, .table-borderless td, .table-borderless th, .table-borderless thead th {
    border: 0
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8fafd
}

.table-hover tbody tr:hover {
    color: #1e2022;
    background-color: rgba(231, 234, 243, .4)
}

.table-primary, .table-primary > td, .table-primary > th {
    background-color: #c6e2f5
}

.table-primary tbody + tbody, .table-primary td, .table-primary th, .table-primary thead th {
    border-color: #95c9ec
}

.table-hover .table-primary:hover, .table-hover .table-primary:hover > td, .table-hover .table-primary:hover > th {
    background-color: #b0d7f1
}

.table-secondary, .table-secondary > td, .table-secondary > th {
    background-color: #d9dce0
}

.table-secondary tbody + tbody, .table-secondary td, .table-secondary th, .table-secondary thead th {
    border-color: #b8bfc5
}

.table-hover .table-secondary:hover, .table-hover .table-secondary:hover > td, .table-hover .table-secondary:hover > th {
    background-color: #cbcfd5
}

.table-success, .table-success > td, .table-success > th {
    background-color: #b8f0e6
}

.table-success tbody + tbody, .table-success td, .table-success th, .table-success thead th {
    border-color: #7ae3d1
}

.table-hover .table-success:hover, .table-hover .table-success:hover > td, .table-hover .table-success:hover > th {
    background-color: #a3ecdf
}

.table-info, .table-info > td, .table-info > th {
    background-color: #c6e2f5
}

.table-info tbody + tbody, .table-info td, .table-info th, .table-info thead th {
    border-color: #95c9ec
}

.table-hover .table-info:hover, .table-hover .table-info:hover > td, .table-hover .table-info:hover > th {
    background-color: #b0d7f1
}

.table-warning, .table-warning > td, .table-warning > th {
    background-color: #f5e3b9
}

.table-warning tbody + tbody, .table-warning td, .table-warning th, .table-warning thead th {
    border-color: #ecca7c
}

.table-hover .table-warning:hover, .table-hover .table-warning:hover > td, .table-hover .table-warning:hover > th {
    background-color: #f2daa3
}

.table-danger, .table-danger > td, .table-danger > th {
    background-color: #f6cbc7
}

.table-danger tbody + tbody, .table-danger td, .table-danger th, .table-danger thead th {
    border-color: #ee9e97
}

.table-hover .table-danger:hover, .table-hover .table-danger:hover > td, .table-hover .table-danger:hover > th {
    background-color: #f2b7b1
}

.table-light, .table-light > td, .table-light > th {
    background-color: #fdfdfe
}

.table-light tbody + tbody, .table-light td, .table-light th, .table-light thead th {
    border-color: #fbfcfc
}

.table-hover .table-light:hover, .table-hover .table-light:hover > td, .table-hover .table-light:hover > th {
    background-color: #ececf6
}

.table-dark, .table-dark > td, .table-dark > th {
    background-color: #c0c1c1
}

.table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
    border-color: #8a8b8c
}

.table-hover .table-dark:hover, .table-hover .table-dark:hover > td, .table-hover .table-dark:hover > th {
    background-color: #b3b4b4
}

.table-white, .table-white > td, .table-white > th {
    background-color: #fff
}

.table-white tbody + tbody, .table-white td, .table-white th, .table-white thead th {
    border-color: #fff
}

.table-hover .table-white:hover, .table-hover .table-white:hover > td, .table-hover .table-white:hover > th {
    background-color: #f2f2f2
}

.table-indigo, .table-indigo > td, .table-indigo > th {
    background-color: #c4bddc
}

.table-indigo tbody + tbody, .table-indigo td, .table-indigo th, .table-indigo thead th {
    border-color: #9285be
}

.table-hover .table-indigo:hover, .table-hover .table-indigo:hover > td, .table-hover .table-indigo:hover > th {
    background-color: #b5acd3
}

.table-active, .table-active > td, .table-active > th {
    background-color: rgba(231, 234, 243, .4)
}

.table-hover .table-active:hover, .table-hover .table-active:hover > td, .table-hover .table-active:hover > th {
    background-color: rgba(214, 219, 235, .4)
}

.table .thead-dark th {
    color: #fff;
    background-color: #3e444a;
    border-color: #4f575f
}

.table .thead-light th {
    color: #6c757e;
    background-color: #f8fafd;
    border-color: #e7eaf3
}

.table-dark {
    color: #fff;
    background-color: #3e444a
}

.table-dark td, .table-dark th, .table-dark thead th {
    border-color: #4f575f
}

.table-dark.table-bordered {
    border: 0
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: hsla(0, 0%, 100%, .05)
}

.table-dark.table-hover tbody tr:hover {
    color: #fff;
    background-color: hsla(0, 0%, 100%, .075)
}

@media (max-width: 575.98px) {
    .table-responsive-sm {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-sm > .table-bordered {
        border: 0
    }
}

@media (max-width: 767.98px) {
    .table-responsive-md {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-md > .table-bordered {
        border: 0
    }
}

@media (max-width: 991.98px) {
    .table-responsive-lg {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-lg > .table-bordered {
        border: 0
    }
}

@media (max-width: 1199.98px) {
    .table-responsive-xl {
        display: block;
        width: 100%;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
    }

    .table-responsive-xl > .table-bordered {
        border: 0
    }
}

.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

.table-responsive > .table-bordered {
    border: 0
}

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 1rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #d5dae2;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none
    }
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:focus {
    color: #1e2022;
    background-color: #fff;
    border-color: #d5dae2;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.form-control::-webkit-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control::-moz-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control::-ms-input-placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control::placeholder {
    color: #8c98a4;
    opacity: 1
}

.form-control:disabled, .form-control[readonly] {
    background-color: #f8fafd;
    opacity: 1
}

select.form-control:focus::-ms-value {
    color: #1e2022;
    background-color: #fff
}

.form-control-file, .form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    padding-top: calc(.75rem + 1px);
    padding-bottom: calc(.75rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5
}

.col-form-label-lg {
    padding-top: calc(1.125rem + 1px);
    padding-bottom: calc(1.125rem + 1px);
    font-size: 1.25rem;
    line-height: 1.5
}

.col-form-label-sm {
    padding-top: calc(.6rem + 1px);
    padding-bottom: calc(.6rem + 1px);
    font-size: .71094rem;
    line-height: 1.5
}

.form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: .75rem;
    padding-bottom: .75rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: #1e2022;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0
}

.form-control-plaintext.form-control-lg, .form-control-plaintext.form-control-sm {
    padding-right: 0;
    padding-left: 0
}

.form-control-sm {
    height: calc(1.5em + 1.2rem + 2px);
    padding: .6rem 1.125rem;
    font-size: .71094rem;
    line-height: 1.5;
    border-radius: .125rem
}

.form-control-lg {
    height: calc(1.5em + 2.25rem + 2px);
    padding: 1.125rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .25rem
}

select.form-control[multiple], select.form-control[size], textarea.form-control {
    height: auto
}

.form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block;
    margin-top: .25rem
}

.form-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px
}

.form-row > .col, .form-row > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem
}

.form-check-input:disabled ~ .form-check-label {
    color: #8c98a4
}

.form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    -ms-flex-align: center;
    align-items: center;
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static;
    margin-top: 0;
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #00c9a7
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .71094rem;
    line-height: 1.5;
    color: #fff;
    background-color: #00c9a7;
    border-radius: .125rem
}

.form-control.is-valid, .was-validated .form-control:valid {
    border-color: #00c9a7;
    padding-right: calc(1.5em + 1.5rem);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 100% calc(.375em + .375rem);
    background-size: calc(.75em + .75rem) calc(.75em + .75rem)
}

.form-control.is-valid:focus, .was-validated .form-control:valid:focus {
    border-color: #00c9a7;
    box-shadow: 0 0 0 .2rem rgba(0, 201, 167, .25)
}

.form-control.is-valid ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, .was-validated .form-control:valid ~ .valid-feedback, .was-validated .form-control:valid ~ .valid-tooltip {
    display: block
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1.5rem);
    background-position: top calc(.375em + .375rem) right calc(.375em + .375rem)
}

.custom-select.is-valid, .was-validated .custom-select:valid {
    border-color: #00c9a7;
    padding-right: calc((1em + 1.5rem) * 3 / 4 + 2rem);
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%233e444a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%2300c9a7' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E") #fff no-repeat center right 2rem/calc(.75em + .75rem) calc(.75em + .75rem)
}

.custom-select.is-valid:focus, .was-validated .custom-select:valid:focus {
    border-color: #00c9a7;
    box-shadow: 0 0 0 .2rem rgba(0, 201, 167, .25)
}

.custom-select.is-valid ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip, .was-validated .custom-select:valid ~ .valid-feedback, .was-validated .custom-select:valid ~ .valid-tooltip, .was-validated .form-control-file:valid ~ .valid-feedback, .was-validated .form-control-file:valid ~ .valid-tooltip {
    display: block
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
    color: #00c9a7
}

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #00c9a7
}

.custom-control-input.is-valid ~ .custom-control-label:before, .was-validated .custom-control-input:valid ~ .custom-control-label:before {
    border-color: #00c9a7
}

.custom-control-input.is-valid ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, .was-validated .custom-control-input:valid ~ .valid-feedback, .was-validated .custom-control-input:valid ~ .valid-tooltip {
    display: block
}

.custom-control-input.is-valid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
    border-color: #00fcd1;
    background-color: #00fcd1
}

.custom-control-input.is-valid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(0, 201, 167, .25)
}

.custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, .custom-file-input.is-valid ~ .custom-file-label, .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #00c9a7
}

.custom-file-input.is-valid ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, .was-validated .custom-file-input:valid ~ .valid-feedback, .was-validated .custom-file-input:valid ~ .valid-tooltip {
    display: block
}

.custom-file-input.is-valid:focus ~ .custom-file-label, .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: #00c9a7;
    box-shadow: 0 0 0 .2rem rgba(0, 201, 167, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #de4437
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: .25rem .5rem;
    margin-top: .1rem;
    font-size: .71094rem;
    line-height: 1.5;
    color: #fff;
    background-color: #de4437;
    border-radius: .125rem
}

.form-control.is-invalid, .was-validated .form-control:invalid {
    border-color: #de4437;
    padding-right: calc(1.5em + 1.5rem);
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23de4437' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23de4437' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 100% calc(.375em + .375rem);
    background-size: calc(.75em + .75rem) calc(.75em + .75rem)
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
    border-color: #de4437;
    box-shadow: 0 0 0 .2rem rgba(222, 68, 55, .25)
}

.form-control.is-invalid ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, .was-validated .form-control:invalid ~ .invalid-feedback, .was-validated .form-control:invalid ~ .invalid-tooltip {
    display: block
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1.5rem);
    background-position: top calc(.375em + .375rem) right calc(.375em + .375rem)
}

.custom-select.is-invalid, .was-validated .custom-select:invalid {
    border-color: #de4437;
    padding-right: calc((1em + 1.5rem) * 3 / 4 + 2rem);
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%233e444a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23de4437' viewBox='-2 -2 7 7'%3E%3Cpath stroke='%23de4437' d='M0 0l3 3m0-3L0 3'/%3E%3Ccircle r='.5'/%3E%3Ccircle cx='3' r='.5'/%3E%3Ccircle cy='3' r='.5'/%3E%3Ccircle cx='3' cy='3' r='.5'/%3E%3C/svg%3E") #fff no-repeat center right 2rem/calc(.75em + .75rem) calc(.75em + .75rem)
}

.custom-select.is-invalid:focus, .was-validated .custom-select:invalid:focus {
    border-color: #de4437;
    box-shadow: 0 0 0 .2rem rgba(222, 68, 55, .25)
}

.custom-select.is-invalid ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip, .was-validated .custom-select:invalid ~ .invalid-feedback, .was-validated .custom-select:invalid ~ .invalid-tooltip, .was-validated .form-control-file:invalid ~ .invalid-feedback, .was-validated .form-control-file:invalid ~ .invalid-tooltip {
    display: block
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #de4437
}

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #de4437
}

.custom-control-input.is-invalid ~ .custom-control-label:before, .was-validated .custom-control-input:invalid ~ .custom-control-label:before {
    border-color: #de4437
}

.custom-control-input.is-invalid ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-control-input:invalid ~ .invalid-feedback, .was-validated .custom-control-input:invalid ~ .invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid:checked ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
    border-color: #e56d63;
    background-color: #e56d63
}

.custom-control-input.is-invalid:focus ~ .custom-control-label:before, .was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(222, 68, 55, .25)
}

.custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, .custom-file-input.is-invalid ~ .custom-file-label, .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #de4437
}

.custom-file-input.is-invalid ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, .was-validated .custom-file-input:invalid ~ .invalid-feedback, .was-validated .custom-file-input:invalid ~ .invalid-tooltip {
    display: block
}

.custom-file-input.is-invalid:focus ~ .custom-file-label, .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #de4437;
    box-shadow: 0 0 0 .2rem rgba(222, 68, 55, .25)
}

.form-inline {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-align: center;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width: 576px) {
    .form-inline label {
        -ms-flex-align: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .form-inline .form-group, .form-inline label {
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        margin-bottom: 0
    }

    .form-inline .form-group {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        -ms-flex-align: center
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select, .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center;
        width: auto;
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        -ms-flex-negative: 0;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        -ms-flex-align: center;
        align-items: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    display: inline-block;
    font-weight: 500;
    color: #1e2022;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .75rem 1rem;
    font-size: .8125rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: all .2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #1e2022;
    text-decoration: none
}

.btn.focus, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.btn.disabled, .btn:disabled {
    opacity: .65
}

a.btn.disabled, fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-primary:hover {
    color: #fff;
    background-color: #2384c6;
    border-color: #217dbb
}

.btn-primary.focus, .btn-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 167, 224, .5)
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #217dbb;
    border-color: #1f76b0
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 167, 224, .5)
}

.btn-secondary {
    color: #fff;
    background-color: #77838f;
    border-color: #77838f
}

.btn-secondary:hover {
    color: #fff;
    background-color: #65707b;
    border-color: #5f6a74
}

.btn-secondary.focus, .btn-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(139, 150, 160, .5)
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff;
    background-color: #77838f;
    border-color: #77838f
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #fff;
    background-color: #5f6a74;
    border-color: #5a636d
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(139, 150, 160, .5)
}

.btn-success {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7
}

.btn-success:hover {
    color: #fff;
    background-color: #00a387;
    border-color: #00967d
}

.btn-success.focus, .btn-success:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 209, 180, .5)
}

.btn-success.disabled, .btn-success:disabled {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
    color: #fff;
    background-color: #00967d;
    border-color: #008972
}

.btn-success:not(:disabled):not(.disabled).active:focus, .btn-success:not(:disabled):not(.disabled):active:focus, .show > .btn-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(38, 209, 180, .5)
}

.btn-info {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-info:hover {
    color: #fff;
    background-color: #2384c6;
    border-color: #217dbb
}

.btn-info.focus, .btn-info:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 167, 224, .5)
}

.btn-info.disabled, .btn-info:disabled {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #217dbb;
    border-color: #1f76b0
}

.btn-info:not(:disabled):not(.disabled).active:focus, .btn-info:not(:disabled):not(.disabled):active:focus, .show > .btn-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(82, 167, 224, .5)
}

.btn-warning {
    color: #1e2022;
    background-color: #db9a04;
    border-color: #db9a04
}

.btn-warning:hover {
    color: #fff;
    background-color: #b58003;
    border-color: #a97703
}

.btn-warning.focus, .btn-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(191, 136, 9, .5)
}

.btn-warning.disabled, .btn-warning:disabled {
    color: #1e2022;
    background-color: #db9a04;
    border-color: #db9a04
}

.btn-warning:not(:disabled):not(.disabled).active, .btn-warning:not(:disabled):not(.disabled):active, .show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #a97703;
    border-color: #9c6e03
}

.btn-warning:not(:disabled):not(.disabled).active:focus, .btn-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(191, 136, 9, .5)
}

.btn-danger {
    color: #fff;
    background-color: #de4437;
    border-color: #de4437
}

.btn-danger:hover {
    color: #fff;
    background-color: #cd2f22;
    border-color: #c22d20
}

.btn-danger.focus, .btn-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(227, 96, 85, .5)
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #de4437;
    border-color: #de4437
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #c22d20;
    border-color: #b72a1e
}

.btn-danger:not(:disabled):not(.disabled).active:focus, .btn-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(227, 96, 85, .5)
}

.btn-light {
    color: #1e2022;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:hover {
    color: #1e2022;
    background-color: #e2e6ea;
    border-color: #dae0e5
}

.btn-light.focus, .btn-light:focus {
    box-shadow: 0 0 0 .2rem hsla(220, 4%, 85%, .5)
}

.btn-light.disabled, .btn-light:disabled {
    color: #1e2022;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .show > .btn-light.dropdown-toggle {
    color: #1e2022;
    background-color: #dae0e5;
    border-color: #d3d9df
}

.btn-light:not(:disabled):not(.disabled).active:focus, .btn-light:not(:disabled):not(.disabled):active:focus, .show > .btn-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem hsla(220, 4%, 85%, .5)
}

.btn-dark {
    color: #fff;
    background-color: #1e2022;
    border-color: #1e2022
}

.btn-dark:hover {
    color: #fff;
    background-color: #0c0d0e;
    border-color: #060707
}

.btn-dark.focus, .btn-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(64, 65, 67, .5)
}

.btn-dark.disabled, .btn-dark:disabled {
    color: #fff;
    background-color: #1e2022;
    border-color: #1e2022
}

.btn-dark:not(:disabled):not(.disabled).active, .btn-dark:not(:disabled):not(.disabled):active, .show > .btn-dark.dropdown-toggle {
    color: #fff;
    background-color: #060707;
    border-color: #000
}

.btn-dark:not(:disabled):not(.disabled).active:focus, .btn-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(64, 65, 67, .5)
}

.btn-white {
    color: #1e2022;
    background-color: #fff;
    border-color: #fff
}

.btn-white:hover {
    color: #1e2022;
    background-color: #ececec;
    border-color: #e6e6e6
}

.btn-white.focus, .btn-white:focus {
    box-shadow: 0 0 0 .2rem hsla(180, 1%, 87%, .5)
}

.btn-white.disabled, .btn-white:disabled {
    color: #1e2022;
    background-color: #fff;
    border-color: #fff
}

.btn-white:not(:disabled):not(.disabled).active, .btn-white:not(:disabled):not(.disabled):active, .show > .btn-white.dropdown-toggle {
    color: #1e2022;
    background-color: #e6e6e6;
    border-color: #dfdfdf
}

.btn-white:not(:disabled):not(.disabled).active:focus, .btn-white:not(:disabled):not(.disabled):active:focus, .show > .btn-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem hsla(180, 1%, 87%, .5)
}

.btn-indigo {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582
}

.btn-indigo:hover {
    color: #fff;
    background-color: #221061;
    border-color: #1e0e56
}

.btn-indigo.focus, .btn-indigo:focus {
    box-shadow: 0 0 0 .2rem rgba(77, 56, 149, .5)
}

.btn-indigo.disabled, .btn-indigo:disabled {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582
}

.btn-indigo:not(:disabled):not(.disabled).active, .btn-indigo:not(:disabled):not(.disabled):active, .show > .btn-indigo.dropdown-toggle {
    color: #fff;
    background-color: #1e0e56;
    border-color: #1a0c4b
}

.btn-indigo:not(:disabled):not(.disabled).active:focus, .btn-indigo:not(:disabled):not(.disabled):active:focus, .show > .btn-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(77, 56, 149, .5)
}

.btn-outline-primary {
    color: #3498db;
    border-color: #3498db
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-outline-primary.focus, .btn-outline-primary:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .5)
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
    color: #3498db;
    background-color: transparent
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show > .btn-outline-primary.dropdown-toggle {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-outline-primary:not(:disabled):not(.disabled).active:focus, .btn-outline-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .5)
}

.btn-outline-secondary {
    color: #77838f;
    border-color: #77838f
}

.btn-outline-secondary:hover {
    color: #fff;
    background-color: #77838f;
    border-color: #77838f
}

.btn-outline-secondary.focus, .btn-outline-secondary:focus {
    box-shadow: 0 0 0 .2rem rgba(119, 131, 143, .5)
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
    color: #77838f;
    background-color: transparent
}

.btn-outline-secondary:not(:disabled):not(.disabled).active, .btn-outline-secondary:not(:disabled):not(.disabled):active, .show > .btn-outline-secondary.dropdown-toggle {
    color: #fff;
    background-color: #77838f;
    border-color: #77838f
}

.btn-outline-secondary:not(:disabled):not(.disabled).active:focus, .btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-secondary.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(119, 131, 143, .5)
}

.btn-outline-success {
    color: #00c9a7;
    border-color: #00c9a7
}

.btn-outline-success:hover {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7
}

.btn-outline-success.focus, .btn-outline-success:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 201, 167, .5)
}

.btn-outline-success.disabled, .btn-outline-success:disabled {
    color: #00c9a7;
    background-color: transparent
}

.btn-outline-success:not(:disabled):not(.disabled).active, .btn-outline-success:not(:disabled):not(.disabled):active, .show > .btn-outline-success.dropdown-toggle {
    color: #fff;
    background-color: #00c9a7;
    border-color: #00c9a7
}

.btn-outline-success:not(:disabled):not(.disabled).active:focus, .btn-outline-success:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-success.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(0, 201, 167, .5)
}

.btn-outline-info {
    color: #3498db;
    border-color: #3498db
}

.btn-outline-info:hover {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-outline-info.focus, .btn-outline-info:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .5)
}

.btn-outline-info.disabled, .btn-outline-info:disabled {
    color: #3498db;
    background-color: transparent
}

.btn-outline-info:not(:disabled):not(.disabled).active, .btn-outline-info:not(:disabled):not(.disabled):active, .show > .btn-outline-info.dropdown-toggle {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-outline-info:not(:disabled):not(.disabled).active:focus, .btn-outline-info:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-info.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .5)
}

.btn-outline-warning {
    color: #db9a04;
    border-color: #db9a04
}

.btn-outline-warning:hover {
    color: #1e2022;
    background-color: #db9a04;
    border-color: #db9a04
}

.btn-outline-warning.focus, .btn-outline-warning:focus {
    box-shadow: 0 0 0 .2rem rgba(219, 154, 4, .5)
}

.btn-outline-warning.disabled, .btn-outline-warning:disabled {
    color: #db9a04;
    background-color: transparent
}

.btn-outline-warning:not(:disabled):not(.disabled).active, .btn-outline-warning:not(:disabled):not(.disabled):active, .show > .btn-outline-warning.dropdown-toggle {
    color: #1e2022;
    background-color: #db9a04;
    border-color: #db9a04
}

.btn-outline-warning:not(:disabled):not(.disabled).active:focus, .btn-outline-warning:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-warning.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(219, 154, 4, .5)
}

.btn-outline-danger {
    color: #de4437;
    border-color: #de4437
}

.btn-outline-danger:hover {
    color: #fff;
    background-color: #de4437;
    border-color: #de4437
}

.btn-outline-danger.focus, .btn-outline-danger:focus {
    box-shadow: 0 0 0 .2rem rgba(222, 68, 55, .5)
}

.btn-outline-danger.disabled, .btn-outline-danger:disabled {
    color: #de4437;
    background-color: transparent
}

.btn-outline-danger:not(:disabled):not(.disabled).active, .btn-outline-danger:not(:disabled):not(.disabled):active, .show > .btn-outline-danger.dropdown-toggle {
    color: #fff;
    background-color: #de4437;
    border-color: #de4437
}

.btn-outline-danger:not(:disabled):not(.disabled).active:focus, .btn-outline-danger:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(222, 68, 55, .5)
}

.btn-outline-light {
    color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:hover {
    color: #1e2022;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light.focus, .btn-outline-light:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-light.disabled, .btn-outline-light:disabled {
    color: #f8f9fa;
    background-color: transparent
}

.btn-outline-light:not(:disabled):not(.disabled).active, .btn-outline-light:not(:disabled):not(.disabled):active, .show > .btn-outline-light.dropdown-toggle {
    color: #1e2022;
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-outline-light:not(:disabled):not(.disabled).active:focus, .btn-outline-light:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-light.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.btn-outline-dark {
    color: #1e2022;
    border-color: #1e2022
}

.btn-outline-dark:hover {
    color: #fff;
    background-color: #1e2022;
    border-color: #1e2022
}

.btn-outline-dark.focus, .btn-outline-dark:focus {
    box-shadow: 0 0 0 .2rem rgba(30, 32, 34, .5)
}

.btn-outline-dark.disabled, .btn-outline-dark:disabled {
    color: #1e2022;
    background-color: transparent
}

.btn-outline-dark:not(:disabled):not(.disabled).active, .btn-outline-dark:not(:disabled):not(.disabled):active, .show > .btn-outline-dark.dropdown-toggle {
    color: #fff;
    background-color: #1e2022;
    border-color: #1e2022
}

.btn-outline-dark:not(:disabled):not(.disabled).active:focus, .btn-outline-dark:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-dark.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(30, 32, 34, .5)
}

.btn-outline-white {
    color: #fff;
    border-color: #fff
}

.btn-outline-white:hover {
    color: #1e2022;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-white.focus, .btn-outline-white:focus {
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5)
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff;
    background-color: transparent
}

.btn-outline-white:not(:disabled):not(.disabled).active, .btn-outline-white:not(:disabled):not(.disabled):active, .show > .btn-outline-white.dropdown-toggle {
    color: #1e2022;
    background-color: #fff;
    border-color: #fff
}

.btn-outline-white:not(:disabled):not(.disabled).active:focus, .btn-outline-white:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-white.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5)
}

.btn-outline-indigo {
    color: #2d1582;
    border-color: #2d1582
}

.btn-outline-indigo:hover {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582
}

.btn-outline-indigo.focus, .btn-outline-indigo:focus {
    box-shadow: 0 0 0 .2rem rgba(45, 21, 130, .5)
}

.btn-outline-indigo.disabled, .btn-outline-indigo:disabled {
    color: #2d1582;
    background-color: transparent
}

.btn-outline-indigo:not(:disabled):not(.disabled).active, .btn-outline-indigo:not(:disabled):not(.disabled):active, .show > .btn-outline-indigo.dropdown-toggle {
    color: #fff;
    background-color: #2d1582;
    border-color: #2d1582
}

.btn-outline-indigo:not(:disabled):not(.disabled).active:focus, .btn-outline-indigo:not(:disabled):not(.disabled):active:focus, .show > .btn-outline-indigo.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(45, 21, 130, .5)
}

.btn-link {
    font-weight: 400;
    color: #3498db;
    text-decoration: none
}

.btn-link:hover {
    color: #1d6fa5;
    text-decoration: none
}

.btn-link.focus, .btn-link:focus {
    text-decoration: none;
    box-shadow: none
}

.btn-link.disabled, .btn-link:disabled {
    color: #8c98a4;
    pointer-events: none
}

.btn-group-lg > .btn, .btn-lg {
    padding: 1.125rem;
    font-size: 1.01563rem;
    line-height: 1.5;
    border-radius: .625rem
}

.btn-group-sm > .btn, .btn-sm {
    padding: .6rem 1.125rem;
    font-size: .875rem;
    line-height: 1.5;
    border-radius: .125rem
}

.btn-block {
    display: block;
    width: 100%
}

.btn-block + .btn-block {
    margin-top: .5rem
}

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown, .dropleft, .dropright, .dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 8.4375rem;
    padding: 1rem 0;
    margin: .125rem 0 0;
    font-size: .8125rem;
    color: #1e2022;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 0 solid rgba(0, 0, 0, .15);
    border-radius: .25rem
}

.dropdown-menu-left {
    right: auto;
    left: 0
}

.dropdown-menu-right {
    left: auto
}

@media (min-width: 576px) {
    .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-sm-right {
        right: 0;
        left: auto
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-md-right {
        right: 0;
        left: auto
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-lg-right {
        right: 0;
        left: auto
    }
}

@media (min-width: 1200px) {
    .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }

    .dropdown-menu-xl-right {
        right: 0;
        left: auto
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%;
    margin-top: 0;
    margin-bottom: .125rem
}

.dropright .dropdown-menu {
    top: 0;
    right: auto;
    left: 100%;
    margin-top: 0;
    margin-left: .125rem
}

.dropright .dropdown-toggle:after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0;
    right: 100%;
    left: auto;
    margin-top: 0;
    margin-right: .125rem
}

.dropleft .dropdown-toggle:before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
    right: auto;
    bottom: auto
}

.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #e7eaf3
}

.dropdown-item {
    display: block;
    width: 100%;
    padding: .375rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #6c757e;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus, .dropdown-item:hover {
    color: #1e2022;
    text-decoration: none;
    background-color: transparent
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: transparent
}

.dropdown-item.disabled, .dropdown-item:disabled {
    color: #8c98a4;
    pointer-events: none;
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    padding: 1rem 1.5rem;
    margin-bottom: 0;
    font-size: .71094rem;
    color: #8c98a4;
    white-space: nowrap
}

.dropdown-item-text {
    display: block;
    padding: .375rem 1.5rem;
    color: #6c757e
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: -ms-inline-flexbox;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical > .btn, .btn-group > .btn {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

.btn-group > .btn-group:not(:first-child), .btn-group > .btn:not(:first-child) {
    margin-left: -1px
}

.btn-group > .btn-group:not(:last-child) > .btn, .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.btn-group > .btn-group:not(:first-child) > .btn, .btn-group > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

.dropdown-toggle-split:after, .dropright .dropdown-toggle-split:after, .dropup .dropdown-toggle-split:after {
    margin-left: 0
}

.dropleft .dropdown-toggle-split:before {
    margin-right: 0
}

.btn-group-lg > .btn + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split, .btn-lg + .dropdown-toggle-split, .btn-sm + .dropdown-toggle-split {
    padding-right: .84375rem;
    padding-left: .84375rem
}

.btn-group-vertical {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: center;
    justify-content: center
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
    width: 100%
}

.btn-group-vertical > .btn-group:not(:first-child), .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px
}

.btn-group-vertical > .btn-group:not(:last-child) > .btn, .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.btn-group-vertical > .btn-group:not(:first-child) > .btn, .btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.btn-group-toggle > .btn, .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0
}

.btn-group-toggle > .btn input[type=checkbox], .btn-group-toggle > .btn input[type=radio], .btn-group-toggle > .btn-group > .btn input[type=checkbox], .btn-group-toggle > .btn-group > .btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: stretch;
    align-items: stretch;
    width: 100%
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    width: 1%;
    margin-bottom: 0
}

.input-group > .custom-file + .custom-file, .input-group > .custom-file + .custom-select, .input-group > .custom-file + .form-control, .input-group > .custom-select + .custom-file, .input-group > .custom-select + .custom-select, .input-group > .custom-select + .form-control, .input-group > .form-control + .custom-file, .input-group > .form-control + .custom-select, .input-group > .form-control + .form-control, .input-group > .form-control-plaintext + .custom-file, .input-group > .form-control-plaintext + .custom-select, .input-group > .form-control-plaintext + .form-control {
    margin-left: -1px
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
    z-index: 3
}

.input-group > .custom-file .custom-file-input:focus {
    z-index: 4
}

.input-group > .custom-select:not(:last-child), .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group > .custom-select:not(:first-child), .input-group > .form-control:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group > .custom-file {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.input-group > .custom-file:not(:last-child) .custom-file-label, .input-group > .custom-file:not(:last-child) .custom-file-label:after {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group > .custom-file:not(:first-child) .custom-file-label {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append, .input-group-prepend {
    display: -ms-flexbox;
    display: flex
}

.input-group-append .btn, .input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus, .input-group-prepend .btn:focus {
    z-index: 3
}

.input-group-append .btn + .btn, .input-group-append .btn + .input-group-text, .input-group-append .input-group-text + .btn, .input-group-append .input-group-text + .input-group-text, .input-group-prepend .btn + .btn, .input-group-prepend .btn + .input-group-text, .input-group-prepend .input-group-text + .btn, .input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px
}

.input-group-prepend {
    margin-right: -1px
}

.input-group-append {
    margin-left: -1px
}

.input-group-text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .75rem 1rem;
    margin-bottom: 0;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #8c98a4;
    text-align: center;
    white-space: nowrap;
    background-color: #fff;
    border: 1px solid #d5dae2;
    border-radius: .25rem
}

.input-group-text input[type=checkbox], .input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg > .custom-select, .input-group-lg > .form-control:not(textarea) {
    height: calc(1.5em + 2.25rem + 2px)
}

.input-group-lg > .custom-select, .input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
    padding: 1.125rem;
    font-size: 1.25rem;
    line-height: 1.5;
    border-radius: .25rem
}

.input-group-sm > .custom-select, .input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + 1.2rem + 2px)
}

.input-group-sm > .custom-select, .input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
    padding: .6rem 1.125rem;
    font-size: .71094rem;
    line-height: 1.5;
    border-radius: .125rem
}

.input-group-lg > .custom-select, .input-group-sm > .custom-select {
    padding-right: 2rem
}

.input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.input-group > .input-group-append > .btn, .input-group > .input-group-append > .input-group-text, .input-group > .input-group-prepend:first-child > .btn:not(:first-child), .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), .input-group > .input-group-prepend:not(:first-child) > .btn, .input-group > .input-group-prepend:not(:first-child) > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.custom-control {
    position: relative;
    display: block;
    min-height: 1.21875rem;
    padding-left: 1.5rem
}

.custom-control-inline {
    display: -ms-inline-flexbox;
    display: inline-flex;
    margin-right: 1rem
}

.custom-control-input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.custom-control-input:checked ~ .custom-control-label:before {
    color: #fff;
    border-color: #3498db;
    background-color: #3498db
}

.custom-control-input:focus ~ .custom-control-label:before {
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label:before {
    border-color: #d5dae2
}

.custom-control-input:not(:disabled):active ~ .custom-control-label:before {
    color: #fff;
    background-color: #cce5f6;
    border-color: #cce5f6
}

.custom-control-input:disabled ~ .custom-control-label {
    color: #8c98a4
}

.custom-control-input:disabled ~ .custom-control-label:before {
    background-color: #f8fafd
}

.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top
}

.custom-control-label:before {
    pointer-events: none;
    background-color: #fff;
    border: 1px solid #97a4af
}

.custom-control-label:after, .custom-control-label:before {
    position: absolute;
    top: .10938rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: ""
}

.custom-control-label:after {
    background: no-repeat 50%/50% 50%
}

.custom-checkbox .custom-control-label:before {
    border-radius: .25rem
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
    border-color: #3498db;
    background-color: #3498db
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='%23fff' d='M0 2h4'/%3E%3C/svg%3E")
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before {
    background-color: rgba(52, 152, 219, .5)
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
    background-color: rgba(52, 152, 219, .5)
}

.custom-radio .custom-control-label:before {
    border-radius: 50%
}

.custom-radio .custom-control-input:checked ~ .custom-control-label:after {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3E%3Ccircle r='3' fill='%23fff'/%3E%3C/svg%3E")
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label:before {
    background-color: rgba(52, 152, 219, .5)
}

.custom-switch {
    padding-left: 3rem
}

.custom-switch .custom-control-label:before {
    left: -3rem;
    width: 2.5rem;
    pointer-events: all;
    border-radius: .5rem
}

.custom-switch .custom-control-label:after {
    top: calc(.10938rem + 2px);
    left: calc(-3rem + 2px);
    width: calc(1rem - 4px);
    height: calc(1rem - 4px);
    background-color: #97a4af;
    border-radius: .5rem;
    transition: transform .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label:after {
        transition: none
    }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label:after {
    background-color: #fff;
    transform: translateX(1.5rem)
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label:before {
    background-color: rgba(52, 152, 219, .5)
}

.custom-select {
    display: inline-block;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px);
    padding: .75rem 2rem .75rem 1rem;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #1e2022;
    vertical-align: middle;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%233e444a' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E") no-repeat right 1rem center/8px 10px;
    background-color: #fff;
    border: 1px solid #d5dae2;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-select:focus {
    border-color: rgba(52, 152, 219, .5);
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.custom-select:focus::-ms-value {
    color: #1e2022;
    background-color: #fff
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
    height: auto;
    padding-right: 1rem;
    background-image: none
}

.custom-select:disabled {
    color: #8c98a4;
    background-color: #f8fafd
}

.custom-select::-ms-expand {
    display: none
}

.custom-select-sm {
    height: calc(1.5em + 1.2rem + 2px);
    padding-top: .6rem;
    padding-bottom: .6rem;
    padding-left: 1.125rem;
    font-size: .8125rem
}

.custom-select-lg {
    height: calc(1.5em + 2.25rem + 2px);
    padding-top: 1.125rem;
    padding-bottom: 1.125rem;
    padding-left: 1.125rem;
    font-size: 1.25rem
}

.custom-file {
    display: inline-block;
    margin-bottom: 0
}

.custom-file, .custom-file-input {
    position: relative;
    width: 100%;
    height: calc(1.5em + 1.5rem + 2px)
}

.custom-file-input {
    z-index: 2;
    margin: 0;
    opacity: 0
}

.custom-file-input:focus ~ .custom-file-label {
    border-color: #d5dae2;
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.custom-file-input:disabled ~ .custom-file-label {
    background-color: #f8fafd
}

.custom-file-input:lang(en) ~ .custom-file-label:after {
    content: "Browse"
}

.custom-file-input ~ .custom-file-label[data-browse]:after {
    content: attr(data-browse)
}

.custom-file-label {
    left: 0;
    z-index: 1;
    height: calc(1.5em + 1.5rem + 2px);
    font-weight: 400;
    border: 1px solid #d5dae2;
    border-radius: .25rem
}

.custom-file-label, .custom-file-label:after {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1rem;
    line-height: 1.5;
    color: #1e2022;
    background-color: #fff
}

.custom-file-label:after {
    bottom: 0;
    z-index: 3;
    display: block;
    height: calc(1.5em + 1.5rem);
    content: "Browse";
    border-left: inherit;
    border-radius: 0 .25rem .25rem 0
}

.custom-range {
    width: 100%;
    height: 1.4rem;
    padding: 0;
    background-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

.custom-range:focus {
    outline: 0
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.custom-range:focus::-ms-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.custom-range::-moz-focus-outer {
    border: 0
}

.custom-range::-webkit-slider-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: -.25rem;
    background-color: #3498db;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-webkit-slider-thumb {
        transition: none
    }
}

.custom-range::-webkit-slider-thumb:active {
    background-color: #cce5f6
}

.custom-range::-webkit-slider-runnable-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7eaf3;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-moz-range-thumb {
    width: 1rem;
    height: 1rem;
    background-color: #3498db;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-appearance: none;
    appearance: none
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-moz-range-thumb {
        transition: none
    }
}

.custom-range::-moz-range-thumb:active {
    background-color: #cce5f6
}

.custom-range::-moz-range-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: #e7eaf3;
    border-color: transparent;
    border-radius: 1rem
}

.custom-range::-ms-thumb {
    width: 1rem;
    height: 1rem;
    margin-top: 0;
    margin-right: .2rem;
    margin-left: .2rem;
    background-color: #3498db;
    border: 0;
    border-radius: 1rem;
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    appearance: none
}

@media (prefers-reduced-motion: reduce) {
    .custom-range::-ms-thumb {
        transition: none
    }
}

.custom-range::-ms-thumb:active {
    background-color: #cce5f6
}

.custom-range::-ms-track {
    width: 100%;
    height: .5rem;
    color: transparent;
    cursor: pointer;
    background-color: transparent;
    border-color: transparent;
    border-width: .5rem
}

.custom-range::-ms-fill-lower, .custom-range::-ms-fill-upper {
    background-color: #e7eaf3;
    border-radius: 1rem
}

.custom-range::-ms-fill-upper {
    margin-right: 15px
}

.custom-range:disabled::-webkit-slider-thumb {
    background-color: #97a4af
}

.custom-range:disabled::-webkit-slider-runnable-track {
    cursor: default
}

.custom-range:disabled::-moz-range-thumb {
    background-color: #97a4af
}

.custom-range:disabled::-moz-range-track {
    cursor: default
}

.custom-range:disabled::-ms-thumb {
    background-color: #97a4af
}

.custom-control-label:before, .custom-file-label, .custom-select {
    transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .custom-control-label:before, .custom-file-label, .custom-select {
        transition: none
    }
}

.nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.nav-link {
    display: block;
    padding: .5rem 1rem
}

.nav-link:focus, .nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #8c98a4;
    pointer-events: none;
    cursor: default
}

.nav-tabs {
    border-bottom: 1px solid #e7eaf3
}

.nav-tabs .nav-item {
    margin-bottom: -1px
}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.nav-tabs .nav-link:focus, .nav-tabs .nav-link:hover {
    border-color: #f8fafd #f8fafd #e7eaf3
}

.nav-tabs .nav-link.disabled {
    color: #8c98a4;
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #6c757e;
    background-color: #fff;
    border-color: #e7eaf3 #e7eaf3 #fff
}

.nav-tabs .dropdown-menu {
    margin-top: -1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff;
    background-color: #3498db
}

.nav-fill .nav-item {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    text-align: center
}

.nav-justified .nav-item {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    text-align: center
}

.tab-content > .tab-pane {
    display: none
}

.tab-content > .active {
    display: block
}

.navbar {
    position: relative;
    padding: .5rem 1rem
}

.navbar, .navbar > .container, .navbar > .container-fluid {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    padding-top: .34766rem;
    padding-bottom: .34766rem;
    margin-right: 1rem;
    font-size: 1.01563rem;
    line-height: inherit;
    white-space: nowrap
}

.navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none
}

.navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static;
    float: none
}

.navbar-text {
    display: inline-block;
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    -ms-flex-positive: 1;
    flex-grow: 1;
    -ms-flex-align: center;
    align-items: center
}

.navbar-toggler {
    padding: .25rem .75rem;
    font-size: 1.01563rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat 50%;
    background-size: 100% 100%
}

@media (max-width: 575.98px) {
    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-flow: row nowrap;
        flex-flow: row nowrap;
        -ms-flex-pack: start;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: -ms-flexbox !important;
        display: flex !important;
        -ms-flex-preferred-size: auto;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    -ms-flex-direction: row;
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand > .container, .navbar-expand > .container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(0, 0, 0, .7)
}

.navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, .3)
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
    color: rgba(0, 0, 0, .9)
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, .5);
    border-color: rgba(0, 0, 0, .1)
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-light .navbar-text {
    color: rgba(0, 0, 0, .5)
}

.navbar-light .navbar-text a, .navbar-light .navbar-text a:focus, .navbar-light .navbar-text a:hover {
    color: rgba(0, 0, 0, .9)
}

.navbar-dark .navbar-brand, .navbar-dark .navbar-brand:focus, .navbar-dark .navbar-brand:hover {
    color: #fff
}

.navbar-dark .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark .navbar-nav .nav-link:focus, .navbar-dark .navbar-nav .nav-link:hover {
    color: hsla(0, 0%, 100%, .75)
}

.navbar-dark .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, .25)
}

.navbar-dark .navbar-nav .active > .nav-link, .navbar-dark .navbar-nav .nav-link.active, .navbar-dark .navbar-nav .nav-link.show, .navbar-dark .navbar-nav .show > .nav-link {
    color: #fff
}

.navbar-dark .navbar-toggler {
    color: hsla(0, 0%, 100%, .5);
    border-color: hsla(0, 0%, 100%, .1)
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E")
}

.navbar-dark .navbar-text {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark .navbar-text a, .navbar-dark .navbar-text a:focus, .navbar-dark .navbar-text a:hover {
    color: #fff
}

.card {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #e7eaf3;
    border-radius: .25rem
}

.card > hr {
    margin-right: 0;
    margin-left: 0
}

.card > .list-group:first-child .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.card > .list-group:last-child .list-group-item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: .75rem
}

.card-title {
    margin-bottom: .75rem
}

.card-subtitle {
    margin-top: -.375rem
}

.card-subtitle, .card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

.card-link + .card-link {
    margin-left: .75rem
}

.card-header {
    padding: .75rem;
    margin-bottom: 0;
    background-color: #fff;
    border-bottom: 1px solid #e7eaf3
}

.card-header:first-child {
    border-radius: calc(.25rem - 1px) calc(.25rem - 1px) 0 0
}

.card-header + .list-group .list-group-item:first-child {
    border-top: 0
}

.card-footer {
    padding: .75rem;
    background-color: #fff;
    border-top: 1px solid #e7eaf3
}

.card-footer:last-child {
    border-radius: 0 0 calc(.25rem - 1px) calc(.25rem - 1px)
}

.card-header-tabs {
    margin-bottom: -.75rem;
    border-bottom: 0
}

.card-header-pills, .card-header-tabs {
    margin-right: -.375rem;
    margin-left: -.375rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem
}

.card-img {
    width: 100%;
    border-radius: calc(.25rem - 1px)
}

.card-img-top {
    width: 100%;
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

.card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(.25rem - 1px);
    border-bottom-left-radius: calc(.25rem - 1px)
}

.card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.card-deck .card {
    margin-bottom: 7.5px
}

@media (min-width: 576px) {
    .card-deck {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap;
        margin-right: -7.5px;
        margin-left: -7.5px
    }

    .card-deck .card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column;
        margin-right: 7.5px;
        margin-bottom: 0;
        margin-left: 7.5px
    }
}

.card-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column
}

.card-group > .card {
    margin-bottom: 7.5px
}

@media (min-width: 576px) {
    .card-group {
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .card-group > .card {
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        margin-bottom: 0
    }

    .card-group > .card + .card {
        margin-left: 0;
        border-left: 0
    }

    .card-group > .card:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    .card-group > .card:not(:last-child) .card-header, .card-group > .card:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    .card-group > .card:not(:last-child) .card-footer, .card-group > .card:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    .card-group > .card:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group > .card:not(:first-child) .card-header, .card-group > .card:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    .card-group > .card:not(:first-child) .card-footer, .card-group > .card:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

.card-columns .card {
    margin-bottom: .75rem
}

@media (min-width: 576px) {
    .card-columns {
        column-count: 3;
        column-gap: 1.25rem;
        orphans: 1;
        widows: 1
    }

    .card-columns .card {
        display: inline-block;
        width: 100%
    }
}

.accordion > .card {
    overflow: hidden
}

.accordion > .card:not(:first-of-type) .card-header:first-child {
    border-radius: 0
}

.accordion > .card:not(:first-of-type):not(:last-of-type) {
    border-bottom: 0;
    border-radius: 0
}

.accordion > .card:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.accordion > .card:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.accordion > .card .card-header {
    margin-bottom: -1px
}

.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: .75rem 1rem;
    margin-bottom: .5rem;
    list-style: none;
    background-color: transparent;
    border-radius: .25rem
}

.breadcrumb-item + .breadcrumb-item {
    padding-left: .5rem
}

.breadcrumb-item + .breadcrumb-item:before {
    display: inline-block;
    padding-right: .5rem;
    color: #97a4af;
    content: "/"
}

.breadcrumb-item + .breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none
}

.breadcrumb-item.active {
    color: #6c757e
}

.pagination {
    display: -ms-flexbox;
    display: flex;
    padding-left: 0;
    list-style: none;
    border-radius: .25rem
}

.page-link {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: 0;
    line-height: 1.25;
    color: #77838f;
    background-color: #fff;
    border: 0 solid #77838f
}

.page-link:hover {
    z-index: 2;
    color: #3498db;
    text-decoration: none;
    background-color: rgba(52, 152, 219, .1);
    border-color: rgba(52, 152, 219, .1)
}

.page-link:focus {
    z-index: 2;
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .25)
}

.page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 1;
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.page-item.disabled .page-link {
    color: #8c98a4;
    pointer-events: none;
    cursor: auto;
    background-color: #fff;
    border-color: #e7eaf3
}

.pagination-lg .page-link {
    padding: .75rem 1.5rem;
    font-size: 1.01563rem;
    line-height: 1.5
}

.pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .625rem;
    border-bottom-left-radius: .625rem
}

.pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .625rem;
    border-bottom-right-radius: .625rem
}

.pagination-sm .page-link {
    padding: .3rem .6rem;
    font-size: .71094rem;
    line-height: 1.5
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .125rem;
    border-bottom-left-radius: .125rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .125rem;
    border-bottom-right-radius: .125rem
}

.badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .125rem;
    transition: all .2s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus, a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

.btn .badge {
    position: relative;
    top: -1px
}

.badge-pill {
    padding-right: .6em;
    padding-left: .6em;
    border-radius: 10rem
}

.badge-primary {
    color: #fff;
    background-color: #3498db
}

a.badge-primary:focus, a.badge-primary:hover {
    color: #fff;
    background-color: #217dbb
}

a.badge-primary.focus, a.badge-primary:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .5)
}

.badge-secondary {
    color: #fff;
    background-color: #77838f
}

a.badge-secondary:focus, a.badge-secondary:hover {
    color: #fff;
    background-color: #5f6a74
}

a.badge-secondary.focus, a.badge-secondary:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(119, 131, 143, .5)
}

.badge-success {
    color: #fff;
    background-color: #00c9a7
}

a.badge-success:focus, a.badge-success:hover {
    color: #fff;
    background-color: #00967d
}

a.badge-success.focus, a.badge-success:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(0, 201, 167, .5)
}

.badge-info {
    color: #fff;
    background-color: #3498db
}

a.badge-info:focus, a.badge-info:hover {
    color: #fff;
    background-color: #217dbb
}

a.badge-info.focus, a.badge-info:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(52, 152, 219, .5)
}

.badge-warning {
    color: #1e2022;
    background-color: #db9a04
}

a.badge-warning:focus, a.badge-warning:hover {
    color: #1e2022;
    background-color: #a97703
}

a.badge-warning.focus, a.badge-warning:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(219, 154, 4, .5)
}

.badge-danger {
    color: #fff;
    background-color: #de4437
}

a.badge-danger:focus, a.badge-danger:hover {
    color: #fff;
    background-color: #c22d20
}

a.badge-danger.focus, a.badge-danger:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(222, 68, 55, .5)
}

.badge-light {
    color: #1e2022;
    background-color: #f8f9fa
}

a.badge-light:focus, a.badge-light:hover {
    color: #1e2022;
    background-color: #dae0e5
}

a.badge-light.focus, a.badge-light:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(248, 249, 250, .5)
}

.badge-dark {
    color: #fff;
    background-color: #1e2022
}

a.badge-dark:focus, a.badge-dark:hover {
    color: #fff;
    background-color: #060707
}

a.badge-dark.focus, a.badge-dark:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(30, 32, 34, .5)
}

.badge-white {
    color: #1e2022;
    background-color: #fff
}

a.badge-white:focus, a.badge-white:hover {
    color: #1e2022;
    background-color: #e6e6e6
}

a.badge-white.focus, a.badge-white:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem hsla(0, 0%, 100%, .5)
}

.badge-indigo {
    color: #fff;
    background-color: #2d1582
}

a.badge-indigo:focus, a.badge-indigo:hover {
    color: #fff;
    background-color: #1e0e56
}

a.badge-indigo.focus, a.badge-indigo:focus {
    outline: 0;
    box-shadow: 0 0 0 .2rem rgba(45, 21, 130, .5)
}

.jumbotron {
    padding: 2rem 1rem;
    margin-bottom: 2rem;
    background-color: #f8fafd;
    border-radius: .625rem
}

@media (min-width: 576px) {
    .jumbotron {
        padding: 4rem 2rem
    }
}

.jumbotron-fluid {
    padding-right: 0;
    padding-left: 0;
    border-radius: 0
}

.alert {
    position: relative;
    padding: .75rem;
    margin-bottom: 1rem;
    border: 0 solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 700
}

.alert-dismissible {
    padding-right: 2.51563rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem;
    color: inherit
}

.alert-primary {
    color: #1b4f72;
    background-color: #d6eaf8;
    border-color: #c6e2f5
}

.alert-primary hr {
    border-top-color: #b0d7f1
}

.alert-primary .alert-link {
    color: #113249
}

.alert-secondary {
    color: #3e444a;
    background-color: #e4e6e9;
    border-color: #d9dce0
}

.alert-secondary hr {
    border-top-color: #cbcfd5
}

.alert-secondary .alert-link {
    color: #272b2e
}

.alert-success {
    color: #006957;
    background-color: #ccf4ed;
    border-color: #b8f0e6
}

.alert-success hr {
    border-top-color: #a3ecdf
}

.alert-success .alert-link {
    color: #00362d
}

.alert-info {
    color: #1b4f72;
    background-color: #d6eaf8;
    border-color: #c6e2f5
}

.alert-info hr {
    border-top-color: #b0d7f1
}

.alert-info .alert-link {
    color: #113249
}

.alert-warning {
    color: #725002;
    background-color: #f8ebcd;
    border-color: #f5e3b9
}

.alert-warning hr {
    border-top-color: #f2daa3
}

.alert-warning .alert-link {
    color: #402d01
}

.alert-danger {
    color: #73231d;
    background-color: #f8dad7;
    border-color: #f6cbc7
}

.alert-danger hr {
    border-top-color: #f2b7b1
}

.alert-danger .alert-link {
    color: #4a1713
}

.alert-light {
    color: #818182;
    background-color: #fefefe;
    border-color: #fdfdfe
}

.alert-light hr {
    border-top-color: #ececf6
}

.alert-light .alert-link {
    color: #686868
}

.alert-dark {
    color: #101112;
    background-color: #d2d2d3;
    border-color: #c0c1c1
}

.alert-dark hr {
    border-top-color: #b3b4b4
}

.alert-dark .alert-link {
    color: #000
}

.alert-white {
    color: #858585;
    background-color: #fff;
    border-color: #fff
}

.alert-white hr {
    border-top-color: #f2f2f2
}

.alert-white .alert-link {
    color: #6c6c6c
}

.alert-indigo {
    color: #170b44;
    background-color: #d5d0e6;
    border-color: #c4bddc
}

.alert-indigo hr {
    border-top-color: #b5acd3
}

.alert-indigo .alert-link {
    color: #080418
}

@keyframes a {
    0% {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

.progress {
    height: 1rem;
    overflow: hidden;
    font-size: .60938rem;
    background-color: #e7eaf3;
    border-radius: .25rem
}

.progress, .progress-bar {
    display: -ms-flexbox;
    display: flex
}

.progress-bar {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    background-color: #3498db;
    transition: width .6s ease
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar {
        transition: none
    }
}

.progress-bar-striped {
    background-image: linear-gradient(45deg, hsla(0, 0%, 100%, .15) 25%, transparent 0, transparent 50%, hsla(0, 0%, 100%, .15) 0, hsla(0, 0%, 100%, .15) 75%, transparent 0, transparent);
    background-size: 1rem 1rem
}

.progress-bar-animated {
    animation: a 1s linear infinite
}

@media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
        animation: none
    }
}

.media {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start
}

.media-body {
    -ms-flex: 1;
    flex: 1
}

.list-group {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0
}

.list-group-item-action {
    width: 100%;
    color: #6c757e;
    text-align: inherit
}

.list-group-item-action:focus, .list-group-item-action:hover {
    z-index: 1;
    color: #3498db;
    text-decoration: none;
    background-color: transparent
}

.list-group-item-action:active {
    color: #3498db;
    background-color: transparent
}

.list-group-item {
    position: relative;
    display: block;
    padding: .425rem 1.25rem;
    margin-bottom: -1px;
    background-color: #fff;
    border: 1px solid #e7eaf3
}

.list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.list-group-item:last-child {
    margin-bottom: 0;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.list-group-item.disabled, .list-group-item:disabled {
    color: #8c98a4;
    pointer-events: none;
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.list-group-horizontal {
    -ms-flex-direction: row;
    flex-direction: row
}

.list-group-horizontal .list-group-item {
    margin-right: -1px;
    margin-bottom: 0
}

.list-group-horizontal .list-group-item:first-child {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

.list-group-horizontal .list-group-item:last-child {
    margin-right: 0;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: 0
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-sm .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }

    .list-group-horizontal-sm .list-group-item:first-child {
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-sm .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-md .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }

    .list-group-horizontal-md .list-group-item:first-child {
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-md .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-lg .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }

    .list-group-horizontal-lg .list-group-item:first-child {
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-lg .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        -ms-flex-direction: row;
        flex-direction: row
    }

    .list-group-horizontal-xl .list-group-item {
        margin-right: -1px;
        margin-bottom: 0
    }

    .list-group-horizontal-xl .list-group-item:first-child {
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    .list-group-horizontal-xl .list-group-item:last-child {
        margin-right: 0;
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: 0
    }
}

.list-group-flush .list-group-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0
}

.list-group-flush .list-group-item:last-child {
    margin-bottom: -1px
}

.list-group-flush:first-child .list-group-item:first-child {
    border-top: 0
}

.list-group-flush:last-child .list-group-item:last-child {
    margin-bottom: 0;
    border-bottom: 0
}

.list-group-item-primary {
    color: #1b4f72;
    background-color: #c6e2f5
}

.list-group-item-primary.list-group-item-action:focus, .list-group-item-primary.list-group-item-action:hover {
    color: #1b4f72;
    background-color: #b0d7f1
}

.list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #1b4f72;
    border-color: #1b4f72
}

.list-group-item-secondary {
    color: #3e444a;
    background-color: #d9dce0
}

.list-group-item-secondary.list-group-item-action:focus, .list-group-item-secondary.list-group-item-action:hover {
    color: #3e444a;
    background-color: #cbcfd5
}

.list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #3e444a;
    border-color: #3e444a
}

.list-group-item-success {
    color: #006957;
    background-color: #b8f0e6
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
    color: #006957;
    background-color: #a3ecdf
}

.list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #006957;
    border-color: #006957
}

.list-group-item-info {
    color: #1b4f72;
    background-color: #c6e2f5
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
    color: #1b4f72;
    background-color: #b0d7f1
}

.list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: #1b4f72;
    border-color: #1b4f72
}

.list-group-item-warning {
    color: #725002;
    background-color: #f5e3b9
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
    color: #725002;
    background-color: #f2daa3
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #725002;
    border-color: #725002
}

.list-group-item-danger {
    color: #73231d;
    background-color: #f6cbc7
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
    color: #73231d;
    background-color: #f2b7b1
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #73231d;
    border-color: #73231d
}

.list-group-item-light {
    color: #818182;
    background-color: #fdfdfe
}

.list-group-item-light.list-group-item-action:focus, .list-group-item-light.list-group-item-action:hover {
    color: #818182;
    background-color: #ececf6
}

.list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182
}

.list-group-item-dark {
    color: #101112;
    background-color: #c0c1c1
}

.list-group-item-dark.list-group-item-action:focus, .list-group-item-dark.list-group-item-action:hover {
    color: #101112;
    background-color: #b3b4b4
}

.list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #101112;
    border-color: #101112
}

.list-group-item-white {
    color: #858585;
    background-color: #fff
}

.list-group-item-white.list-group-item-action:focus, .list-group-item-white.list-group-item-action:hover {
    color: #858585;
    background-color: #f2f2f2
}

.list-group-item-white.list-group-item-action.active {
    color: #fff;
    background-color: #858585;
    border-color: #858585
}

.list-group-item-indigo {
    color: #170b44;
    background-color: #c4bddc
}

.list-group-item-indigo.list-group-item-action:focus, .list-group-item-indigo.list-group-item-action:hover {
    color: #170b44;
    background-color: #b5acd3
}

.list-group-item-indigo.list-group-item-action.active {
    color: #fff;
    background-color: #170b44;
    border-color: #170b44
}

.close {
    float: right;
    font-size: 1.01563rem;
    font-weight: 700;
    line-height: 1;
    color: #1e2022;
    text-shadow: 0 1px 0 #fff;
    opacity: .5
}

.close:hover {
    color: #1e2022;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

button.close {
    padding: 0;
    background-color: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

a.close.disabled {
    pointer-events: none
}

.toast {
    max-width: 350px;
    overflow: hidden;
    font-size: .875rem;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .1);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    opacity: 0;
    border-radius: .25rem
}

.toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding: .25rem .75rem;
    color: #8c98a4;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

.toast-body {
    padding: .75rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    margin: .5rem;
    pointer-events: none
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out;
    transform: translateY(-50px)
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

.modal.show .modal-dialog {
    transform: none
}

.modal-dialog-scrollable {
    display: -ms-flexbox;
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {
    -ms-flex-negative: 0;
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered:before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable:before {
    content: none
}

.modal-content {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .25rem;
    outline: 0
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh;
    background-color: #000
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: .5
}

.modal-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: start;
    align-items: flex-start;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #e7eaf3;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.modal-header .close {
    padding: 1rem;
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    margin-bottom: 0;
    line-height: 1.5
}

.modal-body {
    position: relative;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1rem
}

.modal-footer {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 1rem;
    border-top: 1px solid #e7eaf3;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.modal-footer > :not(:first-child) {
    margin-left: .25rem
}

.modal-footer > :not(:last-child) {
    margin-right: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px;
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered:before {
        height: calc(100vh - 3.5rem)
    }

    .modal-sm {
        max-width: 320px
    }
}

@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 800px
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.tooltip {
    position: absolute;
    z-index: 9999;
    display: block;
    margin: 0;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .71094rem;
    word-wrap: break-word;
    opacity: 0
}

.tooltip.show {
    opacity: 1
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow:before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-tooltip-auto[x-placement^=top], .bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow:before, .bs-tooltip-top .arrow:before {
    top: 0;
    border-width: .4rem .4rem 0;
    border-top-color: #6c757e
}

.bs-tooltip-auto[x-placement^=right], .bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
    left: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=right] .arrow:before, .bs-tooltip-right .arrow:before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #6c757e
}

.bs-tooltip-auto[x-placement^=bottom], .bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow:before, .bs-tooltip-bottom .arrow:before {
    bottom: 0;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #6c757e
}

.bs-tooltip-auto[x-placement^=left], .bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
    right: 0;
    width: .4rem;
    height: .8rem
}

.bs-tooltip-auto[x-placement^=left] .arrow:before, .bs-tooltip-left .arrow:before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #6c757e
}

.tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #6c757e;
    border-radius: .125rem
}

.popover {
    top: 0;
    left: 0;
    z-index: 1060;
    max-width: 276px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .71094rem;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: .25rem
}

.popover, .popover .arrow {
    position: absolute;
    display: block
}

.popover .arrow {
    width: 1rem;
    height: .5rem;
    margin: 0 .625rem
}

.popover .arrow:after, .popover .arrow:before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
}

.bs-popover-auto[x-placement^=top], .bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top] > .arrow, .bs-popover-top > .arrow {
    bottom: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=top] > .arrow:before, .bs-popover-top > .arrow:before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=top] > .arrow:after, .bs-popover-top > .arrow:after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

.bs-popover-auto[x-placement^=right], .bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right] > .arrow, .bs-popover-right > .arrow {
    left: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .625rem 0
}

.bs-popover-auto[x-placement^=right] > .arrow:before, .bs-popover-right > .arrow:before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=right] > .arrow:after, .bs-popover-right > .arrow:after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

.bs-popover-auto[x-placement^=bottom], .bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom] > .arrow, .bs-popover-bottom > .arrow {
    top: calc((.5rem + 1px) * -1)
}

.bs-popover-auto[x-placement^=bottom] > .arrow:before, .bs-popover-bottom > .arrow:before {
    top: 0;
    border-width: 0 .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=bottom] > .arrow:after, .bs-popover-bottom > .arrow:after {
    top: 1px;
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header:before, .bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f7f7f7
}

.bs-popover-auto[x-placement^=left], .bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left] > .arrow, .bs-popover-left > .arrow {
    right: calc((.5rem + 1px) * -1);
    width: .5rem;
    height: 1rem;
    margin: .625rem 0
}

.bs-popover-auto[x-placement^=left] > .arrow:before, .bs-popover-left > .arrow:before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25)
}

.bs-popover-auto[x-placement^=left] > .arrow:after, .bs-popover-left > .arrow:after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    font-size: .8125rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(.625rem - 1px);
    border-top-right-radius: calc(.625rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    padding: .5rem .75rem;
    color: #1e2022
}

.carousel {
    position: relative
}

.carousel.pointer-event {
    -ms-touch-action: pan-y;
    touch-action: pan-y
}

.carousel-inner {
    position: relative;
    width: 100%;
    overflow: hidden
}

.carousel-inner:after {
    display: block;
    clear: both;
    content: ""
}

.carousel-item {
    position: relative;
    display: none;
    float: left;
    width: 100%;
    margin-right: -100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transition: transform .6s ease-in-out
}

@media (prefers-reduced-motion: reduce) {
    .carousel-item {
        transition: none
    }
}

.carousel-item-next, .carousel-item-prev, .carousel-item.active {
    display: block
}

.active.carousel-item-right, .carousel-item-next:not(.carousel-item-left) {
    transform: translateX(100%)
}

.active.carousel-item-left, .carousel-item-prev:not(.carousel-item-right) {
    transform: translateX(-100%)
}

.carousel-fade .carousel-item {
    opacity: 0;
    transition-property: opacity;
    transform: none
}

.carousel-fade .carousel-item-next.carousel-item-left, .carousel-fade .carousel-item-prev.carousel-item-right, .carousel-fade .carousel-item.active {
    z-index: 1;
    opacity: 1
}

.carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
    z-index: 0;
    opacity: 0;
    transition: opacity 0s .6s
}

@media (prefers-reduced-motion: reduce) {
    .carousel-fade .active.carousel-item-left, .carousel-fade .active.carousel-item-right {
        transition: none
    }
}

.carousel-control-next, .carousel-control-prev {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    width: 15%;
    color: #fff;
    text-align: center;
    opacity: .5;
    transition: opacity .15s ease
}

@media (prefers-reduced-motion: reduce) {
    .carousel-control-next, .carousel-control-prev {
        transition: none
    }
}

.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    color: #fff;
    text-decoration: none;
    outline: 0;
    opacity: .9
}

.carousel-control-prev {
    left: 0
}

.carousel-control-next {
    right: 0
}

.carousel-control-next-icon, .carousel-control-prev-icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background: no-repeat 50%/100% 100%
}

.carousel-control-prev-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/%3E%3C/svg%3E")
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23fff' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/%3E%3C/svg%3E")
}

.carousel-indicators {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none
}

.carousel-indicators li {
    box-sizing: content-box;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 30px;
    height: 3px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    cursor: pointer;
    background-color: #fff;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    opacity: .5;
    transition: opacity .6s ease
}

@media (prefers-reduced-motion: reduce) {
    .carousel-indicators li {
        transition: none
    }
}

.carousel-indicators .active {
    opacity: 1
}

.carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 20px;
    left: 15%;
    z-index: 10;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    text-align: center
}

@keyframes b {
    to {
        transform: rotate(1turn)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    animation: b .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem;
    border-width: .2em
}

@keyframes c {
    0% {
        transform: scale(0)
    }
    50% {
        opacity: 1
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    background-color: currentColor;
    border-radius: 50%;
    opacity: 0;
    animation: c .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

.bg-primary {
    background-color: #3498db !important
}

a.bg-primary:focus, a.bg-primary:hover, button.bg-primary:focus, button.bg-primary:hover {
    background-color: #217dbb !important
}

.bg-secondary {
    background-color: #77838f !important
}

a.bg-secondary:focus, a.bg-secondary:hover, button.bg-secondary:focus, button.bg-secondary:hover {
    background-color: #5f6a74 !important
}

.bg-success {
    background-color: #00c9a7 !important
}

a.bg-success:focus, a.bg-success:hover, button.bg-success:focus, button.bg-success:hover {
    background-color: #00967d !important
}

.bg-info {
    background-color: #3498db !important
}

a.bg-info:focus, a.bg-info:hover, button.bg-info:focus, button.bg-info:hover {
    background-color: #217dbb !important
}

.bg-warning {
    background-color: #db9a04 !important
}

a.bg-warning:focus, a.bg-warning:hover, button.bg-warning:focus, button.bg-warning:hover {
    background-color: #a97703 !important
}

.bg-danger {
    background-color: #de4437 !important
}

a.bg-danger:focus, a.bg-danger:hover, button.bg-danger:focus, button.bg-danger:hover {
    background-color: #c22d20 !important
}

.bg-light {
    background-color: #f8f9fa !important
}

a.bg-light:focus, a.bg-light:hover, button.bg-light:focus, button.bg-light:hover {
    background-color: #dae0e5 !important
}

.bg-dark {
    background-color: #1e2022 !important
}

a.bg-dark:focus, a.bg-dark:hover, button.bg-dark:focus, button.bg-dark:hover {
    background-color: #060707 !important
}

a.bg-white:focus, a.bg-white:hover, button.bg-white:focus, button.bg-white:hover {
    background-color: #e6e6e6 !important
}

.bg-indigo {
    background-color: #2d1582 !important
}

a.bg-indigo:focus, a.bg-indigo:hover, button.bg-indigo:focus, button.bg-indigo:hover {
    background-color: #1e0e56 !important
}

.bg-white {
    background-color: #fff !important
}

.bg-transparent {
    background-color: transparent !important
}

.border {
    border: 1px solid #e7eaf3 !important
}

.border-top {
    border-top: 1px solid #e7eaf3 !important
}

.border-right {
    border-right: 1px solid #e7eaf3 !important
}

.border-bottom {
    border-bottom: 1px solid #e7eaf3 !important
}

.border-left {
    border-left: 1px solid #e7eaf3 !important
}

.border-0 {
    border: 0 !important
}

.border-top-0 {
    border-top: 0 !important
}

.border-right-0 {
    border-right: 0 !important
}

.border-bottom-0 {
    border-bottom: 0 !important
}

.border-left-0 {
    border-left: 0 !important
}

.border-primary {
    border-color: #3498db !important
}

.border-secondary {
    border-color: #77838f !important
}

.border-success {
    border-color: #00c9a7 !important
}

.border-info {
    border-color: #3498db !important
}

.border-warning {
    border-color: #db9a04 !important
}

.border-danger {
    border-color: #de4437 !important
}

.border-light {
    border-color: #f8f9fa !important
}

.border-dark {
    border-color: #1e2022 !important
}

.border-indigo {
    border-color: #2d1582 !important
}

.border-white {
    border-color: #fff !important
}

.rounded {
    border-radius: .25rem !important
}

.rounded-top {
    border-top-left-radius: .25rem !important
}

.rounded-right, .rounded-top {
    border-top-right-radius: .25rem !important
}

.rounded-bottom, .rounded-right {
    border-bottom-right-radius: .25rem !important
}

.rounded-bottom, .rounded-left {
    border-bottom-left-radius: .25rem !important
}

.rounded-left {
    border-top-left-radius: .25rem !important
}

.rounded-lg {
    border-radius: .625rem !important
}

.rounded-circle {
    border-radius: 50% !important
}

.rounded-pill {
    border-radius: 50rem !important
}

.rounded-0 {
    border-radius: 0 !important
}

.clearfix:after {
    display: block;
    clear: both;
    content: ""
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: -ms-flexbox !important;
    display: flex !important
}

.d-inline-flex {
    display: -ms-inline-flexbox !important;
    display: inline-flex !important
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-sm-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-md-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-lg-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media (min-width: 1200px) {
    .d-xl-none {
        display: none !important
    }

    .d-xl-inline {
        display: inline !important
    }

    .d-xl-inline-block {
        display: inline-block !important
    }

    .d-xl-block {
        display: block !important
    }

    .d-xl-table {
        display: table !important
    }

    .d-xl-table-row {
        display: table-row !important
    }

    .d-xl-table-cell {
        display: table-cell !important
    }

    .d-xl-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-xl-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

@media print {
    .d-print-none {
        display: none !important
    }

    .d-print-inline {
        display: inline !important
    }

    .d-print-inline-block {
        display: inline-block !important
    }

    .d-print-block {
        display: block !important
    }

    .d-print-table {
        display: table !important
    }

    .d-print-table-row {
        display: table-row !important
    }

    .d-print-table-cell {
        display: table-cell !important
    }

    .d-print-flex {
        display: -ms-flexbox !important;
        display: flex !important
    }

    .d-print-inline-flex {
        display: -ms-inline-flexbox !important;
        display: inline-flex !important
    }
}

.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden
}

.embed-responsive:before {
    display: block;
    content: ""
}

.embed-responsive .embed-responsive-item, .embed-responsive embed, .embed-responsive iframe, .embed-responsive object, .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0
}

.embed-responsive-21by9:before {
    padding-top: 42.85714%
}

.embed-responsive-16by9:before {
    padding-top: 56.25%
}

.embed-responsive-4by3:before {
    padding-top: 75%
}

.embed-responsive-1by1:before {
    padding-top: 100%
}

.flex-row {
    -ms-flex-direction: row !important;
    flex-direction: row !important
}

.flex-column {
    -ms-flex-direction: column !important;
    flex-direction: column !important
}

.flex-row-reverse {
    -ms-flex-direction: row-reverse !important;
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    -ms-flex-direction: column-reverse !important;
    flex-direction: column-reverse !important
}

.flex-wrap {
    -ms-flex-wrap: wrap !important;
    flex-wrap: wrap !important
}

.flex-nowrap {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    -ms-flex: 1 1 auto !important;
    flex: 1 1 auto !important
}

.flex-grow-0 {
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important
}

.flex-grow-1 {
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important
}

.flex-shrink-0 {
    -ms-flex-negative: 0 !important;
    flex-shrink: 0 !important
}

.flex-shrink-1 {
    -ms-flex-negative: 1 !important;
    flex-shrink: 1 !important
}

.justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important
}

.justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important
}

.justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important
}

.justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important
}

.justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important
}

.align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important
}

.align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important
}

.align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important
}

.align-items-baseline {
    -ms-flex-align: baseline !important;
    align-items: baseline !important
}

.align-items-stretch {
    -ms-flex-align: stretch !important;
    align-items: stretch !important
}

.align-content-start {
    -ms-flex-line-pack: start !important;
    align-content: flex-start !important
}

.align-content-end {
    -ms-flex-line-pack: end !important;
    align-content: flex-end !important
}

.align-content-center {
    -ms-flex-line-pack: center !important;
    align-content: center !important
}

.align-content-between {
    -ms-flex-line-pack: justify !important;
    align-content: space-between !important
}

.align-content-around {
    -ms-flex-line-pack: distribute !important;
    align-content: space-around !important
}

.align-content-stretch {
    -ms-flex-line-pack: stretch !important;
    align-content: stretch !important
}

.align-self-auto {
    -ms-flex-item-align: auto !important;
    align-self: auto !important
}

.align-self-start {
    -ms-flex-item-align: start !important;
    align-self: flex-start !important
}

.align-self-end {
    -ms-flex-item-align: end !important;
    align-self: flex-end !important
}

.align-self-center {
    -ms-flex-item-align: center !important;
    align-self: center !important
}

.align-self-baseline {
    -ms-flex-item-align: baseline !important;
    align-self: baseline !important
}

.align-self-stretch {
    -ms-flex-item-align: stretch !important;
    align-self: stretch !important
}

@media (min-width: 576px) {
    .flex-sm-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-sm-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-sm-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-sm-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-sm-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-sm-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-sm-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-sm-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-sm-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-sm-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-sm-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-sm-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-sm-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-sm-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-sm-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-sm-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-sm-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-md-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-md-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-md-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-md-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-md-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-md-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-md-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-md-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-md-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-md-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-md-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-md-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-md-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-md-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-md-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-md-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-md-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-md-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-md-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-md-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-lg-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-lg-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-lg-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-lg-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-lg-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-lg-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-lg-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-lg-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-lg-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-lg-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-lg-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-lg-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-lg-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-lg-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-lg-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-lg-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        -ms-flex-direction: row !important;
        flex-direction: row !important
    }

    .flex-xl-column {
        -ms-flex-direction: column !important;
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        -ms-flex-direction: row-reverse !important;
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        -ms-flex-direction: column-reverse !important;
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        -ms-flex: 1 1 auto !important;
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        -ms-flex-positive: 0 !important;
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        -ms-flex-positive: 1 !important;
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important
    }

    .justify-content-xl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important
    }

    .align-items-xl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important
    }

    .align-items-xl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important
    }

    .align-items-xl-center {
        -ms-flex-align: center !important;
        align-items: center !important
    }

    .align-items-xl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important
    }

    .align-content-xl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important
    }

    .align-content-xl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important
    }

    .align-content-xl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important
    }

    .align-content-xl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important
    }

    .align-content-xl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important
    }

    .align-self-xl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important
    }

    .align-self-xl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important
    }

    .align-self-xl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important
    }

    .align-self-xl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important
    }

    .align-self-xl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important
    }
}

.float-left {
    float: left !important
}

.float-right {
    float: right !important
}

.float-none {
    float: none !important
}

@media (min-width: 576px) {
    .float-sm-left {
        float: left !important
    }

    .float-sm-right {
        float: right !important
    }

    .float-sm-none {
        float: none !important
    }
}

@media (min-width: 768px) {
    .float-md-left {
        float: left !important
    }

    .float-md-right {
        float: right !important
    }

    .float-md-none {
        float: none !important
    }
}

@media (min-width: 992px) {
    .float-lg-left {
        float: left !important
    }

    .float-lg-right {
        float: right !important
    }

    .float-lg-none {
        float: none !important
    }
}

@media (min-width: 1200px) {
    .float-xl-left {
        float: left !important
    }

    .float-xl-right {
        float: right !important
    }

    .float-xl-none {
        float: none !important
    }
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.fixed-top {
    top: 0
}

.fixed-bottom, .fixed-top {
    position: fixed;
    right: 0;
    left: 0;
    z-index: 1030
}

.fixed-bottom {
    bottom: 0
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

.shadow-sm {
    box-shadow: 0 0 35px rgba(140, 152, 164, .125) !important
}

.shadow {
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125) !important
}

.shadow-lg {
    box-shadow: 0 10px 40px 10px rgba(140, 152, 164, .175) !important
}

.shadow-none {
    box-shadow: none !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.h-55 {
    height: 55% !important
}

.h-60 {
    height: 60% !important
}

.h-80 {
    height: 80% !important
}

.h-90 {
    height: 90% !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

.stretched-link:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    pointer-events: auto;
    content: "";
    background-color: transparent
}

.m-0 {
    margin: 0 !important
}

.mt-0, .my-0 {
    margin-top: 0 !important
}

.mr-0, .mx-0 {
    margin-right: 0 !important
}

.mb-0, .my-0 {
    margin-bottom: 0 !important
}

.ml-0, .mx-0 {
    margin-left: 0 !important
}

.m-1 {
    margin: .25rem !important
}

.mt-1, .my-1 {
    margin-top: .25rem !important
}

.mr-1, .mx-1 {
    margin-right: .25rem !important
}

.mb-1, .my-1 {
    margin-bottom: .25rem !important
}

.ml-1, .mx-1 {
    margin-left: .25rem !important
}

.m-2 {
    margin: .5rem !important
}

.mt-2, .my-2 {
    margin-top: .5rem !important
}

.mr-2, .mx-2 {
    margin-right: .5rem !important
}

.mb-2, .my-2 {
    margin-bottom: .5rem !important
}

.ml-2, .mx-2 {
    margin-left: .5rem !important
}

.m-3 {
    margin: .75rem !important
}

.mt-3, .my-3 {
    margin-top: .75rem !important
}

.mr-3, .mx-3 {
    margin-right: .75rem !important
}

.mb-3, .my-3 {
    margin-bottom: .75rem !important
}

.ml-3, .mx-3 {
    margin-left: .75rem !important
}

.m-4 {
    margin: 1.25rem !important
}

.mt-4, .my-4 {
    margin-top: 1.25rem !important
}

.mr-4, .mx-4 {
    margin-right: 1.25rem !important
}

.mb-4, .my-4 {
    margin-bottom: 1.25rem !important
}

.ml-4, .mx-4 {
    margin-left: 1.25rem !important
}

.m-5 {
    margin: 3rem !important
}

.mt-5, .my-5 {
    margin-top: 3rem !important
}

.mr-5, .mx-5 {
    margin-right: 3rem !important
}

.mb-5, .my-5 {
    margin-bottom: 3rem !important
}

.ml-5, .mx-5 {
    margin-left: 3rem !important
}

.m-5-alt {
    margin: 2rem !important
}

.mt-5-alt, .my-5-alt {
    margin-top: 2rem !important
}

.mr-5-alt, .mx-5-alt {
    margin-right: 2rem !important
}

.mb-5-alt, .my-5-alt {
    margin-bottom: 2rem !important
}

.ml-5-alt, .mx-5-alt {
    margin-left: 2rem !important
}

.m-6 {
    margin: 3.5rem !important
}

.mt-6, .my-6 {
    margin-top: 3.5rem !important
}

.mr-6, .mx-6 {
    margin-right: 3.5rem !important
}

.mb-6, .my-6 {
    margin-bottom: 3.5rem !important
}

.ml-6, .mx-6 {
    margin-left: 3.5rem !important
}

.m-9 {
    margin: 4rem !important
}

.mt-9, .my-9 {
    margin-top: 4rem !important
}

.mr-9, .mx-9 {
    margin-right: 4rem !important
}

.mb-9, .my-9 {
    margin-bottom: 4rem !important
}

.ml-9, .mx-9 {
    margin-left: 4rem !important
}

.p-0 {
    padding: 0 !important
}

.pt-0, .py-0 {
    padding-top: 0 !important
}

.pr-0, .px-0 {
    padding-right: 0 !important
}

.pb-0, .py-0 {
    padding-bottom: 0 !important
}

.pl-0, .px-0 {
    padding-left: 0 !important
}

.p-1 {
    padding: .25rem !important
}

.pt-1, .py-1 {
    padding-top: .25rem !important
}

.pr-1, .px-1 {
    padding-right: .25rem !important
}

.pb-1, .py-1 {
    padding-bottom: .25rem !important
}

.pl-1, .px-1 {
    padding-left: .25rem !important
}

.p-2 {
    padding: .5rem !important
}

.pt-2, .py-2 {
    padding-top: .5rem !important
}

.pr-2, .px-2 {
    padding-right: .5rem !important
}

.pb-2, .py-2 {
    padding-bottom: .5rem !important
}

.pl-2, .px-2 {
    padding-left: .5rem !important
}

.p-3 {
    padding: .75rem !important
}

.pt-3, .py-3 {
    padding-top: .75rem !important
}

.pr-3, .px-3 {
    padding-right: .75rem !important
}

.pb-3, .py-3 {
    padding-bottom: .75rem !important
}

.pl-3, .px-3 {
    padding-left: .75rem !important
}

.p-4 {
    padding: 1.25rem !important
}

.pt-4, .py-4 {
    padding-top: 1.25rem !important
}

.pr-4, .px-4 {
    padding-right: 1.25rem !important
}

.pb-4, .py-4 {
    padding-bottom: 1.25rem !important
}

.pl-4, .px-4 {
    padding-left: 1.25rem !important
}

.p-5 {
    padding: 3rem !important
}

.pt-5, .py-5 {
    padding-top: 3rem !important
}

.pr-5, .px-5 {
    padding-right: 3rem !important
}

.pb-5, .py-5 {
    padding-bottom: 3rem !important
}

.pl-5, .px-5 {
    padding-left: 3rem !important
}

.p-5-alt {
    padding: 2rem !important
}

.pt-5-alt, .py-5-alt {
    padding-top: 2rem !important
}

.pr-5-alt, .px-5-alt {
    padding-right: 2rem !important
}

.pb-5-alt, .py-5-alt {
    padding-bottom: 2rem !important
}

.pl-5-alt, .px-5-alt {
    padding-left: 2rem !important
}

.p-6 {
    padding: 3.5rem !important
}

.pt-6, .py-6 {
    padding-top: 3.5rem !important
}

.pr-6, .px-6 {
    padding-right: 3.5rem !important
}

.pb-6, .py-6 {
    padding-bottom: 3.5rem !important
}

.pl-6, .px-6 {
    padding-left: 3.5rem !important
}

.p-9 {
    padding: 4rem !important
}

.pt-9, .py-9 {
    padding-top: 4rem !important
}

.pr-9, .px-9 {
    padding-right: 4rem !important
}

.pb-9, .py-9 {
    padding-bottom: 4rem !important
}

.pl-9, .px-9 {
    padding-left: 4rem !important
}

.m-n1 {
    margin: -.25rem !important
}

.mt-n1, .my-n1 {
    margin-top: -.25rem !important
}

.mr-n1, .mx-n1 {
    margin-right: -.25rem !important
}

.mb-n1, .my-n1 {
    margin-bottom: -.25rem !important
}

.ml-n1, .mx-n1 {
    margin-left: -.25rem !important
}

.m-n2 {
    margin: -.5rem !important
}

.mt-n2, .my-n2 {
    margin-top: -.5rem !important
}

.mr-n2, .mx-n2 {
    margin-right: -.5rem !important
}

.mb-n2, .my-n2 {
    margin-bottom: -.5rem !important
}

.ml-n2, .mx-n2 {
    margin-left: -.5rem !important
}

.m-n3 {
    margin: -.75rem !important
}

.mt-n3, .my-n3 {
    margin-top: -.75rem !important
}

.mr-n3, .mx-n3 {
    margin-right: -.75rem !important
}

.mb-n3, .my-n3 {
    margin-bottom: -.75rem !important
}

.ml-n3, .mx-n3 {
    margin-left: -.75rem !important
}

.m-n4 {
    margin: -1.25rem !important
}

.mt-n4, .my-n4 {
    margin-top: -1.25rem !important
}

.mr-n4, .mx-n4 {
    margin-right: -1.25rem !important
}

.mb-n4, .my-n4 {
    margin-bottom: -1.25rem !important
}

.ml-n4, .mx-n4 {
    margin-left: -1.25rem !important
}

.m-n5 {
    margin: -3rem !important
}

.mt-n5, .my-n5 {
    margin-top: -3rem !important
}

.mr-n5, .mx-n5 {
    margin-right: -3rem !important
}

.mb-n5, .my-n5 {
    margin-bottom: -3rem !important
}

.ml-n5, .mx-n5 {
    margin-left: -3rem !important
}

.m-n5-alt {
    margin: -2rem !important
}

.mt-n5-alt, .my-n5-alt {
    margin-top: -2rem !important
}

.mr-n5-alt, .mx-n5-alt {
    margin-right: -2rem !important
}

.mb-n5-alt, .my-n5-alt {
    margin-bottom: -2rem !important
}

.ml-n5-alt, .mx-n5-alt {
    margin-left: -2rem !important
}

.m-n6 {
    margin: -3.5rem !important
}

.mt-n6, .my-n6 {
    margin-top: -3.5rem !important
}

.mr-n6, .mx-n6 {
    margin-right: -3.5rem !important
}

.mb-n6, .my-n6 {
    margin-bottom: -3.5rem !important
}

.ml-n6, .mx-n6 {
    margin-left: -3.5rem !important
}

.m-n9 {
    margin: -4rem !important
}

.mt-n9, .my-n9 {
    margin-top: -4rem !important
}

.mr-n9, .mx-n9 {
    margin-right: -4rem !important
}

.mb-n9, .my-n9 {
    margin-bottom: -4rem !important
}

.ml-n9, .mx-n9 {
    margin-left: -4rem !important
}

.m-auto {
    margin: auto !important
}

.mt-auto, .my-auto {
    margin-top: auto !important
}

.mr-auto, .mx-auto {
    margin-right: auto !important
}

.mb-auto, .my-auto {
    margin-bottom: auto !important
}

.ml-auto, .mx-auto {
    margin-left: auto !important
}

@media (min-width: 576px) {
    .m-sm-0 {
        margin: 0 !important
    }

    .mt-sm-0, .my-sm-0 {
        margin-top: 0 !important
    }

    .mr-sm-0, .mx-sm-0 {
        margin-right: 0 !important
    }

    .mb-sm-0, .my-sm-0 {
        margin-bottom: 0 !important
    }

    .ml-sm-0, .mx-sm-0 {
        margin-left: 0 !important
    }

    .m-sm-1 {
        margin: .25rem !important
    }

    .mt-sm-1, .my-sm-1 {
        margin-top: .25rem !important
    }

    .mr-sm-1, .mx-sm-1 {
        margin-right: .25rem !important
    }

    .mb-sm-1, .my-sm-1 {
        margin-bottom: .25rem !important
    }

    .ml-sm-1, .mx-sm-1 {
        margin-left: .25rem !important
    }

    .m-sm-2 {
        margin: .5rem !important
    }

    .mt-sm-2, .my-sm-2 {
        margin-top: .5rem !important
    }

    .mr-sm-2, .mx-sm-2 {
        margin-right: .5rem !important
    }

    .mb-sm-2, .my-sm-2 {
        margin-bottom: .5rem !important
    }

    .ml-sm-2, .mx-sm-2 {
        margin-left: .5rem !important
    }

    .m-sm-3 {
        margin: .75rem !important
    }

    .mt-sm-3, .my-sm-3 {
        margin-top: .75rem !important
    }

    .mr-sm-3, .mx-sm-3 {
        margin-right: .75rem !important
    }

    .mb-sm-3, .my-sm-3 {
        margin-bottom: .75rem !important
    }

    .ml-sm-3, .mx-sm-3 {
        margin-left: .75rem !important
    }

    .m-sm-4 {
        margin: 1.25rem !important
    }

    .mt-sm-4, .my-sm-4 {
        margin-top: 1.25rem !important
    }

    .mr-sm-4, .mx-sm-4 {
        margin-right: 1.25rem !important
    }

    .mb-sm-4, .my-sm-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-sm-4, .mx-sm-4 {
        margin-left: 1.25rem !important
    }

    .m-sm-5 {
        margin: 3rem !important
    }

    .mt-sm-5, .my-sm-5 {
        margin-top: 3rem !important
    }

    .mr-sm-5, .mx-sm-5 {
        margin-right: 3rem !important
    }

    .mb-sm-5, .my-sm-5 {
        margin-bottom: 3rem !important
    }

    .ml-sm-5, .mx-sm-5 {
        margin-left: 3rem !important
    }

    .m-sm-5-alt {
        margin: 2rem !important
    }

    .mt-sm-5-alt, .my-sm-5-alt {
        margin-top: 2rem !important
    }

    .mr-sm-5-alt, .mx-sm-5-alt {
        margin-right: 2rem !important
    }

    .mb-sm-5-alt, .my-sm-5-alt {
        margin-bottom: 2rem !important
    }

    .ml-sm-5-alt, .mx-sm-5-alt {
        margin-left: 2rem !important
    }

    .m-sm-6 {
        margin: 3.5rem !important
    }

    .mt-sm-6, .my-sm-6 {
        margin-top: 3.5rem !important
    }

    .mr-sm-6, .mx-sm-6 {
        margin-right: 3.5rem !important
    }

    .mb-sm-6, .my-sm-6 {
        margin-bottom: 3.5rem !important
    }

    .ml-sm-6, .mx-sm-6 {
        margin-left: 3.5rem !important
    }

    .m-sm-9 {
        margin: 4rem !important
    }

    .mt-sm-9, .my-sm-9 {
        margin-top: 4rem !important
    }

    .mr-sm-9, .mx-sm-9 {
        margin-right: 4rem !important
    }

    .mb-sm-9, .my-sm-9 {
        margin-bottom: 4rem !important
    }

    .ml-sm-9, .mx-sm-9 {
        margin-left: 4rem !important
    }

    .p-sm-0 {
        padding: 0 !important
    }

    .pt-sm-0, .py-sm-0 {
        padding-top: 0 !important
    }

    .pr-sm-0, .px-sm-0 {
        padding-right: 0 !important
    }

    .pb-sm-0, .py-sm-0 {
        padding-bottom: 0 !important
    }

    .pl-sm-0, .px-sm-0 {
        padding-left: 0 !important
    }

    .p-sm-1 {
        padding: .25rem !important
    }

    .pt-sm-1, .py-sm-1 {
        padding-top: .25rem !important
    }

    .pr-sm-1, .px-sm-1 {
        padding-right: .25rem !important
    }

    .pb-sm-1, .py-sm-1 {
        padding-bottom: .25rem !important
    }

    .pl-sm-1, .px-sm-1 {
        padding-left: .25rem !important
    }

    .p-sm-2 {
        padding: .5rem !important
    }

    .pt-sm-2, .py-sm-2 {
        padding-top: .5rem !important
    }

    .pr-sm-2, .px-sm-2 {
        padding-right: .5rem !important
    }

    .pb-sm-2, .py-sm-2 {
        padding-bottom: .5rem !important
    }

    .pl-sm-2, .px-sm-2 {
        padding-left: .5rem !important
    }

    .p-sm-3 {
        padding: .75rem !important
    }

    .pt-sm-3, .py-sm-3 {
        padding-top: .75rem !important
    }

    .pr-sm-3, .px-sm-3 {
        padding-right: .75rem !important
    }

    .pb-sm-3, .py-sm-3 {
        padding-bottom: .75rem !important
    }

    .pl-sm-3, .px-sm-3 {
        padding-left: .75rem !important
    }

    .p-sm-4 {
        padding: 1.25rem !important
    }

    .pt-sm-4, .py-sm-4 {
        padding-top: 1.25rem !important
    }

    .pr-sm-4, .px-sm-4 {
        padding-right: 1.25rem !important
    }

    .pb-sm-4, .py-sm-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-sm-4, .px-sm-4 {
        padding-left: 1.25rem !important
    }

    .p-sm-5 {
        padding: 3rem !important
    }

    .pt-sm-5, .py-sm-5 {
        padding-top: 3rem !important
    }

    .pr-sm-5, .px-sm-5 {
        padding-right: 3rem !important
    }

    .pb-sm-5, .py-sm-5 {
        padding-bottom: 3rem !important
    }

    .pl-sm-5, .px-sm-5 {
        padding-left: 3rem !important
    }

    .p-sm-5-alt {
        padding: 2rem !important
    }

    .pt-sm-5-alt, .py-sm-5-alt {
        padding-top: 2rem !important
    }

    .pr-sm-5-alt, .px-sm-5-alt {
        padding-right: 2rem !important
    }

    .pb-sm-5-alt, .py-sm-5-alt {
        padding-bottom: 2rem !important
    }

    .pl-sm-5-alt, .px-sm-5-alt {
        padding-left: 2rem !important
    }

    .p-sm-6 {
        padding: 3.5rem !important
    }

    .pt-sm-6, .py-sm-6 {
        padding-top: 3.5rem !important
    }

    .pr-sm-6, .px-sm-6 {
        padding-right: 3.5rem !important
    }

    .pb-sm-6, .py-sm-6 {
        padding-bottom: 3.5rem !important
    }

    .pl-sm-6, .px-sm-6 {
        padding-left: 3.5rem !important
    }

    .p-sm-9 {
        padding: 4rem !important
    }

    .pt-sm-9, .py-sm-9 {
        padding-top: 4rem !important
    }

    .pr-sm-9, .px-sm-9 {
        padding-right: 4rem !important
    }

    .pb-sm-9, .py-sm-9 {
        padding-bottom: 4rem !important
    }

    .pl-sm-9, .px-sm-9 {
        padding-left: 4rem !important
    }

    .m-sm-n1 {
        margin: -.25rem !important
    }

    .mt-sm-n1, .my-sm-n1 {
        margin-top: -.25rem !important
    }

    .mr-sm-n1, .mx-sm-n1 {
        margin-right: -.25rem !important
    }

    .mb-sm-n1, .my-sm-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-sm-n1, .mx-sm-n1 {
        margin-left: -.25rem !important
    }

    .m-sm-n2 {
        margin: -.5rem !important
    }

    .mt-sm-n2, .my-sm-n2 {
        margin-top: -.5rem !important
    }

    .mr-sm-n2, .mx-sm-n2 {
        margin-right: -.5rem !important
    }

    .mb-sm-n2, .my-sm-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-sm-n2, .mx-sm-n2 {
        margin-left: -.5rem !important
    }

    .m-sm-n3 {
        margin: -.75rem !important
    }

    .mt-sm-n3, .my-sm-n3 {
        margin-top: -.75rem !important
    }

    .mr-sm-n3, .mx-sm-n3 {
        margin-right: -.75rem !important
    }

    .mb-sm-n3, .my-sm-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-sm-n3, .mx-sm-n3 {
        margin-left: -.75rem !important
    }

    .m-sm-n4 {
        margin: -1.25rem !important
    }

    .mt-sm-n4, .my-sm-n4 {
        margin-top: -1.25rem !important
    }

    .mr-sm-n4, .mx-sm-n4 {
        margin-right: -1.25rem !important
    }

    .mb-sm-n4, .my-sm-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-sm-n4, .mx-sm-n4 {
        margin-left: -1.25rem !important
    }

    .m-sm-n5 {
        margin: -3rem !important
    }

    .mt-sm-n5, .my-sm-n5 {
        margin-top: -3rem !important
    }

    .mr-sm-n5, .mx-sm-n5 {
        margin-right: -3rem !important
    }

    .mb-sm-n5, .my-sm-n5 {
        margin-bottom: -3rem !important
    }

    .ml-sm-n5, .mx-sm-n5 {
        margin-left: -3rem !important
    }

    .m-sm-n5-alt {
        margin: -2rem !important
    }

    .mt-sm-n5-alt, .my-sm-n5-alt {
        margin-top: -2rem !important
    }

    .mr-sm-n5-alt, .mx-sm-n5-alt {
        margin-right: -2rem !important
    }

    .mb-sm-n5-alt, .my-sm-n5-alt {
        margin-bottom: -2rem !important
    }

    .ml-sm-n5-alt, .mx-sm-n5-alt {
        margin-left: -2rem !important
    }

    .m-sm-n6 {
        margin: -3.5rem !important
    }

    .mt-sm-n6, .my-sm-n6 {
        margin-top: -3.5rem !important
    }

    .mr-sm-n6, .mx-sm-n6 {
        margin-right: -3.5rem !important
    }

    .mb-sm-n6, .my-sm-n6 {
        margin-bottom: -3.5rem !important
    }

    .ml-sm-n6, .mx-sm-n6 {
        margin-left: -3.5rem !important
    }

    .m-sm-n9 {
        margin: -4rem !important
    }

    .mt-sm-n9, .my-sm-n9 {
        margin-top: -4rem !important
    }

    .mr-sm-n9, .mx-sm-n9 {
        margin-right: -4rem !important
    }

    .mb-sm-n9, .my-sm-n9 {
        margin-bottom: -4rem !important
    }

    .ml-sm-n9, .mx-sm-n9 {
        margin-left: -4rem !important
    }

    .m-sm-auto {
        margin: auto !important
    }

    .mt-sm-auto, .my-sm-auto {
        margin-top: auto !important
    }

    .mr-sm-auto, .mx-sm-auto {
        margin-right: auto !important
    }

    .mb-sm-auto, .my-sm-auto {
        margin-bottom: auto !important
    }

    .ml-sm-auto, .mx-sm-auto {
        margin-left: auto !important
    }
}

@media (min-width: 768px) {
    .m-md-0 {
        margin: 0 !important
    }

    .mt-md-0, .my-md-0 {
        margin-top: 0 !important
    }

    .mr-md-0, .mx-md-0 {
        margin-right: 0 !important
    }

    .mb-md-0, .my-md-0 {
        margin-bottom: 0 !important
    }

    .ml-md-0, .mx-md-0 {
        margin-left: 0 !important
    }

    .m-md-1 {
        margin: .25rem !important
    }

    .mt-md-1, .my-md-1 {
        margin-top: .25rem !important
    }

    .mr-md-1, .mx-md-1 {
        margin-right: .25rem !important
    }

    .mb-md-1, .my-md-1 {
        margin-bottom: .25rem !important
    }

    .ml-md-1, .mx-md-1 {
        margin-left: .25rem !important
    }

    .m-md-2 {
        margin: .5rem !important
    }

    .mt-md-2, .my-md-2 {
        margin-top: .5rem !important
    }

    .mr-md-2, .mx-md-2 {
        margin-right: .5rem !important
    }

    .mb-md-2, .my-md-2 {
        margin-bottom: .5rem !important
    }

    .ml-md-2, .mx-md-2 {
        margin-left: .5rem !important
    }

    .m-md-3 {
        margin: .75rem !important
    }

    .mt-md-3, .my-md-3 {
        margin-top: .75rem !important
    }

    .mr-md-3, .mx-md-3 {
        margin-right: .75rem !important
    }

    .mb-md-3, .my-md-3 {
        margin-bottom: .75rem !important
    }

    .ml-md-3, .mx-md-3 {
        margin-left: .75rem !important
    }

    .m-md-4 {
        margin: 1.25rem !important
    }

    .mt-md-4, .my-md-4 {
        margin-top: 1.25rem !important
    }

    .mr-md-4, .mx-md-4 {
        margin-right: 1.25rem !important
    }

    .mb-md-4, .my-md-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-md-4, .mx-md-4 {
        margin-left: 1.25rem !important
    }

    .m-md-5 {
        margin: 3rem !important
    }

    .mt-md-5, .my-md-5 {
        margin-top: 3rem !important
    }

    .mr-md-5, .mx-md-5 {
        margin-right: 3rem !important
    }

    .mb-md-5, .my-md-5 {
        margin-bottom: 3rem !important
    }

    .ml-md-5, .mx-md-5 {
        margin-left: 3rem !important
    }

    .m-md-5-alt {
        margin: 2rem !important
    }

    .mt-md-5-alt, .my-md-5-alt {
        margin-top: 2rem !important
    }

    .mr-md-5-alt, .mx-md-5-alt {
        margin-right: 2rem !important
    }

    .mb-md-5-alt, .my-md-5-alt {
        margin-bottom: 2rem !important
    }

    .ml-md-5-alt, .mx-md-5-alt {
        margin-left: 2rem !important
    }

    .m-md-6 {
        margin: 3.5rem !important
    }

    .mt-md-6, .my-md-6 {
        margin-top: 3.5rem !important
    }

    .mr-md-6, .mx-md-6 {
        margin-right: 3.5rem !important
    }

    .mb-md-6, .my-md-6 {
        margin-bottom: 3.5rem !important
    }

    .ml-md-6, .mx-md-6 {
        margin-left: 3.5rem !important
    }

    .m-md-9 {
        margin: 4rem !important
    }

    .mt-md-9, .my-md-9 {
        margin-top: 4rem !important
    }

    .mr-md-9, .mx-md-9 {
        margin-right: 4rem !important
    }

    .mb-md-9, .my-md-9 {
        margin-bottom: 4rem !important
    }

    .ml-md-9, .mx-md-9 {
        margin-left: 4rem !important
    }

    .p-md-0 {
        padding: 0 !important
    }

    .pt-md-0, .py-md-0 {
        padding-top: 0 !important
    }

    .pr-md-0, .px-md-0 {
        padding-right: 0 !important
    }

    .pb-md-0, .py-md-0 {
        padding-bottom: 0 !important
    }

    .pl-md-0, .px-md-0 {
        padding-left: 0 !important
    }

    .p-md-1 {
        padding: .25rem !important
    }

    .pt-md-1, .py-md-1 {
        padding-top: .25rem !important
    }

    .pr-md-1, .px-md-1 {
        padding-right: .25rem !important
    }

    .pb-md-1, .py-md-1 {
        padding-bottom: .25rem !important
    }

    .pl-md-1, .px-md-1 {
        padding-left: .25rem !important
    }

    .p-md-2 {
        padding: .5rem !important
    }

    .pt-md-2, .py-md-2 {
        padding-top: .5rem !important
    }

    .pr-md-2, .px-md-2 {
        padding-right: .5rem !important
    }

    .pb-md-2, .py-md-2 {
        padding-bottom: .5rem !important
    }

    .pl-md-2, .px-md-2 {
        padding-left: .5rem !important
    }

    .p-md-3 {
        padding: .75rem !important
    }

    .pt-md-3, .py-md-3 {
        padding-top: .75rem !important
    }

    .pr-md-3, .px-md-3 {
        padding-right: .75rem !important
    }

    .pb-md-3, .py-md-3 {
        padding-bottom: .75rem !important
    }

    .pl-md-3, .px-md-3 {
        padding-left: .75rem !important
    }

    .p-md-4 {
        padding: 1.25rem !important
    }

    .pt-md-4, .py-md-4 {
        padding-top: 1.25rem !important
    }

    .pr-md-4, .px-md-4 {
        padding-right: 1.25rem !important
    }

    .pb-md-4, .py-md-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-md-4, .px-md-4 {
        padding-left: 1.25rem !important
    }

    .p-md-5 {
        padding: 3rem !important
    }

    .pt-md-5, .py-md-5 {
        padding-top: 3rem !important
    }

    .pr-md-5, .px-md-5 {
        padding-right: 3rem !important
    }

    .pb-md-5, .py-md-5 {
        padding-bottom: 3rem !important
    }

    .pl-md-5, .px-md-5 {
        padding-left: 3rem !important
    }

    .p-md-5-alt {
        padding: 2rem !important
    }

    .pt-md-5-alt, .py-md-5-alt {
        padding-top: 2rem !important
    }

    .pr-md-5-alt, .px-md-5-alt {
        padding-right: 2rem !important
    }

    .pb-md-5-alt, .py-md-5-alt {
        padding-bottom: 2rem !important
    }

    .pl-md-5-alt, .px-md-5-alt {
        padding-left: 2rem !important
    }

    .p-md-6 {
        padding: 3.5rem !important
    }

    .pt-md-6, .py-md-6 {
        padding-top: 3.5rem !important
    }

    .pr-md-6, .px-md-6 {
        padding-right: 3.5rem !important
    }

    .pb-md-6, .py-md-6 {
        padding-bottom: 3.5rem !important
    }

    .pl-md-6, .px-md-6 {
        padding-left: 3.5rem !important
    }

    .p-md-9 {
        padding: 4rem !important
    }

    .pt-md-9, .py-md-9 {
        padding-top: 4rem !important
    }

    .pr-md-9, .px-md-9 {
        padding-right: 4rem !important
    }

    .pb-md-9, .py-md-9 {
        padding-bottom: 4rem !important
    }

    .pl-md-9, .px-md-9 {
        padding-left: 4rem !important
    }

    .m-md-n1 {
        margin: -.25rem !important
    }

    .mt-md-n1, .my-md-n1 {
        margin-top: -.25rem !important
    }

    .mr-md-n1, .mx-md-n1 {
        margin-right: -.25rem !important
    }

    .mb-md-n1, .my-md-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-md-n1, .mx-md-n1 {
        margin-left: -.25rem !important
    }

    .m-md-n2 {
        margin: -.5rem !important
    }

    .mt-md-n2, .my-md-n2 {
        margin-top: -.5rem !important
    }

    .mr-md-n2, .mx-md-n2 {
        margin-right: -.5rem !important
    }

    .mb-md-n2, .my-md-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-md-n2, .mx-md-n2 {
        margin-left: -.5rem !important
    }

    .m-md-n3 {
        margin: -.75rem !important
    }

    .mt-md-n3, .my-md-n3 {
        margin-top: -.75rem !important
    }

    .mr-md-n3, .mx-md-n3 {
        margin-right: -.75rem !important
    }

    .mb-md-n3, .my-md-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-md-n3, .mx-md-n3 {
        margin-left: -.75rem !important
    }

    .m-md-n4 {
        margin: -1.25rem !important
    }

    .mt-md-n4, .my-md-n4 {
        margin-top: -1.25rem !important
    }

    .mr-md-n4, .mx-md-n4 {
        margin-right: -1.25rem !important
    }

    .mb-md-n4, .my-md-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-md-n4, .mx-md-n4 {
        margin-left: -1.25rem !important
    }

    .m-md-n5 {
        margin: -3rem !important
    }

    .mt-md-n5, .my-md-n5 {
        margin-top: -3rem !important
    }

    .mr-md-n5, .mx-md-n5 {
        margin-right: -3rem !important
    }

    .mb-md-n5, .my-md-n5 {
        margin-bottom: -3rem !important
    }

    .ml-md-n5, .mx-md-n5 {
        margin-left: -3rem !important
    }

    .m-md-n5-alt {
        margin: -2rem !important
    }

    .mt-md-n5-alt, .my-md-n5-alt {
        margin-top: -2rem !important
    }

    .mr-md-n5-alt, .mx-md-n5-alt {
        margin-right: -2rem !important
    }

    .mb-md-n5-alt, .my-md-n5-alt {
        margin-bottom: -2rem !important
    }

    .ml-md-n5-alt, .mx-md-n5-alt {
        margin-left: -2rem !important
    }

    .m-md-n6 {
        margin: -3.5rem !important
    }

    .mt-md-n6, .my-md-n6 {
        margin-top: -3.5rem !important
    }

    .mr-md-n6, .mx-md-n6 {
        margin-right: -3.5rem !important
    }

    .mb-md-n6, .my-md-n6 {
        margin-bottom: -3.5rem !important
    }

    .ml-md-n6, .mx-md-n6 {
        margin-left: -3.5rem !important
    }

    .m-md-n9 {
        margin: -4rem !important
    }

    .mt-md-n9, .my-md-n9 {
        margin-top: -4rem !important
    }

    .mr-md-n9, .mx-md-n9 {
        margin-right: -4rem !important
    }

    .mb-md-n9, .my-md-n9 {
        margin-bottom: -4rem !important
    }

    .ml-md-n9, .mx-md-n9 {
        margin-left: -4rem !important
    }

    .m-md-auto {
        margin: auto !important
    }

    .mt-md-auto, .my-md-auto {
        margin-top: auto !important
    }

    .mr-md-auto, .mx-md-auto {
        margin-right: auto !important
    }

    .mb-md-auto, .my-md-auto {
        margin-bottom: auto !important
    }

    .ml-md-auto, .mx-md-auto {
        margin-left: auto !important
    }
}

@media (min-width: 992px) {
    .m-lg-0 {
        margin: 0 !important
    }

    .mt-lg-0, .my-lg-0 {
        margin-top: 0 !important
    }

    .mr-lg-0, .mx-lg-0 {
        margin-right: 0 !important
    }

    .mb-lg-0, .my-lg-0 {
        margin-bottom: 0 !important
    }

    .ml-lg-0, .mx-lg-0 {
        margin-left: 0 !important
    }

    .m-lg-1 {
        margin: .25rem !important
    }

    .mt-lg-1, .my-lg-1 {
        margin-top: .25rem !important
    }

    .mr-lg-1, .mx-lg-1 {
        margin-right: .25rem !important
    }

    .mb-lg-1, .my-lg-1 {
        margin-bottom: .25rem !important
    }

    .ml-lg-1, .mx-lg-1 {
        margin-left: .25rem !important
    }

    .m-lg-2 {
        margin: .5rem !important
    }

    .mt-lg-2, .my-lg-2 {
        margin-top: .5rem !important
    }

    .mr-lg-2, .mx-lg-2 {
        margin-right: .5rem !important
    }

    .mb-lg-2, .my-lg-2 {
        margin-bottom: .5rem !important
    }

    .ml-lg-2, .mx-lg-2 {
        margin-left: .5rem !important
    }

    .m-lg-3 {
        margin: .75rem !important
    }

    .mt-lg-3, .my-lg-3 {
        margin-top: .75rem !important
    }

    .mr-lg-3, .mx-lg-3 {
        margin-right: .75rem !important
    }

    .mb-lg-3, .my-lg-3 {
        margin-bottom: .75rem !important
    }

    .ml-lg-3, .mx-lg-3 {
        margin-left: .75rem !important
    }

    .m-lg-4 {
        margin: 1.25rem !important
    }

    .mt-lg-4, .my-lg-4 {
        margin-top: 1.25rem !important
    }

    .mr-lg-4, .mx-lg-4 {
        margin-right: 1.25rem !important
    }

    .mb-lg-4, .my-lg-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-lg-4, .mx-lg-4 {
        margin-left: 1.25rem !important
    }

    .m-lg-5 {
        margin: 3rem !important
    }

    .mt-lg-5, .my-lg-5 {
        margin-top: 3rem !important
    }

    .mr-lg-5, .mx-lg-5 {
        margin-right: 3rem !important
    }

    .mb-lg-5, .my-lg-5 {
        margin-bottom: 3rem !important
    }

    .ml-lg-5, .mx-lg-5 {
        margin-left: 3rem !important
    }

    .m-lg-5-alt {
        margin: 2rem !important
    }

    .mt-lg-5-alt, .my-lg-5-alt {
        margin-top: 2rem !important
    }

    .mr-lg-5-alt, .mx-lg-5-alt {
        margin-right: 2rem !important
    }

    .mb-lg-5-alt, .my-lg-5-alt {
        margin-bottom: 2rem !important
    }

    .ml-lg-5-alt, .mx-lg-5-alt {
        margin-left: 2rem !important
    }

    .m-lg-6 {
        margin: 3.5rem !important
    }

    .mt-lg-6, .my-lg-6 {
        margin-top: 3.5rem !important
    }

    .mr-lg-6, .mx-lg-6 {
        margin-right: 3.5rem !important
    }

    .mb-lg-6, .my-lg-6 {
        margin-bottom: 3.5rem !important
    }

    .ml-lg-6, .mx-lg-6 {
        margin-left: 3.5rem !important
    }

    .m-lg-9 {
        margin: 4rem !important
    }

    .mt-lg-9, .my-lg-9 {
        margin-top: 4rem !important
    }

    .mr-lg-9, .mx-lg-9 {
        margin-right: 4rem !important
    }

    .mb-lg-9, .my-lg-9 {
        margin-bottom: 4rem !important
    }

    .ml-lg-9, .mx-lg-9 {
        margin-left: 4rem !important
    }

    .p-lg-0 {
        padding: 0 !important
    }

    .pt-lg-0, .py-lg-0 {
        padding-top: 0 !important
    }

    .pr-lg-0, .px-lg-0 {
        padding-right: 0 !important
    }

    .pb-lg-0, .py-lg-0 {
        padding-bottom: 0 !important
    }

    .pl-lg-0, .px-lg-0 {
        padding-left: 0 !important
    }

    .p-lg-1 {
        padding: .25rem !important
    }

    .pt-lg-1, .py-lg-1 {
        padding-top: .25rem !important
    }

    .pr-lg-1, .px-lg-1 {
        padding-right: .25rem !important
    }

    .pb-lg-1, .py-lg-1 {
        padding-bottom: .25rem !important
    }

    .pl-lg-1, .px-lg-1 {
        padding-left: .25rem !important
    }

    .p-lg-2 {
        padding: .5rem !important
    }

    .pt-lg-2, .py-lg-2 {
        padding-top: .5rem !important
    }

    .pr-lg-2, .px-lg-2 {
        padding-right: .5rem !important
    }

    .pb-lg-2, .py-lg-2 {
        padding-bottom: .5rem !important
    }

    .pl-lg-2, .px-lg-2 {
        padding-left: .5rem !important
    }

    .p-lg-3 {
        padding: .75rem !important
    }

    .pt-lg-3, .py-lg-3 {
        padding-top: .75rem !important
    }

    .pr-lg-3, .px-lg-3 {
        padding-right: .75rem !important
    }

    .pb-lg-3, .py-lg-3 {
        padding-bottom: .75rem !important
    }

    .pl-lg-3, .px-lg-3 {
        padding-left: .75rem !important
    }

    .p-lg-4 {
        padding: 1.25rem !important
    }

    .pt-lg-4, .py-lg-4 {
        padding-top: 1.25rem !important
    }

    .pr-lg-4, .px-lg-4 {
        padding-right: 1.25rem !important
    }

    .pb-lg-4, .py-lg-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-lg-4, .px-lg-4 {
        padding-left: 1.25rem !important
    }

    .p-lg-5 {
        padding: 3rem !important
    }

    .pt-lg-5, .py-lg-5 {
        padding-top: 3rem !important
    }

    .pr-lg-5, .px-lg-5 {
        padding-right: 3rem !important
    }

    .pb-lg-5, .py-lg-5 {
        padding-bottom: 3rem !important
    }

    .pl-lg-5, .px-lg-5 {
        padding-left: 3rem !important
    }

    .p-lg-5-alt {
        padding: 2rem !important
    }

    .pt-lg-5-alt, .py-lg-5-alt {
        padding-top: 2rem !important
    }

    .pr-lg-5-alt, .px-lg-5-alt {
        padding-right: 2rem !important
    }

    .pb-lg-5-alt, .py-lg-5-alt {
        padding-bottom: 2rem !important
    }

    .pl-lg-5-alt, .px-lg-5-alt {
        padding-left: 2rem !important
    }

    .p-lg-6 {
        padding: 3.5rem !important
    }

    .pt-lg-6, .py-lg-6 {
        padding-top: 3.5rem !important
    }

    .pr-lg-6, .px-lg-6 {
        padding-right: 3.5rem !important
    }

    .pb-lg-6, .py-lg-6 {
        padding-bottom: 3.5rem !important
    }

    .pl-lg-6, .px-lg-6 {
        padding-left: 3.5rem !important
    }

    .p-lg-9 {
        padding: 4rem !important
    }

    .pt-lg-9, .py-lg-9 {
        padding-top: 4rem !important
    }

    .pr-lg-9, .px-lg-9 {
        padding-right: 4rem !important
    }

    .pb-lg-9, .py-lg-9 {
        padding-bottom: 4rem !important
    }

    .pl-lg-9, .px-lg-9 {
        padding-left: 4rem !important
    }

    .m-lg-n1 {
        margin: -.25rem !important
    }

    .mt-lg-n1, .my-lg-n1 {
        margin-top: -.25rem !important
    }

    .mr-lg-n1, .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    .mb-lg-n1, .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-lg-n1, .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    .m-lg-n2 {
        margin: -.5rem !important
    }

    .mt-lg-n2, .my-lg-n2 {
        margin-top: -.5rem !important
    }

    .mr-lg-n2, .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    .mb-lg-n2, .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-lg-n2, .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    .m-lg-n3 {
        margin: -.75rem !important
    }

    .mt-lg-n3, .my-lg-n3 {
        margin-top: -.75rem !important
    }

    .mr-lg-n3, .mx-lg-n3 {
        margin-right: -.75rem !important
    }

    .mb-lg-n3, .my-lg-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-lg-n3, .mx-lg-n3 {
        margin-left: -.75rem !important
    }

    .m-lg-n4 {
        margin: -1.25rem !important
    }

    .mt-lg-n4, .my-lg-n4 {
        margin-top: -1.25rem !important
    }

    .mr-lg-n4, .mx-lg-n4 {
        margin-right: -1.25rem !important
    }

    .mb-lg-n4, .my-lg-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-lg-n4, .mx-lg-n4 {
        margin-left: -1.25rem !important
    }

    .m-lg-n5 {
        margin: -3rem !important
    }

    .mt-lg-n5, .my-lg-n5 {
        margin-top: -3rem !important
    }

    .mr-lg-n5, .mx-lg-n5 {
        margin-right: -3rem !important
    }

    .mb-lg-n5, .my-lg-n5 {
        margin-bottom: -3rem !important
    }

    .ml-lg-n5, .mx-lg-n5 {
        margin-left: -3rem !important
    }

    .m-lg-n5-alt {
        margin: -2rem !important
    }

    .mt-lg-n5-alt, .my-lg-n5-alt {
        margin-top: -2rem !important
    }

    .mr-lg-n5-alt, .mx-lg-n5-alt {
        margin-right: -2rem !important
    }

    .mb-lg-n5-alt, .my-lg-n5-alt {
        margin-bottom: -2rem !important
    }

    .ml-lg-n5-alt, .mx-lg-n5-alt {
        margin-left: -2rem !important
    }

    .m-lg-n6 {
        margin: -3.5rem !important
    }

    .mt-lg-n6, .my-lg-n6 {
        margin-top: -3.5rem !important
    }

    .mr-lg-n6, .mx-lg-n6 {
        margin-right: -3.5rem !important
    }

    .mb-lg-n6, .my-lg-n6 {
        margin-bottom: -3.5rem !important
    }

    .ml-lg-n6, .mx-lg-n6 {
        margin-left: -3.5rem !important
    }

    .m-lg-n9 {
        margin: -4rem !important
    }

    .mt-lg-n9, .my-lg-n9 {
        margin-top: -4rem !important
    }

    .mr-lg-n9, .mx-lg-n9 {
        margin-right: -4rem !important
    }

    .mb-lg-n9, .my-lg-n9 {
        margin-bottom: -4rem !important
    }

    .ml-lg-n9, .mx-lg-n9 {
        margin-left: -4rem !important
    }

    .m-lg-auto {
        margin: auto !important
    }

    .mt-lg-auto, .my-lg-auto {
        margin-top: auto !important
    }

    .mr-lg-auto, .mx-lg-auto {
        margin-right: auto !important
    }

    .mb-lg-auto, .my-lg-auto {
        margin-bottom: auto !important
    }

    .ml-lg-auto, .mx-lg-auto {
        margin-left: auto !important
    }
}

@media (min-width: 1200px) {
    .m-xl-0 {
        margin: 0 !important
    }

    .mt-xl-0, .my-xl-0 {
        margin-top: 0 !important
    }

    .mr-xl-0, .mx-xl-0 {
        margin-right: 0 !important
    }

    .mb-xl-0, .my-xl-0 {
        margin-bottom: 0 !important
    }

    .ml-xl-0, .mx-xl-0 {
        margin-left: 0 !important
    }

    .m-xl-1 {
        margin: .25rem !important
    }

    .mt-xl-1, .my-xl-1 {
        margin-top: .25rem !important
    }

    .mr-xl-1, .mx-xl-1 {
        margin-right: .25rem !important
    }

    .mb-xl-1, .my-xl-1 {
        margin-bottom: .25rem !important
    }

    .ml-xl-1, .mx-xl-1 {
        margin-left: .25rem !important
    }

    .m-xl-2 {
        margin: .5rem !important
    }

    .mt-xl-2, .my-xl-2 {
        margin-top: .5rem !important
    }

    .mr-xl-2, .mx-xl-2 {
        margin-right: .5rem !important
    }

    .mb-xl-2, .my-xl-2 {
        margin-bottom: .5rem !important
    }

    .ml-xl-2, .mx-xl-2 {
        margin-left: .5rem !important
    }

    .m-xl-3 {
        margin: .75rem !important
    }

    .mt-xl-3, .my-xl-3 {
        margin-top: .75rem !important
    }

    .mr-xl-3, .mx-xl-3 {
        margin-right: .75rem !important
    }

    .mb-xl-3, .my-xl-3 {
        margin-bottom: .75rem !important
    }

    .ml-xl-3, .mx-xl-3 {
        margin-left: .75rem !important
    }

    .m-xl-4 {
        margin: 1.25rem !important
    }

    .mt-xl-4, .my-xl-4 {
        margin-top: 1.25rem !important
    }

    .mr-xl-4, .mx-xl-4 {
        margin-right: 1.25rem !important
    }

    .mb-xl-4, .my-xl-4 {
        margin-bottom: 1.25rem !important
    }

    .ml-xl-4, .mx-xl-4 {
        margin-left: 1.25rem !important
    }

    .m-xl-5 {
        margin: 3rem !important
    }

    .mt-xl-5, .my-xl-5 {
        margin-top: 3rem !important
    }

    .mr-xl-5, .mx-xl-5 {
        margin-right: 3rem !important
    }

    .mb-xl-5, .my-xl-5 {
        margin-bottom: 3rem !important
    }

    .ml-xl-5, .mx-xl-5 {
        margin-left: 3rem !important
    }

    .m-xl-5-alt {
        margin: 2rem !important
    }

    .mt-xl-5-alt, .my-xl-5-alt {
        margin-top: 2rem !important
    }

    .mr-xl-5-alt, .mx-xl-5-alt {
        margin-right: 2rem !important
    }

    .mb-xl-5-alt, .my-xl-5-alt {
        margin-bottom: 2rem !important
    }

    .ml-xl-5-alt, .mx-xl-5-alt {
        margin-left: 2rem !important
    }

    .m-xl-6 {
        margin: 3.5rem !important
    }

    .mt-xl-6, .my-xl-6 {
        margin-top: 3.5rem !important
    }

    .mr-xl-6, .mx-xl-6 {
        margin-right: 3.5rem !important
    }

    .mb-xl-6, .my-xl-6 {
        margin-bottom: 3.5rem !important
    }

    .ml-xl-6, .mx-xl-6 {
        margin-left: 3.5rem !important
    }

    .m-xl-9 {
        margin: 4rem !important
    }

    .mt-xl-9, .my-xl-9 {
        margin-top: 4rem !important
    }

    .mr-xl-9, .mx-xl-9 {
        margin-right: 4rem !important
    }

    .mb-xl-9, .my-xl-9 {
        margin-bottom: 4rem !important
    }

    .ml-xl-9, .mx-xl-9 {
        margin-left: 4rem !important
    }

    .p-xl-0 {
        padding: 0 !important
    }

    .pt-xl-0, .py-xl-0 {
        padding-top: 0 !important
    }

    .pr-xl-0, .px-xl-0 {
        padding-right: 0 !important
    }

    .pb-xl-0, .py-xl-0 {
        padding-bottom: 0 !important
    }

    .pl-xl-0, .px-xl-0 {
        padding-left: 0 !important
    }

    .p-xl-1 {
        padding: .25rem !important
    }

    .pt-xl-1, .py-xl-1 {
        padding-top: .25rem !important
    }

    .pr-xl-1, .px-xl-1 {
        padding-right: .25rem !important
    }

    .pb-xl-1, .py-xl-1 {
        padding-bottom: .25rem !important
    }

    .pl-xl-1, .px-xl-1 {
        padding-left: .25rem !important
    }

    .p-xl-2 {
        padding: .5rem !important
    }

    .pt-xl-2, .py-xl-2 {
        padding-top: .5rem !important
    }

    .pr-xl-2, .px-xl-2 {
        padding-right: .5rem !important
    }

    .pb-xl-2, .py-xl-2 {
        padding-bottom: .5rem !important
    }

    .pl-xl-2, .px-xl-2 {
        padding-left: .5rem !important
    }

    .p-xl-3 {
        padding: .75rem !important
    }

    .pt-xl-3, .py-xl-3 {
        padding-top: .75rem !important
    }

    .pr-xl-3, .px-xl-3 {
        padding-right: .75rem !important
    }

    .pb-xl-3, .py-xl-3 {
        padding-bottom: .75rem !important
    }

    .pl-xl-3, .px-xl-3 {
        padding-left: .75rem !important
    }

    .p-xl-4 {
        padding: 1.25rem !important
    }

    .pt-xl-4, .py-xl-4 {
        padding-top: 1.25rem !important
    }

    .pr-xl-4, .px-xl-4 {
        padding-right: 1.25rem !important
    }

    .pb-xl-4, .py-xl-4 {
        padding-bottom: 1.25rem !important
    }

    .pl-xl-4, .px-xl-4 {
        padding-left: 1.25rem !important
    }

    .p-xl-5 {
        padding: 3rem !important
    }

    .pt-xl-5, .py-xl-5 {
        padding-top: 3rem !important
    }

    .pr-xl-5, .px-xl-5 {
        padding-right: 3rem !important
    }

    .pb-xl-5, .py-xl-5 {
        padding-bottom: 3rem !important
    }

    .pl-xl-5, .px-xl-5 {
        padding-left: 3rem !important
    }

    .p-xl-5-alt {
        padding: 2rem !important
    }

    .pt-xl-5-alt, .py-xl-5-alt {
        padding-top: 2rem !important
    }

    .pr-xl-5-alt, .px-xl-5-alt {
        padding-right: 2rem !important
    }

    .pb-xl-5-alt, .py-xl-5-alt {
        padding-bottom: 2rem !important
    }

    .pl-xl-5-alt, .px-xl-5-alt {
        padding-left: 2rem !important
    }

    .p-xl-6 {
        padding: 3.5rem !important
    }

    .pt-xl-6, .py-xl-6 {
        padding-top: 3.5rem !important
    }

    .pr-xl-6, .px-xl-6 {
        padding-right: 3.5rem !important
    }

    .pb-xl-6, .py-xl-6 {
        padding-bottom: 3.5rem !important
    }

    .pl-xl-6, .px-xl-6 {
        padding-left: 3.5rem !important
    }

    .p-xl-9 {
        padding: 4rem !important
    }

    .pt-xl-9, .py-xl-9 {
        padding-top: 4rem !important
    }

    .pr-xl-9, .px-xl-9 {
        padding-right: 4rem !important
    }

    .pb-xl-9, .py-xl-9 {
        padding-bottom: 4rem !important
    }

    .pl-xl-9, .px-xl-9 {
        padding-left: 4rem !important
    }

    .m-xl-n1 {
        margin: -.25rem !important
    }

    .mt-xl-n1, .my-xl-n1 {
        margin-top: -.25rem !important
    }

    .mr-xl-n1, .mx-xl-n1 {
        margin-right: -.25rem !important
    }

    .mb-xl-n1, .my-xl-n1 {
        margin-bottom: -.25rem !important
    }

    .ml-xl-n1, .mx-xl-n1 {
        margin-left: -.25rem !important
    }

    .m-xl-n2 {
        margin: -.5rem !important
    }

    .mt-xl-n2, .my-xl-n2 {
        margin-top: -.5rem !important
    }

    .mr-xl-n2, .mx-xl-n2 {
        margin-right: -.5rem !important
    }

    .mb-xl-n2, .my-xl-n2 {
        margin-bottom: -.5rem !important
    }

    .ml-xl-n2, .mx-xl-n2 {
        margin-left: -.5rem !important
    }

    .m-xl-n3 {
        margin: -.75rem !important
    }

    .mt-xl-n3, .my-xl-n3 {
        margin-top: -.75rem !important
    }

    .mr-xl-n3, .mx-xl-n3 {
        margin-right: -.75rem !important
    }

    .mb-xl-n3, .my-xl-n3 {
        margin-bottom: -.75rem !important
    }

    .ml-xl-n3, .mx-xl-n3 {
        margin-left: -.75rem !important
    }

    .m-xl-n4 {
        margin: -1.25rem !important
    }

    .mt-xl-n4, .my-xl-n4 {
        margin-top: -1.25rem !important
    }

    .mr-xl-n4, .mx-xl-n4 {
        margin-right: -1.25rem !important
    }

    .mb-xl-n4, .my-xl-n4 {
        margin-bottom: -1.25rem !important
    }

    .ml-xl-n4, .mx-xl-n4 {
        margin-left: -1.25rem !important
    }

    .m-xl-n5 {
        margin: -3rem !important
    }

    .mt-xl-n5, .my-xl-n5 {
        margin-top: -3rem !important
    }

    .mr-xl-n5, .mx-xl-n5 {
        margin-right: -3rem !important
    }

    .mb-xl-n5, .my-xl-n5 {
        margin-bottom: -3rem !important
    }

    .ml-xl-n5, .mx-xl-n5 {
        margin-left: -3rem !important
    }

    .m-xl-n5-alt {
        margin: -2rem !important
    }

    .mt-xl-n5-alt, .my-xl-n5-alt {
        margin-top: -2rem !important
    }

    .mr-xl-n5-alt, .mx-xl-n5-alt {
        margin-right: -2rem !important
    }

    .mb-xl-n5-alt, .my-xl-n5-alt {
        margin-bottom: -2rem !important
    }

    .ml-xl-n5-alt, .mx-xl-n5-alt {
        margin-left: -2rem !important
    }

    .m-xl-n6 {
        margin: -3.5rem !important
    }

    .mt-xl-n6, .my-xl-n6 {
        margin-top: -3.5rem !important
    }

    .mr-xl-n6, .mx-xl-n6 {
        margin-right: -3.5rem !important
    }

    .mb-xl-n6, .my-xl-n6 {
        margin-bottom: -3.5rem !important
    }

    .ml-xl-n6, .mx-xl-n6 {
        margin-left: -3.5rem !important
    }

    .m-xl-n9 {
        margin: -4rem !important
    }

    .mt-xl-n9, .my-xl-n9 {
        margin-top: -4rem !important
    }

    .mr-xl-n9, .mx-xl-n9 {
        margin-right: -4rem !important
    }

    .mb-xl-n9, .my-xl-n9 {
        margin-bottom: -4rem !important
    }

    .ml-xl-n9, .mx-xl-n9 {
        margin-left: -4rem !important
    }

    .m-xl-auto {
        margin: auto !important
    }

    .mt-xl-auto, .my-xl-auto {
        margin-top: auto !important
    }

    .mr-xl-auto, .mx-xl-auto {
        margin-right: auto !important
    }

    .mb-xl-auto, .my-xl-auto {
        margin-bottom: auto !important
    }

    .ml-xl-auto, .mx-xl-auto {
        margin-left: auto !important
    }
}

.text-monospace {
    font-family: SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace !important
}

.text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text-left {
    text-align: left !important
}

.text-right {
    text-align: right !important
}

.text-center {
    text-align: center !important
}

@media (min-width: 576px) {
    .text-sm-left {
        text-align: left !important
    }

    .text-sm-right {
        text-align: right !important
    }

    .text-sm-center {
        text-align: center !important
    }
}

@media (min-width: 768px) {
    .text-md-left {
        text-align: left !important
    }

    .text-md-right {
        text-align: right !important
    }

    .text-md-center {
        text-align: center !important
    }
}

@media (min-width: 992px) {
    .text-lg-left {
        text-align: left !important
    }

    .text-lg-right {
        text-align: right !important
    }

    .text-lg-center {
        text-align: center !important
    }
}

@media (min-width: 1200px) {
    .text-xl-left {
        text-align: left !important
    }

    .text-xl-right {
        text-align: right !important
    }

    .text-xl-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 700 !important
}

.font-weight-bolder {
    font-weight: bolder !important
}

.font-italic {
    font-style: italic !important
}

.text-primary {
    color: #3498db !important
}

a.text-primary:focus, a.text-primary:hover {
    color: #1d6fa5 !important
}

.text-secondary {
    color: #77838f !important
}

a.text-secondary:focus, a.text-secondary:hover {
    color: #545d66 !important
}

.text-success {
    color: #00c9a7 !important
}

a.text-success:focus, a.text-success:hover {
    color: #007d67 !important
}

.text-info {
    color: #3498db !important
}

a.text-info:focus, a.text-info:hover {
    color: #1d6fa5 !important
}

.text-warning {
    color: #db9a04 !important
}

a.text-warning:focus, a.text-warning:hover {
    color: #906503 !important
}

.text-danger {
    color: #de4437 !important
}

a.text-danger:focus, a.text-danger:hover {
    color: #ac281c !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:focus, a.text-light:hover {
    color: #cbd3da !important
}

.text-dark {
    color: #1e2022 !important
}

a.text-dark:focus, a.text-dark:hover {
    color: #000 !important
}

.text-white {
    color: #fff !important
}

a.text-white:focus, a.text-white:hover {
    color: #d9d9d9 !important
}

.text-indigo {
    color: #2d1582 !important
}

a.text-indigo:focus, a.text-indigo:hover {
    color: #160a40 !important
}

.text-body {
    color: #1e2022 !important
}

.text-muted {
    color: #8c98a4 !important
}

.text-black-50 {
    color: rgba(0, 0, 0, .5) !important
}

.text-white-50 {
    color: hsla(0, 0%, 100%, .5) !important
}

.text-hide {
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-break: break-word !important;
    overflow-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {
    *, :after, :before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    pre {
        white-space: pre-wrap !important
    }

    blockquote, pre {
        border: 1px solid #97a4af;
        page-break-inside: avoid
    }

    thead {
        display: table-header-group
    }

    img, tr {
        page-break-inside: avoid
    }

    h2, h3, p {
        orphans: 3;
        widows: 3
    }

    h2, h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    .container, body {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    .badge {
        border: 1px solid #000
    }

    .table {
        border-collapse: collapse !important
    }

    .table td, .table th {
        background-color: #fff !important
    }

    .table-bordered td, .table-bordered th {
        border: 1px solid #e7eaf3 !important
    }

    .table-dark {
        color: inherit
    }

    .table-dark tbody + tbody, .table-dark td, .table-dark th, .table-dark thead th {
        border-color: #e7eaf3
    }

    .table .thead-dark th {
        color: inherit;
        border-color: #e7eaf3
    }
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.w-55 {
    width: 55% !important
}

.w-60 {
    width: 60% !important
}

.w-80 {
    width: 80% !important
}

.w-90 {
    width: 90% !important
}

@media (min-width: 576px) {
    .w-sm-25 {
        width: 25% !important
    }

    .w-sm-50 {
        width: 50% !important
    }

    .w-sm-75 {
        width: 75% !important
    }

    .w-sm-100 {
        width: 100% !important
    }

    .w-sm-auto {
        width: auto !important
    }

    .w-sm-55 {
        width: 55% !important
    }

    .w-sm-60 {
        width: 60% !important
    }

    .w-sm-80 {
        width: 80% !important
    }

    .w-sm-90 {
        width: 90% !important
    }
}

@media (min-width: 768px) {
    .w-md-25 {
        width: 25% !important
    }

    .w-md-50 {
        width: 50% !important
    }

    .w-md-75 {
        width: 75% !important
    }

    .w-md-100 {
        width: 100% !important
    }

    .w-md-auto {
        width: auto !important
    }

    .w-md-55 {
        width: 55% !important
    }

    .w-md-60 {
        width: 60% !important
    }

    .w-md-80 {
        width: 80% !important
    }

    .w-md-90 {
        width: 90% !important
    }
}

@media (min-width: 992px) {
    .w-lg-25 {
        width: 25% !important
    }

    .w-lg-50 {
        width: 50% !important
    }

    .w-lg-75 {
        width: 75% !important
    }

    .w-lg-100 {
        width: 100% !important
    }

    .w-lg-auto {
        width: auto !important
    }

    .w-lg-55 {
        width: 55% !important
    }

    .w-lg-60 {
        width: 60% !important
    }

    .w-lg-80 {
        width: 80% !important
    }

    .w-lg-90 {
        width: 90% !important
    }
}

@media (min-width: 1200px) {
    .w-xl-25 {
        width: 25% !important
    }

    .w-xl-50 {
        width: 50% !important
    }

    .w-xl-75 {
        width: 75% !important
    }

    .w-xl-100 {
        width: 100% !important
    }

    .w-xl-auto {
        width: auto !important
    }

    .w-xl-55 {
        width: 55% !important
    }

    .w-xl-60 {
        width: 60% !important
    }

    .w-xl-80 {
        width: 80% !important
    }

    .w-xl-90 {
        width: 90% !important
    }
}

.space-1, .space-top-1 {
    padding-top: 2rem !important
}

.space-1, .space-bottom-1 {
    padding-bottom: 2rem !important
}

.space-2, .space-top-2 {
    padding-top: 3.25rem !important
}

.space-2, .space-bottom-2 {
    padding-bottom: 3.25rem !important
}

.space-3, .space-top-3 {
    padding-top: 4.5rem !important
}

.space-3, .space-bottom-3 {
    padding-bottom: 4.5rem !important
}

@media (min-width: 576px) {
    .space-sm-1, .space-top-sm-1 {
        padding-top: 2rem !important
    }

    .space-bottom-sm-1, .space-sm-1 {
        padding-bottom: 2rem !important
    }

    .space-sm-2, .space-top-sm-2 {
        padding-top: 3.25rem !important
    }

    .space-bottom-sm-2, .space-sm-2 {
        padding-bottom: 3.25rem !important
    }

    .space-sm-3, .space-top-sm-3 {
        padding-top: 4.5rem !important
    }

    .space-bottom-sm-3, .space-sm-3 {
        padding-bottom: 4.5rem !important
    }
}

@media (min-width: 768px) {
    .space-md-1, .space-top-md-1 {
        padding-top: 2rem !important
    }

    .space-bottom-md-1, .space-md-1 {
        padding-bottom: 2rem !important
    }

    .space-md-2, .space-top-md-2 {
        padding-top: 3.25rem !important
    }

    .space-bottom-md-2, .space-md-2 {
        padding-bottom: 3.25rem !important
    }

    .space-md-3, .space-top-md-3 {
        padding-top: 4.5rem !important
    }

    .space-bottom-md-3, .space-md-3 {
        padding-bottom: 4.5rem !important
    }
}

@media (min-width: 992px) {
    .space-lg-1, .space-top-lg-1 {
        padding-top: 2rem !important
    }

    .space-bottom-lg-1, .space-lg-1 {
        padding-bottom: 2rem !important
    }

    .space-lg-2, .space-top-lg-2 {
        padding-top: 3.25rem !important
    }

    .space-bottom-lg-2, .space-lg-2 {
        padding-bottom: 3.25rem !important
    }

    .space-lg-3, .space-top-lg-3 {
        padding-top: 4.5rem !important
    }

    .space-bottom-lg-3, .space-lg-3 {
        padding-bottom: 4.5rem !important
    }
}

@media (min-width: 1200px) {
    .space-top-xl-1, .space-xl-1 {
        padding-top: 2rem !important
    }

    .space-bottom-xl-1, .space-xl-1 {
        padding-bottom: 2rem !important
    }

    .space-top-xl-2, .space-xl-2 {
        padding-top: 3.25rem !important
    }

    .space-bottom-xl-2, .space-xl-2 {
        padding-bottom: 3.25rem !important
    }

    .space-top-xl-3, .space-xl-3 {
        padding-top: 4.5rem !important
    }

    .space-bottom-xl-3, .space-xl-3 {
        padding-bottom: 4.5rem !important
    }
}

main {
    position: relative
}

p {
    color: #6c757e;
    line-height: 1.7
}

.h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a, h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a {
    color: #4a4f55
}

.h1 > a:hover, .h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover, h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover {
    color: #1d6fa5
}

.h1.text-white > a, .h2.text-white > a, .h3.text-white > a, .h4.text-white > a, .h5.text-white > a, .h6.text-white > a, h1.text-white > a, h2.text-white > a, h3.text-white > a, h4.text-white > a, h5.text-white > a, h6.text-white > a {
    color: #fff
}

figure {
    margin-bottom: 0
}

strong {
    font-weight: 600
}

svg {
    margin-bottom: -.4375rem;
    vertical-align: baseline
}

table th {
    font-weight: 600
}

.fa, .fas {
    font-weight: 900
}

::-moz-selection {
    color: #fff;
    background-color: #3498db
}

::selection {
    color: #fff;
    background-color: #3498db
}

.bg-primary ::-moz-selection {
    color: #3498db;
    background-color: #fff
}

.bg-primary ::selection {
    color: #3498db;
    background-color: #fff
}

:focus, a:focus, button:focus {
    outline: 0
}

.btn.focus, .btn:focus, .btn:not(:disabled):not(.disabled).active:focus, .btn:not(:disabled):not(.disabled):active:focus, .btn:not([disabled]):not(.disabled).active, .btn:not([disabled]):not(.disabled):active, .custom-control-input:active ~ .custom-control-label:before, .custom-control-input:checked ~ .custom-control-label:before, .custom-control-input:focus ~ .custom-control-label:before, .custom-file-input:focus ~ .custom-file-label, .custom-select:focus, .form-control:focus, .page-link:focus, .show > .btn.dropdown-toggle:focus {
    box-shadow: 0 0 0 0 transparent
}

.bootstrap-select .dropdown-toggle:focus {
    outline: 0 !important
}

@media print {
    .btn, footer, header {
        display: none
    }
}

.list-group {
    list-style: none
}

.list-group .list-group {
    margin-left: 1rem
}

.list-group .list-group-item {
    font-size: .71094rem
}

.list-group .list-group-icon {
    min-width: .8125rem;
    max-width: .8125rem;
    color: #6c757e;
    text-align: center;
    margin-right: .5rem
}

.list-group .active > .list-group-item {
    color: #3498db
}

.list-group .active > .list-group-text {
    color: #fff
}

.list-group .disabled .u-icon {
    color: #6c757e;
    background-color: hsla(210, 8%, 46%, .1)
}

.list-group-rounded-0 .list-group-item:first-child {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.list-group-rounded-0 .list-group-item:last-child {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.list-group-transparent .list-group-item {
    border-color: transparent;
    background-color: transparent
}

.list-group-white .list-group-item, .list-group-white .list-group-item-action {
    color: #fff
}

.list-group-white .list-group-item-action[href], .list-group-white .list-group-item[href] {
    color: hsla(0, 0%, 100%, .7)
}

.list-group-white .list-group-item-action[href]:hover, .list-group-white .list-group-item[href]:hover {
    color: #fff
}

.list-group-white.list-group-striped .list-group-item:nth-child(2n) {
    background-color: hsla(0, 0%, 100%, .1)
}

.list-group-white .active > .list-group-item {
    color: #fff
}

.list-group-flush .list-group-item {
    padding-left: 0;
    padding-right: 0
}

.list-group-flush .list-group-item.active {
    color: #3498db;
    background-color: transparent;
    border-color: transparent
}

.list-group-borderless .list-group-item {
    border: none
}

.list-group-striped .list-group-item:nth-child(2n) {
    background-color: #f8fafd
}

.u-header {
    position: relative;
    right: 0;
    left: 0;
    width: 100%;
    z-index: 1001
}

.u-header__section {
    position: relative;
    z-index: 1;
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, .1)
}

.u-header__section-divider {
    border-bottom: 1px solid hsla(0, 0%, 100%, .2)
}

.u-header__shadow-on-show-hide {
    box-shadow: none
}

.js-header-fix-moment .u-header__shadow-on-show-hide {
    box-shadow: 0 1px 10px rgba(151, 164, 175, .1)
}

.u-header__navbar {
    padding: .25rem 0
}

@media (max-width: 991.98px) {
    .u-header .u-header__navbar.u-header__navbar--top-space {
        padding-top: .25rem
    }
}

.u-header__navbar-brand, .u-header__navbar-brand > img {
    padding-top: 0;
    padding-bottom: 0;
    width: 9.375rem
}

.u-header__navbar-brand-center {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.u-header__navbar-brand-vertical {
    display: block;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2.5rem
}

.u-header__navbar-brand-vertical .u-header__navbar-brand-text {
    display: block;
    margin-left: 0
}

.u-header__navbar-brand-text {
    font-size: 2.25rem;
    font-weight: 700;
    margin-left: .5rem
}

.u-header__navbar-brand-text, .u-header__navbar-brand-text:focus, .u-header__navbar-brand-text:hover {
    color: #3498db
}

.u-header__navbar-brand-text-white .u-header__navbar-brand-text, .u-header__navbar-brand-text-white .u-header__navbar-brand-text:focus, .u-header__navbar-brand-text-white .u-header__navbar-brand-text:hover {
    color: #fff
}

.u-header__navbar-collapse {
    padding-top: 0;
    padding-bottom: 0
}

.u-header__navbar-link {
    color: #6c757e;
    font-size: .71094rem;
    font-weight: 300;
    padding-left: .5rem;
    padding-right: .5rem
}

.u-header .u-header__nav-item:first-child .u-header__nav-link {
    padding-left: 0
}

.u-header .active > .u-header__nav-link, .u-header__nav-item:focus .u-header__nav-link, .u-header__nav-item:hover .u-header__nav-link {
    color: #3498db
}

.u-header__nav-link {
    color: #6c757e
}

.u-header__nav-link-toggle {
    position: relative
}

.u-header__nav-link-toggle:after {
    display: inline-block;
    font-family: Font Awesome\ 5 Pro;
    font-size: 65%;
    font-weight: 900;
    content: "\f107";
    margin-left: .5rem;
    transition: .3s ease
}

.u-header__sub-menu {
    width: 100%
}

.u-header__sub-menu, .u-header__sub-menu-nav-group {
    padding-left: 0;
    list-style: none
}

.u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    font-size: .75156rem;
    color: #6c757e
}

.u-header__sub-menu-nav-link-toggle {
    position: relative
}

.u-header__sub-menu-nav-link-toggle:after {
    display: inline-block;
    font-family: Font Awesome\ 5 Pro;
    font-size: 80%;
    font-weight: 900;
    content: "\f105";
    margin-left: auto;
    margin-right: .04rem;
    transition: .3s ease
}

.u-header__sub-menu .active > .u-header__sub-menu-nav-link {
    color: #3498db
}

.u-header__sub-menu-title {
    display: block;
    color: #1e2022;
    font-size: .8125rem;
    font-weight: 600;
    margin-bottom: .5rem
}

.u-header__promo {
    background-color: #f8fafd
}

.u-header__promo-inner {
    padding: 2rem
}

.u-header__promo-item {
    margin-left: 1.5rem;
    margin-right: 1.5rem
}

.u-header__promo-item ~ .u-header__promo-item {
    border-top: 1px solid #e7eaf3
}

.u-header__promo-link {
    display: block;
    padding-top: .75rem;
    padding-bottom: .75rem;
    margin-top: .75rem;
    margin-bottom: .75rem
}

.u-header__promo-link:hover .u-header__promo-title {
    color: #3498db
}

.u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-clip: border-box
}

.u-header__promo-card-deck {
    display: block
}

.u-header__promo-card-deck:not(:last-child) .u-header__promo-item {
    border-bottom: 1px solid #e7eaf3
}

.u-header__promo-card-deck .u-header__promo-item {
    border-top: none
}

.u-header__promo-card-body {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto
}

.u-header__promo-icon {
    width: 100%;
    max-width: 2.75rem;
    margin-right: 1rem
}

.u-header__promo-title {
    display: block;
    color: #1e2022;
    font-size: .8125rem;
    font-weight: 500
}

.u-header__promo-text {
    display: block;
    color: #77838f
}

.u-header__promo-footer {
    background-color: #f8fafd;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.u-header__promo-footer-item {
    padding-left: 1.5rem;
    padding-right: 1.5rem
}

.u-header__promo-footer-ver-divider {
    position: relative
}

.u-header__promo-footer-ver-divider:before {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    border-right: 1px solid #bdc5d1;
    content: ""
}

.u-header__banner {
    position: relative;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    border-bottom-left-radius: .25rem
}

.u-header__banner:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(30, 32, 34, .5);
    border-bottom-left-radius: .25rem;
    content: ""
}

.u-header__banner-content {
    z-index: 1;
    width: 100%;
    text-align: center;
    padding: 2rem;
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

.u-header__banner-title {
    display: block;
    color: #fff;
    font-size: 1.26953rem;
    font-weight: 500;
    margin-bottom: .5rem
}

.u-header__banner-text {
    display: block;
    color: #fff;
    margin-bottom: 0
}

.u-header__product-banner {
    background-color: #f8fafd;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    padding-right: 1.5rem
}

.u-header__product-banner-content {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem
}

.u-header__product-banner-title {
    display: block;
    color: #3498db;
    font-size: 1.26953rem;
    font-weight: 500;
    margin-bottom: .5rem
}

.u-header__product-banner-text {
    color: #1e2022;
    margin-bottom: 0
}

.u-header.js-header-fix-moment .u-header__hide-content {
    display: none
}

.u-header.u-header--moved-up.u-header--hide-topbar {
    transform: translate3d(0, -33px, 0)
}

.u-header--invisible {
    display: none
}

.u-header--moved-up {
    transform: translate3d(0, -100%, 0)
}

.u-header--faded {
    opacity: 0;
    visibility: hidden
}

.u-header__section--hidden {
    position: relative
}

.u-header[data-header-fix-effect] {
    transition: .3s ease
}

.u-header.u-header--untransitioned {
    transition: none
}

.u-header.js-header-fix-moment {
    position: fixed;
    top: 0;
    bottom: auto
}

.u-header--fix-top {
    position: fixed
}

.u-header.u-header--fix-top[data-effect-compensation] {
    transition: none
}

@media (min-width: 992px) {
    .u-header__navbar-body, .u-header__navbar-nav {
        margin-left: auto
    }

    .u-header-left-aligned-nav .u-header__navbar-body, .u-header-left-aligned-nav .u-header__navbar-nav {
        margin-left: 0;
        margin-right: auto
    }

    .u-header-left-aligned-nav .u-header__navbar-nav {
        width: 100%
    }

    .u-header-left-aligned-nav .u-header__navbar-brand {
        margin-left: 0;
        margin-right: 3rem
    }

    .u-header-left-aligned-nav .u-header__nav-last-item {
        margin-left: auto
    }

    .u-header-center-aligned-nav__col {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%
    }

    .u-header-center-aligned-nav__col-last-item {
        text-align: right
    }

    .u-header-center-aligned-nav .u-header__navbar-body, .u-header-center-aligned-nav .u-header__navbar-nav {
        margin-left: auto;
        margin-right: auto
    }
}

@media (max-width: 1199.98px) {
    .u-header-center-aligned-nav__col:not(.u-header-center-aligned-nav__col-last-item) {
        width: 100%;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: justify;
        justify-content: space-between
    }

    .u-header-center-aligned-nav__col-last-item {
        -ms-flex-positive: 1;
        flex-grow: 1
    }
}

@media (min-width: 768px) {
    .u-header-center-aligned-nav.u-header--abs-top-sm .u-header__navbar-body, .u-header-center-aligned-nav.u-header--abs-top-sm .u-header__navbar-nav {
        margin-left: auto;
        margin-right: auto
    }
}

.u-header--white-bg-on-scroll.u-scrolled .u-header__section {
    background-color: #fff;
    box-shadow: 0 1px 10px rgba(151, 164, 175, .1)
}

.navbar-expand {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.navbar-expand.u-header__navbar--no-space {
    padding-top: 0;
    padding-bottom: 0
}

.navbar-expand .u-header__navbar-nav {
    -ms-flex-align: center;
    align-items: center;
    padding-top: 0;
    padding-bottom: 0
}

.navbar-expand .u-header__navbar-nav .u-header__nav-last-item {
    text-align: right;
    padding-left: 1rem;
    padding-right: 0
}

.navbar-expand .u-header__navbar-nav .u-header__nav-link {
    padding: .8rem 1rem
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu {
    border-top: .1875rem solid #3498db;
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem;
    box-shadow: 0 8px 20px rgba(52, 152, 219, .075)
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-link {
    padding-right: 1.75rem;
    padding-left: 1.75rem
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
    padding-left: 0
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
    padding-top: 1rem;
    padding-bottom: 1rem
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
    padding: 0
}

.navbar-expand .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
    margin-top: -1.1875rem
}

.navbar-expand .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
    right: 20%
}

.navbar-expand .u-header__mega-menu-wrapper {
    padding: 2.5rem;
    margin-left: 0;
    margin-right: 0
}

.navbar-expand .u-header__promo {
    border-bottom-right-radius: .25rem
}

.navbar-expand .u-header__promo-card {
    display: -ms-flexbox;
    display: flex;
    -ms-flex: 1 0 0%;
    flex: 1 0 0%;
    -ms-flex-direction: column;
    flex-direction: column
}

.navbar-expand .u-header__promo-card-deck {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap
}

.navbar-expand .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
    position: relative
}

.navbar-expand .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link:after {
    position: absolute;
    top: 0;
    right: 0;
    margin-right: -1.5rem;
    border-right: 1px solid #e7eaf3;
    height: 100%;
    content: ""
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .navbar-expand-sm.u-header__navbar--no-space {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-sm .u-header__navbar-nav {
        -ms-flex-align: center;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-sm .u-header__navbar-nav .u-header__nav-last-item {
        text-align: right;
        padding-left: 1rem;
        padding-right: 0
    }

    .navbar-expand-sm .u-header__navbar-nav .u-header__nav-link {
        padding: .8rem 1rem
    }

    .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu {
        border-top: .1875rem solid #3498db;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem;
        box-shadow: 0 8px 20px rgba(52, 152, 219, .075)
    }

    .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-nav-link {
        padding-right: 1.75rem;
        padding-left: 1.75rem
    }

    .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
        padding-left: 0
    }

    .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
        padding: 0
    }

    .navbar-expand-sm .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
        margin-top: -1.1875rem
    }

    .navbar-expand-sm .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
        right: 20%
    }

    .navbar-expand-sm .u-header__mega-menu-wrapper {
        padding: 2.5rem;
        margin-left: 0;
        margin-right: 0
    }

    .navbar-expand-sm .u-header__promo {
        border-bottom-right-radius: .25rem
    }

    .navbar-expand-sm .u-header__promo-card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-sm .u-header__promo-card-deck {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-sm .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
        position: relative
    }

    .navbar-expand-sm .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link:after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -1.5rem;
        border-right: 1px solid #e7eaf3;
        height: 100%;
        content: ""
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .navbar-expand-md.u-header__navbar--no-space {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-md .u-header__navbar-nav {
        -ms-flex-align: center;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-md .u-header__navbar-nav .u-header__nav-last-item {
        text-align: right;
        padding-left: 1rem;
        padding-right: 0
    }

    .navbar-expand-md .u-header__navbar-nav .u-header__nav-link {
        padding: .8rem 1rem
    }

    .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu {
        border-top: .1875rem solid #3498db;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem;
        box-shadow: 0 8px 20px rgba(52, 152, 219, .075)
    }

    .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-nav-link {
        padding-right: 1.75rem;
        padding-left: 1.75rem
    }

    .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
        padding-left: 0
    }

    .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
        padding: 0
    }

    .navbar-expand-md .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
        margin-top: -1.1875rem
    }

    .navbar-expand-md .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
        right: 20%
    }

    .navbar-expand-md .u-header__mega-menu-wrapper {
        padding: 2.5rem;
        margin-left: 0;
        margin-right: 0
    }

    .navbar-expand-md .u-header__promo {
        border-bottom-right-radius: .25rem
    }

    .navbar-expand-md .u-header__promo-card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-md .u-header__promo-card-deck {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-md .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
        position: relative
    }

    .navbar-expand-md .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link:after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -1.5rem;
        border-right: 1px solid #e7eaf3;
        height: 100%;
        content: ""
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .navbar-expand-lg.u-header__navbar--no-space {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-lg .u-header__navbar-nav {
        -ms-flex-align: center;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-lg .u-header__navbar-nav .u-header__nav-last-item {
        text-align: right;
        padding-left: 1rem;
        padding-right: 0
    }

    .navbar-expand-lg .u-header__navbar-nav .u-header__nav-link {
        padding: .8rem 1rem
    }

    .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu {
        border-top: .1875rem solid #3498db;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem;
        box-shadow: 0 8px 20px rgba(52, 152, 219, .075)
    }

    .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-nav-link {
        padding-right: 1.75rem;
        padding-left: 1.75rem
    }

    .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
        padding-left: 0
    }

    .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
        padding: 0
    }

    .navbar-expand-lg .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
        margin-top: -1.1875rem
    }

    .navbar-expand-lg .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
        right: 20%
    }

    .navbar-expand-lg .u-header__mega-menu-wrapper {
        padding: 2.5rem;
        margin-left: 0;
        margin-right: 0
    }

    .navbar-expand-lg .u-header__promo {
        border-bottom-right-radius: .25rem
    }

    .navbar-expand-lg .u-header__promo-card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-lg .u-header__promo-card-deck {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-lg .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
        position: relative
    }

    .navbar-expand-lg .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link:after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -1.5rem;
        border-right: 1px solid #e7eaf3;
        height: 100%;
        content: ""
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        -ms-flex-wrap: wrap;
        flex-wrap: wrap
    }

    .navbar-expand-xl.u-header__navbar--no-space {
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-xl .u-header__navbar-nav {
        -ms-flex-align: center;
        align-items: center;
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__nav-last-item {
        text-align: right;
        padding-left: 1rem;
        padding-right: 0
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__nav-link {
        padding: .8rem 1rem
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu {
        border-top: .1875rem solid #3498db;
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem;
        box-shadow: 0 8px 20px rgba(52, 152, 219, .075)
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-link {
        padding-right: 1.75rem;
        padding-left: 1.75rem
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu-nav-group .u-header__sub-menu-nav-link {
        padding-left: 0
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu.hs-sub-menu {
        padding-top: 1rem;
        padding-bottom: 1rem
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu {
        padding: 0
    }

    .navbar-expand-xl .u-header__navbar-nav .u-header__sub-menu .hs-has-sub-menu .u-header__sub-menu {
        margin-top: -1.1875rem
    }

    .navbar-expand-xl .u-header__mega-menu-position-right-fix.hs-mega-menu.hs-position-right {
        right: 20%
    }

    .navbar-expand-xl .u-header__mega-menu-wrapper {
        padding: 2.5rem;
        margin-left: 0;
        margin-right: 0
    }

    .navbar-expand-xl .u-header__promo {
        border-bottom-right-radius: .25rem
    }

    .navbar-expand-xl .u-header__promo-card {
        display: -ms-flexbox;
        display: flex;
        -ms-flex: 1 0 0%;
        flex: 1 0 0%;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .navbar-expand-xl .u-header__promo-card-deck {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-flow: row wrap;
        flex-flow: row wrap
    }

    .navbar-expand-xl .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link {
        position: relative
    }

    .navbar-expand-xl .u-header__promo-card-deck .u-header__promo-item:not(:last-child) .u-header__promo-link:after {
        position: absolute;
        top: 0;
        right: 0;
        margin-right: -1.5rem;
        border-right: 1px solid #e7eaf3;
        height: 100%;
        content: ""
    }
}

@media (max-width: 575.98px) {
    .navbar-expand-sm .u-header__nav-last-item {
        display: none
    }

    .navbar-expand-sm .u-header__nav-link, .navbar-expand-sm .u-header__sub-menu-nav-link {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-sm .u-header__nav-link-toggle:after, .navbar-expand-sm .u-header__sub-menu-nav-link-toggle:after {
        margin-left: auto
    }

    .navbar-expand-sm .u-header__sub-menu-nav-link-toggle:after {
        transform: rotate(90deg)
    }

    .navbar-expand-sm .hs-mega-menu-opened .u-header__nav-link-toggle:after, .navbar-expand-sm .hs-sub-menu-opened .u-header__nav-link-toggle:after {
        transform: rotate(-180deg)
    }

    .navbar-expand-sm .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle:after {
        transform: rotate(-90deg)
    }

    .navbar-expand-sm .u-header__mega-menu-wrapper {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-sm .u-header__sub-menu {
        min-width: 100% !important
    }

    .navbar-expand-sm .u-header__sub-menu--spacer {
        padding-left: 1.5rem
    }

    .navbar-expand-sm .u-header__sub-menu:not(.u-header__promo) {
        border-left: .1875rem solid #3498db
    }

    .navbar-expand-sm .u-header__sub-menu .u-header__sub-menu {
        padding-left: 1.5rem
    }

    .navbar-expand-sm .u-header__sub-menu-title {
        padding-left: 1.5rem;
        margin-bottom: 0
    }

    .navbar-expand-sm .u-header__sub-menu-nav-group {
        padding-top: .5rem;
        padding-left: 1.5rem
    }

    .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu {
        padding-left: 1.5rem;
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-sm .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
        animation: none
    }

    .navbar-expand-sm .u-header-center-aligned-nav__col-last-item {
        display: none
    }

    .navbar-expand-sm .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
        border-bottom: 1px solid #e7eaf3
    }
}

@media (max-width: 767.98px) {
    .navbar-expand-md .u-header__nav-last-item {
        display: none
    }

    .navbar-expand-md .u-header__nav-link, .navbar-expand-md .u-header__sub-menu-nav-link {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-md .u-header__nav-link-toggle:after, .navbar-expand-md .u-header__sub-menu-nav-link-toggle:after {
        margin-left: auto
    }

    .navbar-expand-md .u-header__sub-menu-nav-link-toggle:after {
        transform: rotate(90deg)
    }

    .navbar-expand-md .hs-mega-menu-opened .u-header__nav-link-toggle:after, .navbar-expand-md .hs-sub-menu-opened .u-header__nav-link-toggle:after {
        transform: rotate(-180deg)
    }

    .navbar-expand-md .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle:after {
        transform: rotate(-90deg)
    }

    .navbar-expand-md .u-header__mega-menu-wrapper {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-md .u-header__sub-menu {
        min-width: 100% !important
    }

    .navbar-expand-md .u-header__sub-menu--spacer {
        padding-left: 1.5rem
    }

    .navbar-expand-md .u-header__sub-menu:not(.u-header__promo) {
        border-left: .1875rem solid #3498db
    }

    .navbar-expand-md .u-header__sub-menu .u-header__sub-menu {
        padding-left: 1.5rem
    }

    .navbar-expand-md .u-header__sub-menu-title {
        padding-left: 1.5rem;
        margin-bottom: 0
    }

    .navbar-expand-md .u-header__sub-menu-nav-group {
        padding-top: .5rem;
        padding-left: 1.5rem
    }

    .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu {
        padding-left: 1.5rem;
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-md .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
        animation: none
    }

    .navbar-expand-md .u-header-center-aligned-nav__col-last-item {
        display: none
    }

    .navbar-expand-md .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
        border-bottom: 1px solid #e7eaf3
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg .u-header__nav-last-item {
        display: none
    }

    .navbar-expand-lg .u-header__nav-link, .navbar-expand-lg .u-header__sub-menu-nav-link {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-lg .u-header__nav-link-toggle:after, .navbar-expand-lg .u-header__sub-menu-nav-link-toggle:after {
        margin-left: auto
    }

    .navbar-expand-lg .u-header__sub-menu-nav-link-toggle:after {
        transform: rotate(90deg)
    }

    .navbar-expand-lg .hs-mega-menu-opened .u-header__nav-link-toggle:after, .navbar-expand-lg .hs-sub-menu-opened .u-header__nav-link-toggle:after {
        transform: rotate(-180deg)
    }

    .navbar-expand-lg .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle:after {
        transform: rotate(-90deg)
    }

    .navbar-expand-lg .u-header__mega-menu-wrapper {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-lg .u-header__sub-menu {
        min-width: 100% !important
    }

    .navbar-expand-lg .u-header__sub-menu--spacer {
        padding-left: 1.5rem
    }

    .navbar-expand-lg .u-header__sub-menu:not(.u-header__promo) {
        border-left: .1875rem solid #3498db
    }

    .navbar-expand-lg .u-header__sub-menu .u-header__sub-menu {
        padding-left: 1.5rem
    }

    .navbar-expand-lg .u-header__sub-menu-title {
        padding-left: 1.5rem;
        margin-bottom: 0
    }

    .navbar-expand-lg .u-header__sub-menu-nav-group {
        padding-top: .5rem;
        padding-left: 1.5rem
    }

    .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu {
        padding-left: 1.5rem;
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-lg .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
        animation: none
    }

    .navbar-expand-lg .u-header-center-aligned-nav__col-last-item {
        display: none
    }

    .navbar-expand-lg .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
        border-bottom: 1px solid #e7eaf3
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl .u-header__nav-last-item {
        display: none
    }

    .navbar-expand-xl .u-header__nav-link, .navbar-expand-xl .u-header__sub-menu-nav-link {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-align: center;
        align-items: center
    }

    .navbar-expand-xl .u-header__nav-link-toggle:after, .navbar-expand-xl .u-header__sub-menu-nav-link-toggle:after {
        margin-left: auto
    }

    .navbar-expand-xl .u-header__sub-menu-nav-link-toggle:after {
        transform: rotate(90deg)
    }

    .navbar-expand-xl .hs-mega-menu-opened .u-header__nav-link-toggle:after, .navbar-expand-xl .hs-sub-menu-opened .u-header__nav-link-toggle:after {
        transform: rotate(-180deg)
    }

    .navbar-expand-xl .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle:after {
        transform: rotate(-90deg)
    }

    .navbar-expand-xl .u-header__mega-menu-wrapper {
        padding-top: .5rem;
        padding-bottom: .5rem
    }

    .navbar-expand-xl .u-header__sub-menu {
        min-width: 100% !important
    }

    .navbar-expand-xl .u-header__sub-menu--spacer {
        padding-left: 1.5rem
    }

    .navbar-expand-xl .u-header__sub-menu:not(.u-header__promo) {
        border-left: .1875rem solid #3498db
    }

    .navbar-expand-xl .u-header__sub-menu .u-header__sub-menu {
        padding-left: 1.5rem
    }

    .navbar-expand-xl .u-header__sub-menu-title {
        padding-left: 1.5rem;
        margin-bottom: 0
    }

    .navbar-expand-xl .u-header__sub-menu-nav-group {
        padding-top: .5rem;
        padding-left: 1.5rem
    }

    .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu {
        padding-left: 1.5rem;
        padding-top: 0;
        padding-bottom: 0
    }

    .navbar-expand-xl .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
        animation: none
    }

    .navbar-expand-xl .u-header-center-aligned-nav__col-last-item {
        display: none
    }

    .navbar-expand-xl .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
        border-bottom: 1px solid #e7eaf3
    }
}

.navbar-expand .u-header__nav-last-item {
    display: none
}

.navbar-expand .u-header__nav-link, .navbar-expand .u-header__sub-menu-nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
}

.navbar-expand .u-header__nav-link-toggle:after, .navbar-expand .u-header__sub-menu-nav-link-toggle:after {
    margin-left: auto
}

.navbar-expand .u-header__sub-menu-nav-link-toggle:after {
    transform: rotate(90deg)
}

.navbar-expand .hs-mega-menu-opened .u-header__nav-link-toggle:after, .navbar-expand .hs-sub-menu-opened .u-header__nav-link-toggle:after {
    transform: rotate(-180deg)
}

.navbar-expand .hs-sub-menu-opened > .u-header__sub-menu-nav-link-toggle:after {
    transform: rotate(-90deg)
}

.navbar-expand .u-header__mega-menu-wrapper {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-expand .u-header__sub-menu {
    min-width: 100% !important
}

.navbar-expand .u-header__sub-menu--spacer {
    padding-left: 1.5rem
}

.navbar-expand .u-header__sub-menu:not(.u-header__promo) {
    border-left: .1875rem solid #3498db
}

.navbar-expand .u-header__sub-menu .u-header__sub-menu {
    padding-left: 1.5rem
}

.navbar-expand .u-header__sub-menu-title {
    padding-left: 1.5rem;
    margin-bottom: 0
}

.navbar-expand .u-header__sub-menu-nav-group {
    padding-top: .5rem;
    padding-left: 1.5rem
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu {
    padding-left: 1.5rem;
    padding-top: 0;
    padding-bottom: 0
}

.navbar-expand .u-header__sub-menu .hs-has-sub-menu .hs-sub-menu {
    animation: none
}

.navbar-expand .u-header-center-aligned-nav__col-last-item {
    display: none
}

.navbar-expand .u-header__promo-card-deck:last-child .u-header__promo-item:not(:last-child) {
    border-bottom: 1px solid #e7eaf3
}

@media (max-width: 767.98px) {
    .navbar-expand-sm .u-header__nav-last-item {
        display: none
    }
}

@media (max-width: 991.98px) {
    .navbar-expand-lg .u-header__banner, .navbar-expand-lg .u-header__product-banner, .navbar-expand-md .u-header__banner, .navbar-expand-md .u-header__product-banner, .navbar-expand-sm .u-header__banner, .navbar-expand-sm .u-header__product-banner, .navbar-expand-xs .u-header__banner, .navbar-expand-xs .u-header__product-banner {
        display: none
    }
}

@media (max-width: 1199.98px) {
    .navbar-expand-xl .u-header__banner, .navbar-expand-xl .u-header__product-banner {
        display: none
    }
}

@media (min-width: 768px) and (max-width: 991.98px) {
    .navbar-expand-md .u-header__navbar-brand {
        padding-top: .5rem
    }

    .u-header .u-header__mega-menu-position-right-fix--md.hs-mega-menu.hs-position-right {
        right: 20%
    }
}

@media (min-width: 576px) and (max-width: 991.98px) {
    .navbar-expand-sm .u-header__navbar-brand {
        padding-top: .5rem
    }
}

@media (min-width: 992px) {
    .navbar-expand .u-header__nav-last-item {
        display: inline-block
    }

    [class*=navbar-expand]:not(.navbar-expand-xl) {
        -ms-flex-wrap: nowrap;
        flex-wrap: nowrap
    }
}

.alert-text {
    color: inherit
}

.u-avatar {
    width: 2.4375rem;
    height: 2.4375rem
}

.u-xs-avatar {
    width: 1.11719rem;
    height: 1.11719rem
}

.u-sm-avatar {
    width: 2.8rem;
    height: 1.625rem
}

.u-lg-avatar {
    width: 4.0625rem;
    height: 4.0625rem
}

.u-xl-avatar {
    width: 8.125rem;
    height: 8.125rem
}

.u-sm-avatar--bordered {
    border: 2px solid #fff
}

.u-xl-avatar--bordered {
    border: 7px solid #fff
}

[class*=badge-] {
    z-index: 3
}

.badge:empty {
    display: inherit
}

.badge.badge-pos {
    position: absolute
}

.btn .badge-pos {
    top: auto
}

[class*=badge-outline] {
    border-style: solid;
    border-width: .10156rem;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25)
}

.badge-xs {
    width: .71094rem;
    height: .71094rem;
    line-height: 1.4;
    font-size: .40625rem
}

.badge-sm {
    width: .91406rem;
    height: .91406rem;
    line-height: 1.4;
    font-size: .50781rem
}

.badge-sm[class*=badge-outline] {
    line-height: 1
}

.badge-md {
    width: 1.11719rem;
    height: 1.11719rem;
    line-height: 1.7;
    font-size: .50781rem
}

.badge-md[class*=badge-outline] {
    line-height: 1.3
}

.badge-bigger {
    padding: .50781rem
}

.badge-outline-primary {
    color: #fff;
    background: #3498db;
    border-color: #fff
}

.badge-outline-secondary {
    color: #fff;
    background: #77838f;
    border-color: #fff
}

.badge-outline-success {
    color: #fff;
    background: #00c9a7;
    border-color: #fff
}

.badge-outline-info {
    color: #fff;
    background: #3498db;
    border-color: #fff
}

.badge-outline-warning {
    color: #1e2022;
    background: #db9a04;
    border-color: #fff
}

.badge-outline-danger {
    color: #fff;
    background: #de4437;
    border-color: #fff
}

.badge-outline-light {
    color: #1e2022;
    background: #f8f9fa;
    border-color: #fff
}

.badge-outline-dark {
    color: #fff;
    background: #1e2022;
    border-color: #fff
}

.badge-outline-white {
    color: #1e2022;
    background: #fff
}

.badge-outline-indigo {
    color: #fff;
    background: #2d1582;
    border-color: #fff
}

.badge-outline-white {
    border-color: #fff
}

.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    top: 0;
    right: 0;
    transform: translate(50%, -50%)
}

.badge-pos--top-left {
    top: 0;
    left: 0;
    transform: translate(-50%, -50%)
}

.badge-pos--bottom-left {
    bottom: 0;
    left: 0;
    transform: translate(-50%, 50%)
}

.badge-pos--bottom-right {
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%)
}

.u-avatar .rounded-circle + .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(20%, -20%)
}

.u-avatar .rounded-circle + .badge-xs.badge-pos--top-left {
    transform: translate(-20%, -20%)
}

.u-avatar .rounded-circle + .badge-xs.badge-pos--bottom-left {
    transform: translate(-20%, 20%)
}

.u-avatar .rounded-circle + .badge-xs.badge-pos--bottom-right {
    transform: translate(20%, 20%)
}

.u-avatar .rounded-circle + .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(25%, -25%)
}

.u-avatar .rounded-circle + .badge-sm.badge-pos--top-left {
    transform: translate(-25%, -25%)
}

.u-avatar .rounded-circle + .badge-sm.badge-pos--bottom-left {
    transform: translate(-25%, 25%)
}

.u-avatar .rounded-circle + .badge-sm.badge-pos--bottom-right {
    transform: translate(25%, 25%)
}

.u-avatar .rounded-circle + .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(30%, -30%)
}

.u-avatar .rounded-circle + .badge-md.badge-pos--top-left {
    transform: translate(-30%, -30%)
}

.u-avatar .rounded-circle + .badge-md.badge-pos--bottom-left {
    transform: translate(-30%, 30%)
}

.u-avatar .rounded-circle + .badge-md.badge-pos--bottom-right {
    transform: translate(30%, 30%)
}

.u-sm-avatar .rounded-circle + .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(25%, -25%)
}

.u-sm-avatar .rounded-circle + .badge-xs.badge-pos--top-left {
    transform: translate(-25%, -25%)
}

.u-sm-avatar .rounded-circle + .badge-xs.badge-pos--bottom-left {
    transform: translate(-25%, 25%)
}

.u-sm-avatar .rounded-circle + .badge-xs.badge-pos--bottom-right {
    transform: translate(25%, 25%)
}

.u-sm-avatar .rounded-circle + .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .u-sm-avatar .rounded-circle + .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(35%, -35%)
}

.u-sm-avatar .rounded-circle + .badge-md.badge-pos--top-left, .u-sm-avatar .rounded-circle + .badge-sm.badge-pos--top-left {
    transform: translate(-35%, -35%)
}

.u-sm-avatar .rounded-circle + .badge-md.badge-pos--bottom-left, .u-sm-avatar .rounded-circle + .badge-sm.badge-pos--bottom-left {
    transform: translate(-35%, 35%)
}

.u-sm-avatar .rounded-circle + .badge-md.badge-pos--bottom-right, .u-sm-avatar .rounded-circle + .badge-sm.badge-pos--bottom-right {
    transform: translate(35%, 35%)
}

.u-lg-avatar .rounded-circle + .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(-35%, 35%)
}

.u-lg-avatar .rounded-circle + .badge-xs.badge-pos--top-left {
    transform: translate(35%, 35%)
}

.u-lg-avatar .rounded-circle + .badge-xs.badge-pos--bottom-left {
    transform: translate(35%, -35%)
}

.u-lg-avatar .rounded-circle + .badge-xs.badge-pos--bottom-right {
    transform: translate(-35%, -35%)
}

.u-lg-avatar .rounded-circle + .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(-15%, 15%)
}

.u-lg-avatar .rounded-circle + .badge-sm.badge-pos--top-left {
    transform: translate(15%, 15%)
}

.u-lg-avatar .rounded-circle + .badge-sm.badge-pos--bottom-left {
    transform: translate(15%, -15%)
}

.u-lg-avatar .rounded-circle + .badge-sm.badge-pos--bottom-right {
    transform: translate(-15%, -15%)
}

.u-lg-avatar .rounded-circle + .badge-md.badge-pos--bottom-left, .u-lg-avatar .rounded-circle + .badge-md.badge-pos--bottom-right, .u-lg-avatar .rounded-circle + .badge-md.badge-pos--top-left, .u-lg-avatar .rounded-circle + .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(0)
}

.u-xl-avatar .rounded-circle + .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(-100%, 100%)
}

.u-xl-avatar .rounded-circle + .badge-xs.badge-pos--top-left {
    transform: translate(100%, 100%)
}

.u-xl-avatar .rounded-circle + .badge-xs.badge-pos--bottom-left {
    transform: translate(100%, -100%)
}

.u-xl-avatar .rounded-circle + .badge-xs.badge-pos--bottom-right {
    transform: translate(-100%, -100%)
}

.u-xl-avatar .rounded-circle + .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(-65%, 65%)
}

.u-xl-avatar .rounded-circle + .badge-sm.badge-pos--top-left {
    transform: translate(65%, 65%)
}

.u-xl-avatar .rounded-circle + .badge-sm.badge-pos--bottom-left {
    transform: translate(65%, -65%)
}

.u-xl-avatar .rounded-circle + .badge-sm.badge-pos--bottom-right {
    transform: translate(-65%, -65%)
}

.u-xl-avatar .rounded-circle + .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(-45%, 45%)
}

.u-xl-avatar .rounded-circle + .badge-md.badge-pos--top-left {
    transform: translate(45%, 45%)
}

.u-xl-avatar .rounded-circle + .badge-md.badge-pos--bottom-left {
    transform: translate(45%, -45%)
}

.u-xl-avatar .rounded-circle + .badge-md.badge-pos--bottom-right {
    transform: translate(-45%, -45%)
}

.btn-icon.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(20%, -20%)
}

.btn-icon.rounded-circle .badge-xs.badge-pos--top-left {
    transform: translate(-20%, -20%)
}

.btn-icon.rounded-circle .badge-xs.badge-pos--bottom-left {
    transform: translate(-20%, 20%)
}

.btn-icon.rounded-circle .badge-xs.badge-pos--bottom-right {
    transform: translate(20%, 20%)
}

.btn-icon.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(25%, -25%)
}

.btn-icon.rounded-circle .badge-sm.badge-pos--top-left {
    transform: translate(-25%, -25%)
}

.btn-icon.rounded-circle .badge-sm.badge-pos--bottom-left {
    transform: translate(-25%, 25%)
}

.btn-icon.rounded-circle .badge-sm.badge-pos--bottom-right {
    transform: translate(25%, 25%)
}

.btn-icon.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(30%, -30%)
}

.btn-icon.rounded-circle .badge-md.badge-pos--top-left {
    transform: translate(-30%, -30%)
}

.btn-icon.rounded-circle .badge-md.badge-pos--bottom-left {
    transform: translate(-30%, 30%)
}

.btn-icon.rounded-circle .badge-md.badge-pos--bottom-right {
    transform: translate(30%, 30%)
}

.btn-group-sm > .rounded-circle.btn .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-sm.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(25%, -25%)
}

.btn-group-sm > .rounded-circle.btn .badge-xs.badge-pos--top-left, .btn-sm.rounded-circle .badge-xs.badge-pos--top-left {
    transform: translate(-25%, -25%)
}

.btn-group-sm > .rounded-circle.btn .badge-xs.badge-pos--bottom-left, .btn-sm.rounded-circle .badge-xs.badge-pos--bottom-left {
    transform: translate(-25%, 25%)
}

.btn-group-sm > .rounded-circle.btn .badge-xs.badge-pos--bottom-right, .btn-sm.rounded-circle .badge-xs.badge-pos--bottom-right {
    transform: translate(25%, 25%)
}

.btn-group-sm > .rounded-circle.btn .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-group-sm > .rounded-circle.btn .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-sm.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-sm.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(35%, -35%)
}

.btn-group-sm > .rounded-circle.btn .badge-md.badge-pos--top-left, .btn-group-sm > .rounded-circle.btn .badge-sm.badge-pos--top-left, .btn-sm.rounded-circle .badge-md.badge-pos--top-left, .btn-sm.rounded-circle .badge-sm.badge-pos--top-left {
    transform: translate(-35%, -35%)
}

.btn-group-sm > .rounded-circle.btn .badge-md.badge-pos--bottom-left, .btn-group-sm > .rounded-circle.btn .badge-sm.badge-pos--bottom-left, .btn-sm.rounded-circle .badge-md.badge-pos--bottom-left, .btn-sm.rounded-circle .badge-sm.badge-pos--bottom-left {
    transform: translate(-35%, 35%)
}

.btn-group-sm > .rounded-circle.btn .badge-md.badge-pos--bottom-right, .btn-group-sm > .rounded-circle.btn .badge-sm.badge-pos--bottom-right, .btn-sm.rounded-circle .badge-md.badge-pos--bottom-right, .btn-sm.rounded-circle .badge-sm.badge-pos--bottom-right {
    transform: translate(35%, 35%)
}

.btn-group-lg > .rounded-circle.btn .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-lg.rounded-circle .badge-xs.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(-35%, 35%)
}

.btn-group-lg > .rounded-circle.btn .badge-xs.badge-pos--top-left, .btn-lg.rounded-circle .badge-xs.badge-pos--top-left {
    transform: translate(35%, 35%)
}

.btn-group-lg > .rounded-circle.btn .badge-xs.badge-pos--bottom-left, .btn-lg.rounded-circle .badge-xs.badge-pos--bottom-left {
    transform: translate(35%, -35%)
}

.btn-group-lg > .rounded-circle.btn .badge-xs.badge-pos--bottom-right, .btn-lg.rounded-circle .badge-xs.badge-pos--bottom-right {
    transform: translate(-35%, -35%)
}

.btn-group-lg > .rounded-circle.btn .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-lg.rounded-circle .badge-sm.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(-15%, 15%)
}

.btn-group-lg > .rounded-circle.btn .badge-sm.badge-pos--top-left, .btn-lg.rounded-circle .badge-sm.badge-pos--top-left {
    transform: translate(15%, 15%)
}

.btn-group-lg > .rounded-circle.btn .badge-sm.badge-pos--bottom-left, .btn-lg.rounded-circle .badge-sm.badge-pos--bottom-left {
    transform: translate(15%, -15%)
}

.btn-group-lg > .rounded-circle.btn .badge-sm.badge-pos--bottom-right, .btn-lg.rounded-circle .badge-sm.badge-pos--bottom-right {
    transform: translate(-15%, -15%)
}

.btn-group-lg > .rounded-circle.btn .badge-md.badge-pos--bottom-left, .btn-group-lg > .rounded-circle.btn .badge-md.badge-pos--bottom-right, .btn-group-lg > .rounded-circle.btn .badge-md.badge-pos--top-left, .btn-group-lg > .rounded-circle.btn .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]), .btn-lg.rounded-circle .badge-md.badge-pos--bottom-left, .btn-lg.rounded-circle .badge-md.badge-pos--bottom-right, .btn-lg.rounded-circle .badge-md.badge-pos--top-left, .btn-lg.rounded-circle .badge-md.badge-pos:not([class*="--top-left"]):not([class*="--bottom-left"]):not([class*="--bottom-right"]) {
    transform: translate(0)
}

.btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998
}

.btn-facebook:hover {
    color: #fff;
    background-color: #30497c;
    border-color: #2d4373
}

.btn-facebook.focus, .btn-facebook:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 114, 167, .5)
}

.btn-facebook.disabled, .btn-facebook:disabled {
    color: #fff;
    background-color: #3b5998;
    border-color: #3b5998
}

.btn-facebook:not(:disabled):not(.disabled).active, .btn-facebook:not(:disabled):not(.disabled):active, .show > .btn-facebook.dropdown-toggle {
    color: #fff;
    background-color: #2d4373;
    border-color: #293e6a
}

.btn-facebook:not(:disabled):not(.disabled).active:focus, .btn-facebook:not(:disabled):not(.disabled):active:focus, .show > .btn-facebook.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(88, 114, 167, .5)
}

.btn-google {
    color: #fff;
    background-color: #d14130;
    border-color: #d14130
}

.btn-google:hover {
    color: #fff;
    background-color: #b33628;
    border-color: #a93325
}

.btn-google.focus, .btn-google:focus {
    box-shadow: 0 0 0 .2rem rgba(216, 94, 79, .5)
}

.btn-google.disabled, .btn-google:disabled {
    color: #fff;
    background-color: #d14130;
    border-color: #d14130
}

.btn-google:not(:disabled):not(.disabled).active, .btn-google:not(:disabled):not(.disabled):active, .show > .btn-google.dropdown-toggle {
    color: #fff;
    background-color: #a93325;
    border-color: #9e3023
}

.btn-google:not(:disabled):not(.disabled).active:focus, .btn-google:not(:disabled):not(.disabled):active:focus, .show > .btn-google.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(216, 94, 79, .5)
}

.btn-twitter {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2
}

.btn-twitter:hover {
    color: #fff;
    background-color: #0d8ddc;
    border-color: #0c85d0
}

.btn-twitter.focus, .btn-twitter:focus {
    box-shadow: 0 0 0 .2rem rgba(63, 175, 244, .5)
}

.btn-twitter.disabled, .btn-twitter:disabled {
    color: #fff;
    background-color: #1da1f2;
    border-color: #1da1f2
}

.btn-twitter:not(:disabled):not(.disabled).active, .btn-twitter:not(:disabled):not(.disabled):active, .show > .btn-twitter.dropdown-toggle {
    color: #fff;
    background-color: #0c85d0;
    border-color: #0b7ec4
}

.btn-twitter:not(:disabled):not(.disabled).active:focus, .btn-twitter:not(:disabled):not(.disabled):active:focus, .show > .btn-twitter.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(63, 175, 244, .5)
}

.btn-instagram {
    color: #fff;
    background-color: #3f729b;
    border-color: #3f729b
}

.btn-instagram:hover {
    color: #fff;
    background-color: #345e80;
    border-color: #305777
}

.btn-instagram.focus, .btn-instagram:focus {
    box-shadow: 0 0 0 .2rem rgba(92, 135, 170, .5)
}

.btn-instagram.disabled, .btn-instagram:disabled {
    color: #fff;
    background-color: #3f729b;
    border-color: #3f729b
}

.btn-instagram:not(:disabled):not(.disabled).active, .btn-instagram:not(:disabled):not(.disabled):active, .show > .btn-instagram.dropdown-toggle {
    color: #fff;
    background-color: #305777;
    border-color: #2d516e
}

.btn-instagram:not(:disabled):not(.disabled).active:focus, .btn-instagram:not(:disabled):not(.disabled):active:focus, .show > .btn-instagram.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(92, 135, 170, .5)
}

.btn-github {
    color: #fff;
    background-color: #24292e;
    border-color: #24292e
}

.btn-github:hover {
    color: #fff;
    background-color: #131619;
    border-color: #0e1011
}

.btn-github.focus, .btn-github:focus {
    box-shadow: 0 0 0 .2rem rgba(69, 73, 77, .5)
}

.btn-github.disabled, .btn-github:disabled {
    color: #fff;
    background-color: #24292e;
    border-color: #24292e
}

.btn-github:not(:disabled):not(.disabled).active, .btn-github:not(:disabled):not(.disabled):active, .show > .btn-github.dropdown-toggle {
    color: #fff;
    background-color: #0e1011;
    border-color: #08090a
}

.btn-github:not(:disabled):not(.disabled).active:focus, .btn-github:not(:disabled):not(.disabled):active:focus, .show > .btn-github.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem rgba(69, 73, 77, .5)
}

.btn-gray {
    color: #1e2022;
    background-color: #f8fafd;
    border-color: #f8fafd
}

.btn-gray:hover {
    color: #1e2022;
    background-color: #dae5f5;
    border-color: #d0def2
}

.btn-gray.focus, .btn-gray:focus {
    box-shadow: 0 0 0 .2rem hsla(216, 7%, 85%, .5)
}

.btn-gray.disabled, .btn-gray:disabled {
    color: #1e2022;
    background-color: #f8fafd;
    border-color: #f8fafd
}

.btn-gray:not(:disabled):not(.disabled).active, .btn-gray:not(:disabled):not(.disabled):active, .show > .btn-gray.dropdown-toggle {
    color: #1e2022;
    background-color: #d0def2;
    border-color: #c6d7ef
}

.btn-gray:not(:disabled):not(.disabled).active:focus, .btn-gray:not(:disabled):not(.disabled):active:focus, .show > .btn-gray.dropdown-toggle:focus {
    box-shadow: 0 0 0 .2rem hsla(216, 7%, 85%, .5)
}

.btn-primary[href]:active, .btn-primary[href]:focus, .btn-primary[href]:hover, .btn-primary[type]:active, .btn-primary[type]:focus, .btn-primary[type]:hover {
    box-shadow: 0 4px 11px rgba(63, 31, 72, .35)
}

.btn-primary:not([href]), .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #3498db;
    border-color: #3498db
}

.btn-secondary[href]:active, .btn-secondary[href]:focus, .btn-secondary[href]:hover, .btn-secondary[type]:active, .btn-secondary[type]:focus, .btn-secondary[type]:hover {
    box-shadow: 0 4px 11px rgba(119, 131, 143, .35)
}

.btn-secondary:not([href]), .btn-secondary:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #77838f;
    border-color: #77838f
}

.btn-success[href]:active, .btn-success[href]:focus, .btn-success[href]:hover, .btn-success[type]:active, .btn-success[type]:focus, .btn-success[type]:hover {
    box-shadow: 0 4px 11px rgba(0, 201, 167, .35)
}

.btn-success:not([href]), .btn-success:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #00c9a7;
    border-color: #00c9a7
}

.btn-info[href]:active, .btn-info[href]:focus, .btn-info[href]:hover, .btn-info[type]:active, .btn-info[type]:focus, .btn-info[type]:hover {
    box-shadow: 0 4px 11px rgba(52, 152, 219, .35)
}

.btn-info:not([href]), .btn-info:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #3498db;
    border-color: #3498db
}

.btn-warning[href]:active, .btn-warning[href]:focus, .btn-warning[href]:hover, .btn-warning[type]:active, .btn-warning[type]:focus, .btn-warning[type]:hover {
    box-shadow: 0 4px 11px rgba(219, 154, 4, .35)
}

.btn-warning:not([href]), .btn-warning:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #db9a04;
    border-color: #db9a04
}

.btn-danger[href]:active, .btn-danger[href]:focus, .btn-danger[href]:hover, .btn-danger[type]:active, .btn-danger[type]:focus, .btn-danger[type]:hover {
    box-shadow: 0 4px 11px rgba(222, 68, 55, .35)
}

.btn-danger:not([href]), .btn-danger:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #de4437;
    border-color: #de4437
}

.btn-light[href]:active, .btn-light[href]:focus, .btn-light[href]:hover, .btn-light[type]:active, .btn-light[type]:focus, .btn-light[type]:hover {
    box-shadow: 0 4px 11px rgba(248, 249, 250, .35)
}

.btn-light:not([href]), .btn-light:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.btn-dark[href]:active, .btn-dark[href]:focus, .btn-dark[href]:hover, .btn-dark[type]:active, .btn-dark[type]:focus, .btn-dark[type]:hover {
    box-shadow: 0 4px 11px rgba(30, 32, 34, .35)
}

.btn-dark:not([href]), .btn-dark:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #1e2022;
    border-color: #1e2022
}

.btn-white[href]:active, .btn-white[href]:focus, .btn-white[href]:hover, .btn-white[type]:active, .btn-white[type]:focus, .btn-white[type]:hover {
    box-shadow: 0 4px 11px hsla(0, 0%, 100%, .35)
}

.btn-white:not([href]), .btn-white:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #fff;
    border-color: #fff
}

.btn-indigo[href]:active, .btn-indigo[href]:focus, .btn-indigo[href]:hover, .btn-indigo[type]:active, .btn-indigo[type]:focus, .btn-indigo[type]:hover {
    box-shadow: 0 4px 11px rgba(45, 21, 130, .35)
}

.btn-indigo:not([href]), .btn-indigo:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #2d1582;
    border-color: #2d1582
}

.btn-facebook[href]:active, .btn-facebook[href]:focus, .btn-facebook[href]:hover, .btn-facebook[type]:active, .btn-facebook[type]:focus, .btn-facebook[type]:hover {
    box-shadow: 0 4px 11px rgba(59, 89, 152, .35)
}

.btn-facebook:not([href]), .btn-facebook:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #3b5998;
    border-color: #3b5998
}

.btn-google[href]:active, .btn-google[href]:focus, .btn-google[href]:hover, .btn-google[type]:active, .btn-google[type]:focus, .btn-google[type]:hover {
    box-shadow: 0 4px 11px rgba(209, 65, 48, .35)
}

.btn-google:not([href]), .btn-google:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #d14130;
    border-color: #d14130
}

.btn-twitter[href]:active, .btn-twitter[href]:focus, .btn-twitter[href]:hover, .btn-twitter[type]:active, .btn-twitter[type]:focus, .btn-twitter[type]:hover {
    box-shadow: 0 4px 11px rgba(29, 161, 242, .35)
}

.btn-twitter:not([href]), .btn-twitter:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #1da1f2;
    border-color: #1da1f2
}

.btn-instagram[href]:active, .btn-instagram[href]:focus, .btn-instagram[href]:hover, .btn-instagram[type]:active, .btn-instagram[type]:focus, .btn-instagram[type]:hover {
    box-shadow: 0 4px 11px rgba(63, 114, 155, .35)
}

.btn-instagram:not([href]), .btn-instagram:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #3f729b;
    border-color: #3f729b
}

.btn-github[href]:active, .btn-github[href]:focus, .btn-github[href]:hover, .btn-github[type]:active, .btn-github[type]:focus, .btn-github[type]:hover {
    box-shadow: 0 4px 11px rgba(36, 41, 46, .35)
}

.btn-github:not([href]), .btn-github:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #24292e;
    border-color: #24292e
}

.btn-gray[href]:active, .btn-gray[href]:focus, .btn-gray[href]:hover, .btn-gray[type]:active, .btn-gray[type]:focus, .btn-gray[type]:hover {
    box-shadow: 0 4px 11px rgba(248, 250, 253, .35)
}

.btn-gray:not([href]), .btn-gray:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #f8fafd;
    border-color: #f8fafd
}

.btn-custom-toggle-primary {
    color: #8c98a4;
    border-color: #d5dae2
}

.btn-custom-toggle-primary:hover {
    color: #3498db;
    background-color: transparent;
    border-color: #3498db
}

.btn-custom-toggle-primary:not(:disabled):not(.disabled).active, .btn-custom-toggle-primary:not(:disabled):not(.disabled):active {
    color: #fff;
    background-color: #3498db;
    border-color: #3498db
}

.btn-custom-toggle-white:hover {
    color: #fff;
    background-color: hsla(0, 0%, 100%, .2)
}

.btn-custom-toggle-white:not(:disabled):not(.disabled).active, .btn-custom-toggle-white:not(:disabled):not(.disabled):active {
    color: #3498db
}

.btn-soft-primary {
    color: #3498db;
    background: rgba(52, 152, 219, .1);
    border-color: transparent
}

.btn-soft-primary[href].active, .btn-soft-primary[href]:active, .btn-soft-primary[href]:focus, .btn-soft-primary[href]:hover, .btn-soft-primary[type].active, .btn-soft-primary[type]:active, .btn-soft-primary[type]:focus, .btn-soft-primary[type]:hover {
    color: #fff;
    background: #3498db;
    box-shadow: 0 4px 11px rgba(52, 152, 219, .35)
}

.btn-soft-primary[href].disabled, .btn-soft-primary[href]:disabled, .btn-soft-primary[type].disabled, .btn-soft-primary[type]:disabled {
    color: #3498db;
    background-color: rgba(52, 152, 219, .1);
    box-shadow: none
}

.btn-soft-secondary {
    color: #77838f;
    background: rgba(119, 131, 143, .1);
    border-color: transparent
}

.btn-soft-secondary[href].active, .btn-soft-secondary[href]:active, .btn-soft-secondary[href]:focus, .btn-soft-secondary[href]:hover, .btn-soft-secondary[type].active, .btn-soft-secondary[type]:active, .btn-soft-secondary[type]:focus, .btn-soft-secondary[type]:hover {
    color: #fff;
    background: #77838f;
    box-shadow: 0 4px 11px rgba(119, 131, 143, .35)
}

.btn-soft-secondary[href].disabled, .btn-soft-secondary[href]:disabled, .btn-soft-secondary[type].disabled, .btn-soft-secondary[type]:disabled {
    color: #77838f;
    background-color: rgba(119, 131, 143, .1);
    box-shadow: none
}

.btn-soft-success {
    color: #00c9a7;
    background: rgba(0, 201, 167, .1);
    border-color: transparent
}

.btn-soft-success[href].active, .btn-soft-success[href]:active, .btn-soft-success[href]:focus, .btn-soft-success[href]:hover, .btn-soft-success[type].active, .btn-soft-success[type]:active, .btn-soft-success[type]:focus, .btn-soft-success[type]:hover {
    color: #fff;
    background: #00c9a7;
    box-shadow: 0 4px 11px rgba(0, 201, 167, .35)
}

.btn-soft-success[href].disabled, .btn-soft-success[href]:disabled, .btn-soft-success[type].disabled, .btn-soft-success[type]:disabled {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .1);
    box-shadow: none
}

.btn-soft-info {
    color: #3498db;
    background: rgba(52, 152, 219, .1);
    border-color: transparent
}

.btn-soft-info[href].active, .btn-soft-info[href]:active, .btn-soft-info[href]:focus, .btn-soft-info[href]:hover, .btn-soft-info[type].active, .btn-soft-info[type]:active, .btn-soft-info[type]:focus, .btn-soft-info[type]:hover {
    color: #fff;
    background: #3498db;
    box-shadow: 0 4px 11px rgba(52, 152, 219, .35)
}

.btn-soft-info[href].disabled, .btn-soft-info[href]:disabled, .btn-soft-info[type].disabled, .btn-soft-info[type]:disabled {
    color: #3498db;
    background-color: rgba(52, 152, 219, .1);
    box-shadow: none
}

.btn-soft-warning {
    color: #db9a04;
    background: rgba(219, 154, 4, .1);
    border-color: transparent
}

.btn-soft-warning[href].active, .btn-soft-warning[href]:active, .btn-soft-warning[href]:focus, .btn-soft-warning[href]:hover, .btn-soft-warning[type].active, .btn-soft-warning[type]:active, .btn-soft-warning[type]:focus, .btn-soft-warning[type]:hover {
    color: #1e2022;
    background: #db9a04;
    box-shadow: 0 4px 11px rgba(219, 154, 4, .35)
}

.btn-soft-warning[href].disabled, .btn-soft-warning[href]:disabled, .btn-soft-warning[type].disabled, .btn-soft-warning[type]:disabled {
    color: #db9a04;
    background-color: rgba(219, 154, 4, .1);
    box-shadow: none
}

.btn-soft-danger {
    color: #de4437;
    background: rgba(222, 68, 55, .1);
    border-color: transparent
}

.btn-soft-danger[href].active, .btn-soft-danger[href]:active, .btn-soft-danger[href]:focus, .btn-soft-danger[href]:hover, .btn-soft-danger[type].active, .btn-soft-danger[type]:active, .btn-soft-danger[type]:focus, .btn-soft-danger[type]:hover {
    color: #fff;
    background: #de4437;
    box-shadow: 0 4px 11px rgba(222, 68, 55, .35)
}

.btn-soft-danger[href].disabled, .btn-soft-danger[href]:disabled, .btn-soft-danger[type].disabled, .btn-soft-danger[type]:disabled {
    color: #de4437;
    background-color: rgba(222, 68, 55, .1);
    box-shadow: none
}

.btn-soft-light {
    color: #f8f9fa;
    background: rgba(248, 249, 250, .1);
    border-color: transparent
}

.btn-soft-light[href].active, .btn-soft-light[href]:active, .btn-soft-light[href]:focus, .btn-soft-light[href]:hover, .btn-soft-light[type].active, .btn-soft-light[type]:active, .btn-soft-light[type]:focus, .btn-soft-light[type]:hover {
    color: #1e2022;
    background: #f8f9fa;
    box-shadow: 0 4px 11px rgba(248, 249, 250, .35)
}

.btn-soft-light[href].disabled, .btn-soft-light[href]:disabled, .btn-soft-light[type].disabled, .btn-soft-light[type]:disabled {
    color: #f8f9fa;
    background-color: rgba(248, 249, 250, .1);
    box-shadow: none
}

.btn-soft-dark {
    color: #1e2022;
    background: rgba(30, 32, 34, .1);
    border-color: transparent
}

.btn-soft-dark[href].active, .btn-soft-dark[href]:active, .btn-soft-dark[href]:focus, .btn-soft-dark[href]:hover, .btn-soft-dark[type].active, .btn-soft-dark[type]:active, .btn-soft-dark[type]:focus, .btn-soft-dark[type]:hover {
    color: #fff;
    background: #1e2022;
    box-shadow: 0 4px 11px rgba(30, 32, 34, .35)
}

.btn-soft-dark[href].disabled, .btn-soft-dark[href]:disabled, .btn-soft-dark[type].disabled, .btn-soft-dark[type]:disabled {
    color: #1e2022;
    background-color: rgba(30, 32, 34, .1);
    box-shadow: none
}

.btn-soft-white {
    color: #fff;
    background: hsla(0, 0%, 100%, .1);
    border-color: transparent
}

.btn-soft-white[href].active, .btn-soft-white[href]:active, .btn-soft-white[href]:focus, .btn-soft-white[href]:hover, .btn-soft-white[type].active, .btn-soft-white[type]:active, .btn-soft-white[type]:focus, .btn-soft-white[type]:hover {
    color: #1e2022;
    background: #fff;
    box-shadow: 0 4px 11px hsla(0, 0%, 100%, .35)
}

.btn-soft-white[href].disabled, .btn-soft-white[href]:disabled, .btn-soft-white[type].disabled, .btn-soft-white[type]:disabled {
    color: #fff;
    background-color: hsla(0, 0%, 100%, .1);
    box-shadow: none
}

.btn-soft-indigo {
    color: #2d1582;
    background: rgba(45, 21, 130, .1);
    border-color: transparent
}

.btn-soft-indigo[href].active, .btn-soft-indigo[href]:active, .btn-soft-indigo[href]:focus, .btn-soft-indigo[href]:hover, .btn-soft-indigo[type].active, .btn-soft-indigo[type]:active, .btn-soft-indigo[type]:focus, .btn-soft-indigo[type]:hover {
    color: #fff;
    background: #2d1582;
    box-shadow: 0 4px 11px rgba(45, 21, 130, .35)
}

.btn-soft-indigo[href].disabled, .btn-soft-indigo[href]:disabled, .btn-soft-indigo[type].disabled, .btn-soft-indigo[type]:disabled {
    color: #2d1582;
    background-color: rgba(45, 21, 130, .1);
    box-shadow: none
}

.btn-soft-facebook {
    color: #3b5998;
    background: rgba(59, 89, 152, .1);
    border-color: transparent
}

.btn-soft-facebook[href].active, .btn-soft-facebook[href]:active, .btn-soft-facebook[href]:focus, .btn-soft-facebook[href]:hover, .btn-soft-facebook[type].active, .btn-soft-facebook[type]:active, .btn-soft-facebook[type]:focus, .btn-soft-facebook[type]:hover {
    color: #fff;
    background: #3b5998;
    box-shadow: 0 4px 11px rgba(59, 89, 152, .35)
}

.btn-soft-facebook[href].disabled, .btn-soft-facebook[href]:disabled, .btn-soft-facebook[type].disabled, .btn-soft-facebook[type]:disabled {
    color: #3b5998;
    background-color: rgba(59, 89, 152, .1);
    box-shadow: none
}

.btn-soft-google {
    color: #d14130;
    background: rgba(209, 65, 48, .1);
    border-color: transparent
}

.btn-soft-google[href].active, .btn-soft-google[href]:active, .btn-soft-google[href]:focus, .btn-soft-google[href]:hover, .btn-soft-google[type].active, .btn-soft-google[type]:active, .btn-soft-google[type]:focus, .btn-soft-google[type]:hover {
    color: #fff;
    background: #d14130;
    box-shadow: 0 4px 11px rgba(209, 65, 48, .35)
}

.btn-soft-google[href].disabled, .btn-soft-google[href]:disabled, .btn-soft-google[type].disabled, .btn-soft-google[type]:disabled {
    color: #d14130;
    background-color: rgba(209, 65, 48, .1);
    box-shadow: none
}

.btn-soft-twitter {
    color: #1da1f2;
    background: rgba(29, 161, 242, .1);
    border-color: transparent
}

.btn-soft-twitter[href].active, .btn-soft-twitter[href]:active, .btn-soft-twitter[href]:focus, .btn-soft-twitter[href]:hover, .btn-soft-twitter[type].active, .btn-soft-twitter[type]:active, .btn-soft-twitter[type]:focus, .btn-soft-twitter[type]:hover {
    color: #fff;
    background: #1da1f2;
    box-shadow: 0 4px 11px rgba(29, 161, 242, .35)
}

.btn-soft-twitter[href].disabled, .btn-soft-twitter[href]:disabled, .btn-soft-twitter[type].disabled, .btn-soft-twitter[type]:disabled {
    color: #1da1f2;
    background-color: rgba(29, 161, 242, .1);
    box-shadow: none
}

.btn-soft-instagram {
    color: #3f729b;
    background: rgba(63, 114, 155, .1);
    border-color: transparent
}

.btn-soft-instagram[href].active, .btn-soft-instagram[href]:active, .btn-soft-instagram[href]:focus, .btn-soft-instagram[href]:hover, .btn-soft-instagram[type].active, .btn-soft-instagram[type]:active, .btn-soft-instagram[type]:focus, .btn-soft-instagram[type]:hover {
    color: #fff;
    background: #3f729b;
    box-shadow: 0 4px 11px rgba(63, 114, 155, .35)
}

.btn-soft-instagram[href].disabled, .btn-soft-instagram[href]:disabled, .btn-soft-instagram[type].disabled, .btn-soft-instagram[type]:disabled {
    color: #3f729b;
    background-color: rgba(63, 114, 155, .1);
    box-shadow: none
}

.btn-soft-github {
    color: #24292e;
    background: rgba(36, 41, 46, .1);
    border-color: transparent
}

.btn-soft-github[href].active, .btn-soft-github[href]:active, .btn-soft-github[href]:focus, .btn-soft-github[href]:hover, .btn-soft-github[type].active, .btn-soft-github[type]:active, .btn-soft-github[type]:focus, .btn-soft-github[type]:hover {
    color: #fff;
    background: #24292e;
    box-shadow: 0 4px 11px rgba(36, 41, 46, .35)
}

.btn-soft-github[href].disabled, .btn-soft-github[href]:disabled, .btn-soft-github[type].disabled, .btn-soft-github[type]:disabled {
    color: #24292e;
    background-color: rgba(36, 41, 46, .1);
    box-shadow: none
}

.btn-soft-gray {
    color: #f8fafd;
    background: rgba(248, 250, 253, .1);
    border-color: transparent
}

.btn-soft-gray[href].active, .btn-soft-gray[href]:active, .btn-soft-gray[href]:focus, .btn-soft-gray[href]:hover, .btn-soft-gray[type].active, .btn-soft-gray[type]:active, .btn-soft-gray[type]:focus, .btn-soft-gray[type]:hover {
    color: #1e2022;
    background: #f8fafd;
    box-shadow: 0 4px 11px rgba(248, 250, 253, .35)
}

.btn-soft-gray[href].disabled, .btn-soft-gray[href]:disabled, .btn-soft-gray[type].disabled, .btn-soft-gray[type]:disabled {
    color: #f8fafd;
    background-color: rgba(248, 250, 253, .1);
    box-shadow: none
}

.btn-wide {
    min-width: 7.87109rem
}

.btn-sm-wide {
    min-width: 6.85547rem
}

.btn-xs {
    font-size: .72rem;
    font-weight: 400;
    padding: .3rem .6rem
}

.btn-icon.btn-xs {
    font-size: .60938rem;
    width: 1.21875rem;
    height: 1.21875rem
}

.btn-group-sm > .btn-icon.btn, .btn-icon.btn-sm {
    font-size: .66422rem;
    width: 1.625rem;
    height: 1.625rem
}

.btn-group-lg > .btn-icon.btn, .btn-icon.btn-lg {
    font-size: 1.42188rem;
    width: 4.0625rem;
    height: 4.0625rem
}

.btn-text-primary {
    color: #3498db;
    background: 0 0
}

.btn-text-primary[href]:active, .btn-text-primary[href]:focus, .btn-text-primary[href]:hover, .btn-text-primary[type]:active, .btn-text-primary[type]:focus, .btn-text-primary[type]:hover {
    color: #1e2022
}

.btn-text-secondary {
    color: #77838f;
    background: 0 0
}

.btn-text-secondary[href]:active, .btn-text-secondary[href]:focus, .btn-text-secondary[href]:hover, .btn-text-secondary[type]:active, .btn-text-secondary[type]:focus, .btn-text-secondary[type]:hover {
    color: #1e2022
}

.btn-text-success {
    color: #00c9a7;
    background: 0 0
}

.btn-text-success[href]:active, .btn-text-success[href]:focus, .btn-text-success[href]:hover, .btn-text-success[type]:active, .btn-text-success[type]:focus, .btn-text-success[type]:hover {
    color: #1e2022
}

.btn-text-info {
    color: #3498db;
    background: 0 0
}

.btn-text-info[href]:active, .btn-text-info[href]:focus, .btn-text-info[href]:hover, .btn-text-info[type]:active, .btn-text-info[type]:focus, .btn-text-info[type]:hover {
    color: #1e2022
}

.btn-text-warning {
    color: #db9a04;
    background: 0 0
}

.btn-text-warning[href]:active, .btn-text-warning[href]:focus, .btn-text-warning[href]:hover, .btn-text-warning[type]:active, .btn-text-warning[type]:focus, .btn-text-warning[type]:hover {
    color: #1e2022
}

.btn-text-danger {
    color: #de4437;
    background: 0 0
}

.btn-text-danger[href]:active, .btn-text-danger[href]:focus, .btn-text-danger[href]:hover, .btn-text-danger[type]:active, .btn-text-danger[type]:focus, .btn-text-danger[type]:hover {
    color: #1e2022
}

.btn-text-light {
    color: #f8f9fa;
    background: 0 0
}

.btn-text-light[href]:active, .btn-text-light[href]:focus, .btn-text-light[href]:hover, .btn-text-light[type]:active, .btn-text-light[type]:focus, .btn-text-light[type]:hover {
    color: #bdc5d1
}

.btn-text-dark {
    color: #1e2022;
    background: 0 0
}

.btn-text-dark[href]:active, .btn-text-dark[href]:focus, .btn-text-dark[href]:hover, .btn-text-dark[type]:active, .btn-text-dark[type]:focus, .btn-text-dark[type]:hover {
    color: #1e2022
}

.btn-text-white {
    color: #fff;
    background: 0 0
}

.btn-text-white[href]:active, .btn-text-white[href]:focus, .btn-text-white[href]:hover, .btn-text-white[type]:active, .btn-text-white[type]:focus, .btn-text-white[type]:hover {
    color: #1e2022
}

.btn-text-indigo {
    color: #2d1582;
    background: 0 0
}

.btn-text-indigo[href]:active, .btn-text-indigo[href]:focus, .btn-text-indigo[href]:hover, .btn-text-indigo[type]:active, .btn-text-indigo[type]:focus, .btn-text-indigo[type]:hover {
    color: #1e2022
}

.btn[href] {
    cursor: pointer
}

.btn:not([href]):not([type]):not(:disabled):not(.disabled) {
    cursor: default
}

.btn-pill {
    border-radius: 6.1875rem
}

.btn-icon {
    position: relative;
    line-height: 0;
    font-size: .8125rem;
    width: 2.53906rem;
    height: 2.53906rem;
    padding: 0
}

.btn-icon__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.btn-icon__inner-bottom-minus {
    top: 80%
}

[class*=btn-soft-].btn-bg-transparent {
    background-color: transparent
}

.btn__toggle-default {
    display: inline-block
}

.btn.toggled .btn__toggle-default, .btn__toggle-toggled {
    display: none
}

.btn.toggled .btn__toggle-toggled {
    display: inline-block
}

.brand {
    font-size: 2rem;
    font-weight: 600;
    margin-left: .5rem
}

.brand-primary, .brand-primary:focus, .brand-primary:hover {
    color: #3498db
}

.brand-light, .brand-light:focus, .brand-light:hover {
    color: #fff
}

.breadcrumb-white {
    background-color: transparent
}

.breadcrumb-white .breadcrumb-item {
    color: #fff
}

.breadcrumb-white .breadcrumb-item + .breadcrumb-item:before, .breadcrumb-white .breadcrumb-link {
    color: hsla(0, 0%, 100%, .7)
}

.breadcrumb-no-gutter {
    padding: 0
}

.card-2-count {
    column-count: 2
}

.card-3-count {
    column-count: 3
}

.card-4-count {
    column-count: 4
}

.card-columns {
    column-gap: 1rem;
    orphans: 1;
    widows: 1
}

.card-columns .card {
    display: inline-block;
    width: 100%
}

@media (min-width: 576px) {
    .card-sm-2-count {
        column-count: 2
    }

    .card-sm-3-count {
        column-count: 3
    }

    .card-sm-4-count {
        column-count: 4
    }

    .card-sm-columns {
        column-gap: 1rem;
        orphans: 1;
        widows: 1
    }

    .card-sm-columns .card {
        display: inline-block;
        width: 100%
    }
}

@media (min-width: 768px) {
    .card-md-2-count {
        column-count: 2
    }

    .card-md-3-count {
        column-count: 3
    }

    .card-md-4-count {
        column-count: 4
    }

    .card-md-columns {
        column-gap: 1rem;
        orphans: 1;
        widows: 1
    }

    .card-md-columns .card {
        display: inline-block;
        width: 100%
    }
}

@media (min-width: 992px) {
    .card-lg-2-count {
        column-count: 2
    }

    .card-lg-3-count {
        column-count: 3
    }

    .card-lg-4-count {
        column-count: 4
    }

    .card-lg-columns {
        column-gap: 1rem;
        orphans: 1;
        widows: 1
    }

    .card-lg-columns .card {
        display: inline-block;
        width: 100%
    }
}

@media (min-width: 1200px) {
    .card-xl-2-count {
        column-count: 2
    }

    .card-xl-3-count {
        column-count: 3
    }

    .card-xl-4-count {
        column-count: 4
    }

    .card-xl-columns {
        column-gap: 1rem;
        orphans: 1;
        widows: 1
    }

    .card-xl-columns .card {
        display: inline-block;
        width: 100%
    }
}

.card-bg-light {
    background-color: #f8fafd
}

.card-bg-light:hover {
    background-color: #f4f7fc
}

.card-frame {
    border: 1px solid #e7eaf3;
    transition: .3s
}

.card-frame-highlighted, .card-frame:hover {
    border-color: rgba(52, 152, 219, .3);
    box-shadow: 0 0 35px rgba(52, 152, 219, .125)
}

.card-text-dark {
    color: #1e2022
}

.card-text-dark:hover {
    color: #3498db
}

.card-collapse {
    padding: 0;
    transition: .3s ease-in-out
}

.card-collapse:hover {
    background-color: #f8fafd
}

.card-collapse .card-body {
    color: #6c757e
}

.card-btn {
    color: #1e2022;
    text-align: left;
    white-space: inherit
}

.card-btn-arrow {
    display: inline-block;
    color: #3498db;
    margin-left: 1rem;
    transition: .3s ease-in-out
}

.collapsed .card-btn-arrow {
    transform: rotate(-90deg)
}

@media (min-width: 576px) {
    .card-sm-gutters-1 {
        margin-right: -.25rem;
        margin-left: -.25rem
    }

    .card-sm-gutters-1 .card {
        margin-right: .25rem;
        margin-left: .25rem
    }

    .card-sm-gutters-2 {
        margin-right: -.5rem;
        margin-left: -.5rem
    }

    .card-sm-gutters-2 .card {
        margin-right: .5rem;
        margin-left: .5rem
    }

    .card-sm-gutters-3 {
        margin-right: -.9375rem;
        margin-left: -.9375rem
    }

    .card-sm-gutters-3 .card {
        margin-right: .9375rem;
        margin-left: .9375rem
    }
}

@media (min-width: 768px) {
    .card-md-gutters-1 {
        margin-right: -.25rem;
        margin-left: -.25rem
    }

    .card-md-gutters-1 .card {
        margin-right: .25rem;
        margin-left: .25rem
    }

    .card-md-gutters-2 {
        margin-right: -.5rem;
        margin-left: -.5rem
    }

    .card-md-gutters-2 .card {
        margin-right: .5rem;
        margin-left: .5rem
    }

    .card-md-gutters-3 {
        margin-right: -.9375rem;
        margin-left: -.9375rem
    }

    .card-md-gutters-3 .card {
        margin-right: .9375rem;
        margin-left: .9375rem
    }
}

@media (min-width: 992px) {
    .card-lg-gutters-1 {
        margin-right: -.25rem;
        margin-left: -.25rem
    }

    .card-lg-gutters-1 .card {
        margin-right: .25rem;
        margin-left: .25rem
    }

    .card-lg-gutters-2 {
        margin-right: -.5rem;
        margin-left: -.5rem
    }

    .card-lg-gutters-2 .card {
        margin-right: .5rem;
        margin-left: .5rem
    }

    .card-lg-gutters-3 {
        margin-right: -.9375rem;
        margin-left: -.9375rem
    }

    .card-lg-gutters-3 .card {
        margin-right: .9375rem;
        margin-left: .9375rem
    }
}

@media (max-width: 767.98px) {
    .card-group-sm-break {
        display: block
    }

    .card-group-sm-break > .card:not(:last-child) {
        margin-bottom: -1px
    }

    .card-group-sm-break > .card + .card {
        border-left: 1px solid #e7eaf3
    }

    .card-group-sm-break > .card:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group-sm-break > .card:first-child, .card-group-sm-break > .card:first-child .card-header, .card-group-sm-break > .card:first-child .card-img-top {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem
    }

    .card-group-sm-break > .card:first-child .card-footer, .card-group-sm-break > .card:first-child .card-img-bottom {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group-sm-break > .card:last-child {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }

    .card-group-sm-break > .card:last-child, .card-group-sm-break > .card:last-child .card-header, .card-group-sm-break > .card:last-child .card-img-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0
    }

    .card-group-sm-break > .card:last-child .card-footer, .card-group-sm-break > .card:last-child .card-img-bottom {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }
}

@media (max-width: 991.98px) {
    .card-group-md-break {
        display: block
    }

    .card-group-md-break > .card:not(:last-child) {
        margin-bottom: -1px
    }

    .card-group-md-break > .card + .card {
        border-left: 1px solid #e7eaf3
    }

    .card-group-md-break > .card:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group-md-break > .card:first-child, .card-group-md-break > .card:first-child .card-header, .card-group-md-break > .card:first-child .card-img-top {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem
    }

    .card-group-md-break > .card:first-child .card-footer, .card-group-md-break > .card:first-child .card-img-bottom {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group-md-break > .card:last-child {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }

    .card-group-md-break > .card:last-child, .card-group-md-break > .card:last-child .card-header, .card-group-md-break > .card:last-child .card-img-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0
    }

    .card-group-md-break > .card:last-child .card-footer, .card-group-md-break > .card:last-child .card-img-bottom {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }
}

@media (max-width: 1199.98px) {
    .card-group-lg-break {
        display: block
    }

    .card-group-lg-break > .card:not(:last-child) {
        margin-bottom: -1px
    }

    .card-group-lg-break > .card + .card {
        border-left: 1px solid #e7eaf3
    }

    .card-group-lg-break > .card:first-child {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group-lg-break > .card:first-child, .card-group-lg-break > .card:first-child .card-header, .card-group-lg-break > .card:first-child .card-img-top {
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem
    }

    .card-group-lg-break > .card:first-child .card-footer, .card-group-lg-break > .card:first-child .card-img-bottom {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }

    .card-group-lg-break > .card:last-child {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }

    .card-group-lg-break > .card:last-child, .card-group-lg-break > .card:last-child .card-header, .card-group-lg-break > .card:last-child .card-img-top {
        border-top-left-radius: 0;
        border-top-right-radius: 0
    }

    .card-group-lg-break > .card:last-child .card-footer, .card-group-lg-break > .card:last-child .card-img-bottom {
        border-bottom-right-radius: .25rem;
        border-bottom-left-radius: .25rem
    }
}

.u-sidebar[aria-labelledby] {
    opacity: 0
}

.u-sidebar[aria-labelledby].u-unfold--css-animation, .u-sidebar[aria-labelledby].u-unfold--jquery-slide, .u-sidebar[aria-labelledby][role=tabpanel] {
    opacity: 1
}

.u-sidebar.u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden
}

.u-sidebar {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1002;
    width: 23.4375rem;
    background-color: #fff;
    box-shadow: -13px 0 20px hsla(210, 8%, 46%, .08)
}

.u-sidebar, .u-sidebar__body, .u-sidebar__content {
    height: 100%
}

.u-sidebar--left {
    right: auto;
    left: 0;
    box-shadow: 13px 0 20px hsla(210, 8%, 46%, .08)
}

.u-sidebar__scroller {
    height: 100%;
    overflow: hidden;
    overflow-y: auto
}

.u-sidebar__container {
    position: relative;
    height: 100%;
    min-height: 100%
}

.u-sidebar__footer {
    width: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0
}

.u-sidebar--account__footer-offset {
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 10.3125rem
}

.u-sidebar__content--account {
    padding: 1.5rem 3rem
}

.u-sidebar--account__holder {
    background-color: #f8fafd;
    padding: 1.5rem 3rem
}

.u-sidebar--account__holder-img {
    width: 3rem;
    border-radius: .25rem
}

.u-sidebar--account__holder-text {
    display: block;
    font-size: .75rem;
    color: #8c98a4
}

.u-sidebar--account__toggle-bg {
    position: relative;
    top: 1px;
    padding: .25rem .25rem .25rem .75rem;
    background-color: rgba(52, 152, 219, .1);
    border-radius: 6.1875rem
}

@media (max-width: 767.98px) {
    .u-sidebar--account__toggle-bg {
        padding-left: .25rem
    }
}

.u-sidebar--account__toggle-bg:hover .u-sidebar--account__toggle-text {
    color: #3498db
}

.u-sidebar--account__toggle-text {
    color: #1e2022;
    font-size: .75rem;
    vertical-align: middle;
    margin-right: .3125rem
}

@media (max-width: 767.98px) {
    .u-sidebar--account__toggle-text {
        display: none
    }
}

.u-sidebar--account__toggle-img {
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%
}

.u-sidebar--account__list {
    margin-bottom: 0
}

.u-sidebar--account__list-item {
    padding-top: .25rem;
    padding-bottom: .25rem
}

.u-sidebar--account__list-link {
    display: block;
    font-size: .875rem;
    color: #97a4af;
    border-radius: .25rem;
    padding: .625rem;
    transition: .2s ease-in-out
}

.u-sidebar--account__list-link.active, .u-sidebar--account__list-link:hover {
    color: #3498db;
    background-color: rgba(52, 152, 219, .1)
}

.u-sidebar--account__list-link.active .u-sidebar--account__list-icon, .u-sidebar--account__list-link:hover .u-sidebar--account__list-icon {
    color: #3498db
}

.u-sidebar--account__list-icon {
    display: inline-block;
    text-align: center;
    width: 1.125rem;
    font-size: .8125rem;
    color: #8c98a4;
    transition: .2s ease-in-out
}

.u-sidebar--account__list-divider {
    border-top: 1px solid #e4ecf7;
    padding: 1rem 1.5rem
}

.u-sidebar__footer--account {
    padding: 0 3rem 1.5rem
}

.u-sidebar__footer--account__text {
    position: relative;
    z-index: 1;
    font-size: .875rem;
    color: hsla(0, 0%, 100%, .7)
}

.u-sidebar__footer--account__text:hover {
    color: #fff
}

.u-sidebar__cart-footer-offset {
    height: 100%;
    box-sizing: border-box;
    padding-bottom: 13.4375rem
}

.u-clients {
    width: 100%;
    max-width: 6rem;
    height: auto;
    margin-left: auto;
    margin-right: auto
}

.close-light {
    color: hsla(0, 0%, 100%, .7)
}

.close-light:not(:disabled):not(.disabled):focus, .close-light:not(:disabled):not(.disabled):hover {
    color: #fff
}

.u-divider {
    position: relative;
    display: inline-block
}

.u-divider:after, .u-divider:before {
    position: absolute;
    top: 50%;
    content: ""
}

.u-divider:before {
    right: 100%;
    background-image: linear-gradient(45deg, transparent, #bdc5d1);
    background-repeat: repeat-x
}

.u-divider:after {
    left: 100%;
    background-image: linear-gradient(45deg, #bdc5d1, transparent);
    background-repeat: repeat-x
}

.u-divider--xs {
    font-size: .60938rem
}

.u-divider--xs:after, .u-divider--xs:before {
    height: .05078rem;
    transform: translateY(-50%)
}

.u-divider--xs:before {
    margin-right: .625rem
}

.u-divider--xs:after {
    margin-left: .625rem
}

.u-divider--xs:after, .u-divider--xs:before {
    width: 1.625rem
}

.u-divider--text {
    color: #8c98a4
}

.u-ver-divider {
    position: relative
}

@media (max-width: 575.98px) {
    .u-ver-divider--none-sm:after {
        display: none
    }
}

@media (max-width: 767.98px) {
    .u-ver-divider--none-md:after {
        display: none
    }
}

@media (max-width: 991.98px) {
    .u-ver-divider--none-lg:after {
        display: none
    }
}

.u-ver-divider--xs:after {
    top: 50%;
    height: .8125rem;
    transform: translateY(-50%)
}

.u-ver-divider--sm:after {
    top: 50%;
    height: 1.625rem;
    transform: translateY(-50%)
}

.u-ver-divider--lg:after {
    top: 50%;
    height: 2.4375rem;
    transform: translateY(-50%)
}

.u-ver-divider--xl:after {
    top: 50%;
    height: 3.25rem;
    transform: translateY(-50%)
}

.dropdown-unfold[aria-labelledby] {
    opacity: 0
}

.dropdown-unfold[aria-labelledby].u-unfold--css-animation, .dropdown-unfold[aria-labelledby].u-unfold--jquery-slide, .dropdown-unfold[aria-labelledby][role=tabpanel] {
    display: block;
    opacity: 1
}

.dropdown-unfold.u-unfold--css-animation.u-unfold--hidden, .u-unfold--css-animation.u-unfold--hidden {
    display: none;
    opacity: 0;
    visibility: hidden
}

.dropdown-unfold.show {
    display: block;
    opacity: 1
}

.dropdown-menu {
    margin-top: .5rem;
    font-size: .71094rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 2px 7px rgba(52, 152, 219, .05), 0 0 10px hsla(210, 8%, 46%, .1)
}

.dropdown-nav-link {
    color: #6c757e;
    font-weight: 300;
    font-size: .71094rem
}

.dropdown-nav-link:hover {
    color: #1e2022
}

.dropdown-item {
    font-size: .71094rem
}

.dropdown-item.active, .dropdown-item:hover {
    color: #3498db
}

.dropdown-item-icon {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    font-size: .66016rem;
    min-width: .8125rem;
    max-width: .8125rem;
    margin-right: .5rem
}

.dropdown-toggle:after {
    display: inline-block;
    font-family: Font Awesome\ 5 Pro;
    font-size: 80%;
    font-weight: 900;
    content: "\f107";
    margin-left: .5rem
}

.dropdown-menu-right {
    right: 0;
    left: auto !important
}

.dropdown-menu-bottom {
    top: auto;
    bottom: 0
}

@media (min-width: 576px) {
    .dropdown-menu-sm-right {
        right: 0;
        left: auto !important
    }
}

@media (min-width: 768px) {
    .dropdown-menu-md-right {
        right: 0;
        left: auto !important
    }
}

.dropdown-card {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 2rem
}

@media (min-width: 768px) {
    .dropdown-card {
        min-width: 12rem
    }
}

.custom-select:focus, .form-control:focus {
    box-shadow: 0 0 25px rgba(52, 152, 219, .1);
    border-color: rgba(52, 152, 219, .5)
}

.form-label {
    display: block;
    text-transform: uppercase;
    font-size: 80%;
    font-weight: 500
}

.input-group-borderless .form-control, .input-group-borderless .input-group-text {
    border: none
}

.input-group-borderless .form-control:focus, .input-group-borderless .input-group-text:focus {
    box-shadow: none
}

.input-group-pill {
    border-radius: 6.1875rem
}

.input-group-pill > .form-control:first-child {
    border-top-left-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem
}

.input-group-pill > .form-control:last-child {
    border-top-right-radius: 6.1875rem;
    border-bottom-right-radius: 6.1875rem
}

.input-group-pill .input-group-prepend > .btn, .input-group-pill .input-group-prepend > .input-group-text {
    border-top-left-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem
}

.input-group-pill .input-group-append > .btn, .input-group-pill .input-group-append > .input-group-text {
    border-top-right-radius: 6.1875rem;
    border-bottom-right-radius: 6.1875rem
}

.input-group.input-group-pill > .form-control:first-child {
    padding-left: 1.5rem
}

.input-group.input-group-pill .input-group-prepend > .btn, .input-group.input-group-pill .input-group-prepend > .input-group-text {
    padding-left: 1.25rem
}

.input-group.input-group-pill .input-group-append > .btn, .input-group.input-group-pill .input-group-append > .input-group-text {
    padding-right: 1.25rem
}

.input-group .input-group-append > .input-group-text, .input-group .input-group-prepend > .input-group-text {
    font-size: .71094rem
}

.input-group-xs.input-group-pill > .form-control:first-child {
    padding-left: 1.375rem
}

.input-group-xs.input-group-pill .input-group-prepend > .btn, .input-group-xs.input-group-pill .input-group-prepend > .input-group-text {
    padding-left: 1.25rem
}

.input-group-xs.input-group-pill .input-group-append > .btn, .input-group-xs.input-group-pill .input-group-append > .input-group-text {
    padding-right: 1.25rem
}

.input-group-xs .input-group-append > .input-group-text, .input-group-xs .input-group-prepend > .input-group-text {
    font-size: .50781rem
}

.form-control-xs {
    height: calc(1.45313rem + 1px);
    font-size: .60938rem;
    line-height: 1.4
}

.input-group-sm.input-group-pill .input-group-prepend > .btn, .input-group-sm.input-group-pill .input-group-prepend > .input-group-text, .input-group-sm.input-group-pill > .form-control:first-child {
    padding-left: 1rem
}

.input-group-sm.input-group-pill .input-group-append > .btn, .input-group-sm.input-group-pill .input-group-append > .input-group-text {
    padding-right: 1rem
}

.input-group-sm .input-group-append > .input-group-text, .input-group-sm .input-group-prepend > .input-group-text {
    font-size: .60938rem
}

.input-group-lg.input-group-pill > .form-control:first-child {
    padding-left: 2rem
}

.input-group-lg.input-group-pill .input-group-prepend > .btn, .input-group-lg.input-group-pill .input-group-prepend > .input-group-text {
    padding-left: 1.375rem
}

.input-group-lg.input-group-pill .input-group-append > .btn, .input-group-lg.input-group-pill .input-group-append > .input-group-text {
    padding-right: 1.5rem
}

.input-group-lg .input-group-append > .input-group-text, .input-group-lg .input-group-prepend > .input-group-text {
    font-size: .8125rem
}

.input-group-lg .form-control {
    font-size: .91406rem
}

.u-focus-state .custom-select, .u-focus-state .form-control:first-child:last-child, .u-focus-state .form-label + .form-control, .u-focus-state .input-group {
    box-shadow: 0 0 25px rgba(52, 152, 219, .1)
}

.u-focus-state .input-group-text {
    color: #3498db
}

.u-focus-state .custom-select, .u-focus-state .form-control, .u-focus-state .input-group-text, .u-focus-state :focus, .u-focus-state:focus {
    border-color: rgba(52, 152, 219, .5)
}

.u-focus-state .form-control {
    box-shadow: none
}

.u-has-success .custom-select, .u-has-success .form-control:first-child:last-child, .u-has-success .form-label + .form-control, .u-has-success .input-group {
    box-shadow: 0 0 25px rgba(0, 201, 167, .1)
}

.u-has-success .input-group-text {
    color: #00c9a7
}

.u-has-success .custom-select, .u-has-success .form-control, .u-has-success .input-group-text, .u-has-success :focus, .u-has-success:focus {
    border-color: rgba(0, 201, 167, .5)
}

.u-has-error .custom-select, .u-has-error .form-control:first-child:last-child, .u-has-error .form-label + .form-control, .u-has-error .input-group {
    box-shadow: 0 0 25px rgba(222, 68, 55, .1)
}

.u-has-error .input-group-text {
    color: #de4437
}

.u-has-error .custom-select, .u-has-error .form-control, .u-has-error .input-group-text, .u-has-error :focus, .u-has-error:focus {
    border-color: rgba(222, 68, 55, .5)
}

.checkbox-outline {
    padding-left: 0;
    margin-right: 0
}

.checkbox-outline__label {
    border: 1px solid #e7eaf3;
    cursor: pointer
}

.checkbox-outline__input:checked ~ .checkbox-outline__label {
    border-color: #3498db
}

.checkbox-switch {
    position: relative;
    display: inline-block;
    width: 2.2rem;
    height: 1.2rem
}

.checkbox-switch__input {
    position: absolute;
    z-index: -1;
    opacity: 0
}

.checkbox-switch__slider {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #e7eaf3;
    border-radius: 6.1875rem;
    padding: .25rem;
    transition: .3s
}

.checkbox-switch__slider:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: .75rem;
    height: .75rem;
    background-color: #fff;
    border-radius: 50%;
    pointer-events: none;
    content: "";
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: .4s
}

.checkbox-switch__input:checked + .checkbox-switch__slider {
    background-color: #3498db
}

.checkbox-switch__input:checked + .checkbox-switch__slider:before {
    left: calc(100% - 20px);
    transform: translate(4px, -50%)
}

.u-range-slider, .u-range-slider .irs {
    height: 1.25rem
}

.u-range-slider .irs-line {
    top: .5rem;
    height: .25rem
}

.u-range-slider .irs-line-left {
    left: 0;
    border-top-left-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem
}

.u-range-slider .irs-line-left, .u-range-slider .irs-line-mid, .u-range-slider .irs-line-right {
    height: .25rem;
    background-color: #e7eaf3
}

.u-range-slider .irs-line-right {
    right: 0;
    border-top-right-radius: 6.1875rem;
    border-bottom-right-radius: 6.1875rem
}

.u-range-slider .irs-bar, .u-range-slider .irs-bar-edge {
    top: .5rem;
    height: .25rem;
    background-color: #3498db
}

.u-range-slider .irs-bar-edge {
    left: .0625rem;
    width: .6875rem;
    border-top-left-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem
}

.u-range-slider .irs-slider {
    top: -.1875rem;
    width: 1.5rem;
    height: 1.5rem;
    background-color: #fff;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0 .1875rem .75rem rgba(140, 152, 164, .3125)
}

.u-range-slider .irs-slider.state_hover {
    transform: scale(1.3)
}

.u-range-slider .irs-from, .u-range-slider .irs-single, .u-range-slider .irs-to {
    display: inline-block;
    min-width: 2.5rem;
    background-color: #fff;
    color: #1e2022;
    font-size: .875rem;
    text-shadow: none;
    text-align: center;
    border-radius: .25rem;
    box-shadow: 0 0 35px rgba(140, 152, 164, .125);
    padding: .5rem
}

.u-range-slider-indicator, .u-range-slider-indicator .irs {
    height: 5rem
}

.u-range-slider-indicator.u-range-slider-grid, .u-range-slider-indicator.u-range-slider-grid .irs {
    height: 8rem
}

.u-range-slider-indicator .irs-bar, .u-range-slider-indicator .irs-bar-edge, .u-range-slider-indicator .irs-line {
    top: 3.875rem
}

.u-range-slider-indicator .irs-slider {
    top: 3.1875rem
}

.u-range-slider-grid, .u-range-slider-grid .irs {
    height: 4.5rem
}

.u-range-slider-grid .irs-grid {
    height: 2.5rem
}

.u-range-slider-grid .irs-grid-text {
    display: inline-block;
    min-width: 2.5rem;
    line-height: 1;
    font-size: .875rem;
    color: #6c757e;
    border-radius: 6.1875rem;
    padding: .25rem
}

.u-range-slider-grid .irs-grid-text.current {
    background: rgba(52, 152, 219, .1);
    color: #3498db
}

.u-range-slider-grid .irs-grid-pol {
    height: .75rem;
    background-color: #e7eaf3
}

.u-range-slider-grid .irs-grid-pol.small {
    display: none
}

.file-attachment-btn {
    position: relative;
    overflow: hidden;
    margin-bottom: 0
}

.file-attachment-btn__label {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    cursor: inherit;
    opacity: 0
}

.file-attachment-input {
    position: relative;
    display: block;
    background-color: #f8fafd;
    border: 2px dashed #e7eaf3;
    text-align: center;
    overflow: hidden;
    cursor: pointer;
    border-radius: .25rem;
    padding: 4rem;
    margin-bottom: 0
}

.file-attachment-input__label {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    min-width: 100%;
    min-height: 100%;
    cursor: inherit;
    opacity: 0
}

.file-attachment-input:hover {
    background-color: #f5f8fc
}

.file-attachment-link {
    position: relative;
    top: .4375rem;
    overflow: hidden
}

.file-attachment-link input[type=file] {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    opacity: 0
}

.file-attachment-link__label {
    color: hsla(0, 0%, 100%, .7);
    border-bottom: 1px dashed hsla(0, 0%, 100%, .7);
    margin-bottom: 0
}

.file-attachment-link:hover .u-file-attachment-link__label {
    color: #fff;
    border-color: hsla(0, 0%, 100%, .7)
}

.u-go-to {
    display: none;
    position: relative;
    z-index: 100;
    width: 2.5rem;
    height: 2.5rem;
    background-color: #3498db;
    color: #fff;
    font-size: .60938rem;
    border-radius: .25rem;
    opacity: .5;
    transition: .3s ease-out
}

.u-go-to__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.u-go-to:focus:hover, .u-go-to:hover {
    color: #fff;
    opacity: 1
}

.u-go-to-ver-arrow {
    display: inline-block;
    color: #3498db;
    background-color: rgba(52, 152, 219, .1);
    padding: 2rem 1rem;
    border-radius: 6.1875rem
}

.u-go-to-ver-arrow:hover .u-go-to-ver-arrow__inner {
    transform: translateY(3px)
}

.u-go-to-ver-arrow__inner {
    transition: .2s ease-in-out
}

.u-go-to-modern {
    position: relative;
    z-index: 2;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    width: 3.5rem;
    height: 3.5rem;
    font-size: .91406rem;
    color: #3498db;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 0 0 1.25rem rgba(140, 152, 164, .2)
}

.u-go-to-modern .u-go-to-modern__inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.u-go-to-wave {
    width: 13.125rem;
    margin-left: auto;
    margin-right: auto
}

@media (max-width: 575.98px) {
    .u-go-to-wave {
        width: 10rem
    }
}

.u-go-to-wave__icon {
    transform: translateY(-50%)
}

.u-hamburger {
    font-size: inherit;
    line-height: 0;
    padding: .25rem
}

.u-hamburger:hover .u-hamburger__inner, .u-hamburger:hover .u-hamburger__inner:after, .u-hamburger:hover .u-hamburger__inner:before {
    background-color: #3498db
}

.u-hamburger__box {
    width: 1.26953rem;
    height: .875rem;
    display: inline-block;
    position: relative
}

.u-hamburger__inner {
    display: block;
    top: .0625rem;
    margin: top, -.0625rem
}

.u-hamburger__inner, .u-hamburger__inner:after, .u-hamburger__inner:before {
    width: 1.26953rem;
    height: .125rem;
    background-color: #8c98a4;
    position: absolute;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease
}

.u-hamburger__inner:after, .u-hamburger__inner:before {
    content: "";
    display: block
}

.u-hamburger__inner:before {
    top: .375rem;
    transition-property: transform, opacity;
    transition-duration: .3s;
    transition-timing-function: ease
}

.u-hamburger__inner:after {
    top: .75rem
}

.u-hamburger .is-active .u-hamburger__inner, .u-hamburger[aria-expanded=true] .u-hamburger__inner {
    transform: translate3d(0, .375rem, 0) rotate(45deg)
}

.u-hamburger .is-active .u-hamburger__inner:before, .u-hamburger[aria-expanded=true] .u-hamburger__inner:before {
    transform: rotate(-45deg) translate3d(-.18136rem, -.25rem, 0);
    opacity: 0
}

.u-hamburger .is-active .u-hamburger__inner:after, .u-hamburger[aria-expanded=true] .u-hamburger__inner:after {
    transform: translate3d(0, -.75rem, 0) rotate(-90deg)
}

.u-hamburger--white .u-hamburger__inner, .u-hamburger--white .u-hamburger__inner:after, .u-hamburger--white .u-hamburger__inner:before {
    background-color: hsla(0, 0%, 100%, .8)
}

.u-hamburger--white:hover .u-hamburger__inner, .u-hamburger--white:hover .u-hamburger__inner:after, .u-hamburger--white:hover .u-hamburger__inner:before {
    background-color: #fff
}

.js-header-fix-moment .u-hamburger--white .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner:after, .js-header-fix-moment .u-hamburger--white .u-hamburger__inner:before {
    background-color: #8c98a4
}

.js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner:after, .js-header-fix-moment .u-hamburger--white:hover .u-hamburger__inner:before {
    background-color: #3498db
}

.u-indicator-dots {
    position: relative
}

@media (min-width: 768px) {
    .u-indicator-dots:after {
        position: absolute;
        right: -2.1875rem;
        top: 50%;
        width: 2.4375rem;
        height: .75rem;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 16'%3E %3Ccircle fill='%23e7eaf3' cx='27.7' cy='8.1' r='7.7'/%3E %3Ccircle fill='%23e7eaf3' cx='5' cy='8.1' r='5'/%3E %3Ccircle fill='%23e7eaf3' cx='50' cy='8.1' r='5'/%3E %3C/svg%3E");
        background-repeat: no-repeat;
        content: "";
        margin-top: -.375rem
    }
}

.u-indicator-vertical-dashed-item:not(:last-child) {
    position: relative;
    margin-bottom: 1rem
}

.u-indicator-vertical-dashed-item:not(:last-child):after {
    position: absolute;
    top: 1.625rem;
    bottom: -.8125rem;
    left: .625rem;
    border-left: 2px dashed #e7eaf3;
    content: ""
}

.u-indicator-steps {
    position: relative;
    padding-left: 2.5rem
}

.u-indicator-steps:before {
    left: -.25rem;
    width: 2rem;
    height: .0625rem;
    border-top: 1px solid #e7eaf3
}

.u-indicator-steps:after, .u-indicator-steps:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto auto auto 0;
    content: ""
}

.u-indicator-steps:after {
    left: -.3125rem;
    width: .0625rem;
    height: 100%;
    border-right: 1px solid #e7eaf3
}

.u-indicator-steps:first-child:after {
    height: 50%;
    top: auto
}

.u-indicator-steps:last-child:after {
    height: 50%;
    bottom: auto
}

.u-indicator-steps__inner {
    min-width: 3rem
}

.u-label {
    display: inline-block;
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1.3;
    padding: .625rem 1.125rem;
    border-radius: 6.1875rem;
    transition: .2s ease-in-out
}

.u-label--rounded {
    border-radius: .25rem
}

.u-label--xs {
    font-weight: 300;
    font-size: .60938rem;
    line-height: 1.7;
    padding: .2rem .5rem
}

.u-label--sm {
    font-size: .71094rem;
    padding: .4rem .7rem
}

.u-label--primary {
    color: #3498db;
    background-color: rgba(52, 152, 219, .1)
}

.u-label--primary[href]:focus, .u-label--primary[href]:hover {
    color: #fff;
    background-color: #3498db
}

.u-label--primary[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--primary[type=button]:focus, .u-label--primary[type=button]:hover {
    color: #fff;
    background-color: #3498db
}

.u-label--secondary {
    color: #77838f;
    background-color: rgba(119, 131, 143, .1)
}

.u-label--secondary[href]:focus, .u-label--secondary[href]:hover {
    color: #fff;
    background-color: #77838f
}

.u-label--secondary[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--secondary[type=button]:focus, .u-label--secondary[type=button]:hover {
    color: #fff;
    background-color: #77838f
}

.u-label--success {
    color: #00c9a7;
    background-color: rgba(0, 201, 167, .1)
}

.u-label--success[href]:focus, .u-label--success[href]:hover {
    color: #fff;
    background-color: #00c9a7
}

.u-label--success[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--success[type=button]:focus, .u-label--success[type=button]:hover {
    color: #fff;
    background-color: #00c9a7
}

.u-label--info {
    color: #3498db;
    background-color: rgba(52, 152, 219, .1)
}

.u-label--info[href]:focus, .u-label--info[href]:hover {
    color: #fff;
    background-color: #3498db
}

.u-label--info[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--info[type=button]:focus, .u-label--info[type=button]:hover {
    color: #fff;
    background-color: #3498db
}

.u-label--warning {
    color: #db9a04;
    background-color: rgba(219, 154, 4, .1)
}

.u-label--warning[href]:focus, .u-label--warning[href]:hover {
    color: #1e2022;
    background-color: #db9a04
}

.u-label--warning[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--warning[type=button]:focus, .u-label--warning[type=button]:hover {
    color: #1e2022;
    background-color: #db9a04
}

.u-label--danger {
    color: #de4437;
    background-color: rgba(222, 68, 55, .1)
}

.u-label--danger[href]:focus, .u-label--danger[href]:hover {
    color: #fff;
    background-color: #de4437
}

.u-label--danger[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--danger[type=button]:focus, .u-label--danger[type=button]:hover {
    color: #fff;
    background-color: #de4437
}

.u-label--light {
    color: #f8f9fa;
    background-color: rgba(248, 249, 250, .1)
}

.u-label--light[href]:focus, .u-label--light[href]:hover {
    color: #1e2022;
    background-color: #f8f9fa
}

.u-label--light[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--light[type=button]:focus, .u-label--light[type=button]:hover {
    color: #1e2022;
    background-color: #f8f9fa
}

.u-label--dark {
    color: #1e2022;
    background-color: rgba(30, 32, 34, .1)
}

.u-label--dark[href]:focus, .u-label--dark[href]:hover {
    color: #fff;
    background-color: #1e2022
}

.u-label--dark[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--dark[type=button]:focus, .u-label--dark[type=button]:hover {
    color: #fff;
    background-color: #1e2022
}

.u-label--white {
    color: #fff;
    background-color: hsla(0, 0%, 100%, .1)
}

.u-label--white[href]:focus, .u-label--white[href]:hover {
    color: #1e2022;
    background-color: #fff
}

.u-label--white[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--white[type=button]:focus, .u-label--white[type=button]:hover {
    color: #1e2022;
    background-color: #fff
}

.u-label--indigo {
    color: #2d1582;
    background-color: rgba(45, 21, 130, .1)
}

.u-label--indigo[href]:focus, .u-label--indigo[href]:hover {
    color: #fff;
    background-color: #2d1582
}

.u-label--indigo[type=button] {
    border: 0;
    cursor: pointer
}

.u-label--indigo[type=button]:focus, .u-label--indigo[type=button]:hover {
    color: #fff;
    background-color: #2d1582
}

.u-modal-window {
    display: none;
    max-height: 85vh
}

.page-link {
    text-align: center;
    min-width: 2.25rem
}

.page-item {
    margin-left: .15rem;
    margin-right: .15rem
}

.page-item .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.nav-classic {
    border-bottom: 1px solid #e7eaf3
}

.nav-classic .nav-link {
    color: #6c757e;
    border-bottom: 3px solid transparent;
    border-radius: 0;
    padding: 1rem 1.5rem;
    margin-bottom: -.125rem;
    transition: .3s
}

.nav-classic .nav-link:hover {
    color: #3498db
}

.nav-classic .nav-link.active {
    color: #3498db;
    border-bottom-color: #3498db
}

.nav-borderless {
    border-color: transparent
}

.nav-rounded {
    border-radius: .25rem
}

.nav-rounded .nav-item:first-child .nav-link {
    border-bottom-left-radius: .25rem
}

.nav-rounded .nav-item:last-child .nav-link {
    border-bottom-right-radius: .25rem
}

.nav-shadow {
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25)
}

.nav .nav-item:not(:first-child) {
    margin-left: .25rem
}

.nav .nav-item:not(:last-child) {
    margin-right: .25rem
}

.nav-icon .nav-item {
    color: #6c757e
}

.nav-icon .nav-item.active {
    color: #3498db
}

.nav-icon .nav-item.active .nav-icon-action {
    color: #fff;
    background-color: #3498db;
    transform: scale(1)
}

.nav-icon-action {
    position: relative;
    display: block;
    vertical-align: middle;
    text-align: center;
    z-index: 1;
    line-height: .7;
    width: 3.25rem;
    height: 3.25rem;
    font-size: 1.11719rem;
    color: #77838f;
    background-color: rgba(119, 131, 143, .1);
    border-radius: 50%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: .5rem;
    transition: .3s ease-in-out;
    transform: scale(.8)
}

.nav-icon-action-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.u-blog-thumb-minimal {
    padding: .5rem;
    transition: .3s ease-in-out
}

.u-blog-thumb-minimal, .u-blog-thumb-minimal__img {
    border-radius: .25rem
}

.u-blog-thumb-minimal:hover {
    background-color: #196eff
}

.u-blog-thumb-minimal__img-wrapper {
    width: 5rem
}

.progress-vertical {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-flow: column nowrap;
    flex-flow: column nowrap;
    -ms-flex-pack: end;
    justify-content: flex-end;
    background-color: #f8fafd;
    height: 12.5rem
}

.u-paging-modern .u-paging-modern__arrow-icon-next, .u-paging-modern .u-paging-modern__arrow-icon-prev {
    transition: .3s
}

.u-paging-modern .u-paging-modern__arrow-icon-prev {
    margin-right: 1rem
}

.u-paging-modern .u-paging-modern__arrow-icon-next {
    margin-left: 1rem
}

.u-paging-modern:hover .u-paging-modern__arrow-icon-prev {
    transform: translateX(-8px)
}

.u-paging-modern:hover .u-paging-modern__arrow-icon-next {
    transform: translateX(8px)
}

.u-paging-modern-view-all {
    color: #fff;
    text-align: center;
    background-color: #db9a04
}

.u-paging-modern-view-all__icon {
    display: block;
    font-size: 1.625rem;
    margin-bottom: .5rem;
    transition: .3s
}

.u-paging-modern-view-all:hover {
    color: #fff
}

.u-paging-modern-view-all:hover .u-paging-modern-view-all__icon {
    transform: translateY(-8px)
}

.u-quantity {
    width: 4rem
}

.u-quantity > .u-quantity__input {
    padding-right: 1.875rem
}

.u-quantity > .u-quantity__input:not(:last-child) {
    border-radius: .25rem
}

.u-quantity__arrows {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: .75rem;
    z-index: 3
}

.u-quantity__arrows-inner {
    display: block;
    color: #77838f;
    cursor: pointer
}

.u-quantity__arrows-inner:hover {
    color: #3498db
}

.u-stats-progress {
    position: relative;
    width: 13.75rem;
    height: 13.75rem;
    border-radius: 50%;
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125);
    margin-left: auto;
    margin-right: auto
}

.u-stats-progress__info {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 2rem;
    text-align: center
}

@media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
    .u-header__promo-icon {
        height: 48px
    }

    .ie-height-40 {
        height: 40px
    }

    .ie-height-56 {
        height: 56px
    }

    .ie-height-72 {
        height: 72px
    }

    .ie-main-hero {
        height: 686px
    }

    .ie-soft-triangle-shape {
        height: 610px
    }

    .ie-browser {
        height: 565px
    }

    .ie-wave-1-bottom {
        height: 100px
    }

    .ie-easy-payment, .ie-responsive, .ie-vault {
        height: 210px
    }

    .ie-app-development, .ie-business-analysis, .ie-marketing-strategy {
        height: 328px
    }

    .ie-devices-v1-tablet {
        height: 38.5rem
    }

    .ie-devices-v1-phone {
        height: 27.5rem
    }

    .ie-devices-v2-tablet {
        height: 37.875rem
    }

    .ie-devices-v2-iphone {
        height: 31rem
    }

    .ie-devices-v3-iphone {
        height: 633px
    }

    .ie-devices-v4-tablet-horizontal {
        height: 16.875rem
    }

    .ie-devices-v4-tablet {
        height: 25.4375rem
    }

    .ie-devices-v4-laptop {
        height: 20.75rem
    }

    .ie-devices-v4-iphone {
        height: 13.0625rem
    }

    .ie-wave-6-top-left {
        height: 379px
    }

    .ie-double-ellipse-top-right {
        height: 431px
    }

    .ie-double-ellipse-top-left {
        height: 788px
    }

    .ie-double-ellipse-bottom-right {
        height: 252px
    }

    .ie-ellipse-mockup {
        height: 656px
    }

    .ie-irregular-shape-2-right {
        height: 660px
    }

    .ie-irregular-shape-3-bottom {
        height: 255px
    }

    .ie-circle-chart {
        height: 219px
    }

    .ie-curved-shape {
        height: 55.8px
    }

    .ie-subscribe-illustration {
        height: 329px
    }

    .ie-subscribe-1 {
        height: 315px
    }

    .ie-subscribe-2 {
        height: 295px
    }

    .ie-subscribe-2-flat-icons {
        height: 200px
    }

    .ie-color-gradient {
        height: 566px
    }

    .ie-buyer, .ie-for-sale {
        height: 208px
    }

    .ie-data-report, .ie-events, .ie-image-upload {
        height: 219px
    }

    .ie-analysis, .ie-in-the-office, .ie-make-it-rain {
        height: 200px
    }

    .ie-house-agency {
        height: 381px
    }

    .ie-laptop-and-iphone {
        height: 421px
    }

    .ie-get-answered {
        height: 386px
    }

    .ie-bg-elements-1 {
        height: 420px
    }

    .ie-bg-elements-2 {
        height: 374px
    }

    .ie-bg-elements-3 {
        height: 583px
    }

    .ie-bg-elements-4 {
        height: 850px
    }

    .ie-circle-1 {
        height: 379px
    }

    .ie-go-to-wave {
        height: 46px
    }

    .ie-graphic-illustration-1 {
        height: 270px
    }

    .ie-app-development {
        height: 328px
    }

    .ie-we-have-an-idea {
        height: 335px
    }

    .ie-chatting-boy, .ie-chatting-girl {
        height: 328px
    }

    .ie-virtual-reality {
        height: 320px
    }

    .ie-maintenance-mode {
        height: 200px
    }

    .ie-non-standard-hero-shape {
        height: 556px
    }

    .ie-enterprise-2 {
        height: 267px
    }

    .ie-abstract-shapes-1 {
        height: 554px
    }

    .ie-abstract-shapes-2, .ie-abstract-shapes-3, .ie-abstract-shapes-4 {
        height: 532px
    }

    .ie-abstract-shapes-6 {
        height: 187px
    }

    .ie-abstract-shapes-7 {
        height: 624px
    }

    .ie-abstract-shapes-9 {
        height: 182px
    }

    .ie-abstract-shapes-10 {
        height: 573px
    }

    .ie-showcase-mockup-1 {
        height: 384px
    }

    .ie-showcase-mockup-2 {
        height: 371px
    }

    .ie-showcase-mockup-3 {
        height: 535px
    }

    .ie-knowledgebase-community {
        height: 447px
    }

    .ie-knowledgebase-community-2 {
        height: 542px
    }
}

.table-heighlighted thead th {
    border-bottom-color: #1e2022;
    border-top: none
}

.table-heighlighted tfoot td {
    border-top: 2px solid #1e2022
}

[class*=sticky-block] {
    max-width: 100%
}

.gradient-half-primary-body-v1 {
    background-image: linear-gradient(150deg, #2d1582, #19a0ff);
    background-attachment: fixed
}

.gradient-half-primary-v1 {
    background-image: linear-gradient(150deg, #2d1582, #19a0ff);
    background-repeat: repeat-x
}

.gradient-half-primary-v2 {
    background-image: linear-gradient(0deg, rgba(52, 152, 219, .05), transparent);
    background-repeat: repeat-x
}

.gradient-half-primary-v3 {
    background-image: linear-gradient(0deg, rgba(52, 152, 219, .1), transparent);
    background-repeat: repeat-x
}

.gradient-half-primary-v4 {
    background-image: linear-gradient(150deg, #2d1582, #19a0ff 85%);
    background-repeat: repeat-x
}

.gradient-half-primary-v5 {
    background-image: linear-gradient(150deg, #3498db, #2d1582);
    background-repeat: repeat-x
}

.gradient-half-info-v1 {
    background-image: linear-gradient(0deg, #3498db, #3498db);
    background-repeat: repeat-x
}

.gradient-half-warning-v1 {
    background-image: linear-gradient(25deg, #db9a04 30%, #de4437);
    background-repeat: repeat-x
}

.gradient-half-warning-v2 {
    background-image: linear-gradient(150deg, #db9a04, #efa02e);
    background-repeat: repeat-x
}

.gradient-half-warning-v3 {
    background-image: linear-gradient(150deg, #db9a04, #de4437);
    background-repeat: repeat-x
}

[class*=gradient-overlay-half] {
    position: relative;
    z-index: 1
}

[class*=gradient-overlay-half]:before {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    content: ""
}

.gradient-overlay-half-primary-video-v1:before {
    z-index: 2
}

.gradient-overlay-half-primary-v1:before, .gradient-overlay-half-primary-video-v1:before {
    background-image: linear-gradient(150deg, rgba(45, 21, 130, .9), rgba(25, 160, 255, .85));
    background-repeat: repeat-x
}

.gradient-overlay-half-primary-v2:before {
    background-image: linear-gradient(30deg, rgba(25, 160, 255, .85), rgba(45, 21, 130, .9));
    background-repeat: repeat-x
}

.gradient-overlay-half-info-v1:before {
    background-image: linear-gradient(0deg, rgba(52, 152, 219, .92), rgba(52, 152, 219, .92));
    background-repeat: repeat-x
}

.gradient-overlay-half-dark-v1:before {
    background-image: linear-gradient(0deg, #1e2022, transparent 75%);
    background-repeat: repeat-x
}

.gradient-overlay-half-dark-v2:before {
    background-image: linear-gradient(150deg, rgba(34, 53, 66, .675), hsla(210, 8%, 46%, .3));
    background-repeat: repeat-x
}

.link, .link__icon {
    display: inline-block
}

.link__icon {
    position: relative;
    line-height: .7;
    vertical-align: middle;
    text-align: center;
    width: 1.375rem;
    height: 1.375rem;
    font-size: 1rem;
    color: #3498db;
    background-color: rgba(52, 152, 219, .1);
    border-radius: 50%;
    transition: .3s
}

.link__icon-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.link:hover .link__icon {
    background-color: #1d6fa5;
    transform: rotate(90deg)
}

.link-light {
    color: hsla(0, 0%, 100%, .7);
    border-bottom: 1px dashed hsla(0, 0%, 100%, .7)
}

.link-light:hover {
    color: #fff;
    border-color: hsla(0, 0%, 100%, .7)
}

.link-muted {
    color: #8c98a4;
    border-bottom: 1px dashed #97a4af
}

.link-muted:hover {
    border-color: #1d6fa5
}

.link-muted__toggle-default {
    display: inline-block
}

.link-muted.toggled .link-muted__toggle-default, .link-muted__toggle-toggled {
    display: none
}

.link-muted.toggled .link-muted__toggle-toggled {
    display: inline-block
}

.link-white {
    color: #fff;
    border-bottom: 1px dashed #fff
}

.link-white:hover {
    color: #fff
}

.fill-none {
    fill: none !important
}

.fill-white {
    fill: #fff !important
}

.fill-dark {
    fill: #1e2022 !important
}

.fill-primary {
    fill: #3498db !important
}

.fill-primary-lighter {
    fill: #19a0ff !important
}

.fill-primary-darker {
    fill: #196eff !important
}

.fill-success {
    fill: #00c9a7 !important
}

.fill-success-lighter {
    fill: #13d7b6 !important
}

.fill-info {
    fill: #3498db !important
}

.fill-danger {
    fill: #de4437 !important
}

.fill-danger-lighter {
    fill: #ec5245 !important
}

.fill-warning {
    fill: #db9a04 !important
}

.fill-warning-lighter {
    fill: #fdd14e !important
}

.fill-warning-darker {
    fill: #efa02e !important
}

.fill-gray-200 {
    fill: #f8fafd !important
}

.fill-gray-300 {
    fill: #e7eaf3 !important
}

.fill-gray-400 {
    fill: #bdc5d1 !important
}

.fill-gray-500 {
    fill: #97a4af !important
}

.stop-color-white {
    stop-color: #fff !important
}

.stop-color-primary {
    stop-color: #3498db !important
}

.stop-color-primary-darker {
    stop-color: #196eff !important
}

.stop-color-primary-lighter {
    stop-color: #19a0ff !important
}

.stop-color-warning {
    stop-color: #db9a04 !important
}

.stop-color-danger {
    stop-color: #de4437 !important
}

.stop-color-info {
    stop-color: #3498db !important
}

.stop-color-indigo {
    stop-color: #2d1582 !important
}

.stroke-white {
    stroke: #fff !important
}

.stroke-primary {
    stroke: #3498db !important
}

.stroke-primary-lighter {
    stroke: #19a0ff !important
}

.stroke-primary-darker {
    stroke: #196eff !important
}

.stroke-success {
    stroke: #00c9a7 !important
}

.stroke-danger {
    stroke: #de4437 !important
}

.stroke-warning {
    stroke: #db9a04 !important
}

.stroke-info {
    stroke: #3498db !important
}

.stroke-gray-200 {
    stroke: #f8fafd !important
}

.stroke-gray-300 {
    stroke: #e7eaf3 !important
}

.stroke-gray-400 {
    stroke: #bdc5d1 !important
}

.stroke-gray-500 {
    stroke: #97a4af !important
}

.bg-img-hero {
    background-position: top
}

.bg-img-hero, .bg-img-hero-bottom {
    background-size: cover;
    background-repeat: no-repeat
}

.bg-img-hero-bottom {
    background-position: bottom
}

.bg-img-hero-center, .bg-img-hero-fixed {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%
}

.bg-img-hero-fixed {
    background-attachment: fixed
}

.rounded-pill {
    border-radius: 6.1875rem
}

.rounded-top-pill {
    border-top-left-radius: 6.1875rem;
    border-top-right-radius: 6.1875rem
}

.rounded-right-pill {
    border-top-right-radius: 6.1875rem;
    border-bottom-right-radius: 6.1875rem
}

.rounded-bottom-pill {
    border-bottom-right-radius: 6.1875rem;
    border-bottom-left-radius: 6.1875rem
}

.rounded-left-pill {
    border-bottom-left-radius: 6.1875rem
}

.rounded-left-pill, .rounded-top-left-pill {
    border-top-left-radius: 6.1875rem
}

.rounded-bottom-left-pill {
    border-bottom-left-radius: 6.1875rem
}

.rounded-pseudo, .rounded-pseudo:after, .rounded-pseudo:before {
    border-radius: .25rem
}

.rounded-top-pseudo, .rounded-top-pseudo:after, .rounded-top-pseudo:before {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

.top-0 {
    top: 0
}

.right-0 {
    right: 0
}

.bottom-0 {
    bottom: 0
}

.left-0 {
    left: 0
}

@media (min-width: 768px) {
    .top-md-0 {
        top: 0
    }

    .right-md-0 {
        right: 0
    }

    .bottom-md-0 {
        bottom: 0
    }

    .left-md-0 {
        left: 0
    }

    .position-md-absolute {
        position: absolute
    }
}

@media (min-width: 992px) {
    .top-lg-0 {
        top: 0
    }

    .right-lg-0 {
        right: 0
    }

    .bottom-lg-0 {
        bottom: 0
    }

    .left-lg-0 {
        left: 0
    }

    .position-lg-absolute {
        position: absolute
    }
}

.content-centered-y {
    position: absolute;
    top: 50%;
    transform: translateY(-50%)
}

@media (min-width: 768px) {
    .content-centered-y--md {
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }
}

@media (min-width: 992px) {
    .content-centered-y--lg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%)
    }
}

.ml-n2 {
    margin-left: -.5rem
}

.ml-n3 {
    margin-left: -1rem
}

.mt-n1 {
    margin-top: -.25rem
}

.mt-n5 {
    margin-top: -2rem
}

.mt-n6 {
    margin-top: -2.5rem
}

.mt-n9 {
    margin-top: -4rem
}

.mt-n23 {
    margin-top: -10rem
}

.mb-n3 {
    margin-bottom: -1rem
}

.mb-n9 {
    margin-bottom: -4rem
}

.mx-gutters-2 {
    margin-right: -.5rem;
    margin-left: -.5rem
}

.mx-gutters-2 > .col, .mx-gutters-2 > [class*=col-] {
    padding-right: .5rem;
    padding-left: .5rem
}

.opacity-md {
    opacity: .2
}

.overflow-hidden {
    overflow: hidden
}

.shadow-primary-lg {
    box-shadow: 0 0 50px rgba(52, 152, 219, .4) !important
}

.shadow-soft {
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25) !important
}

.min-width-3 {
    min-width: 1rem !important
}

.max-width-6 {
    max-width: 2.5rem !important
}

.max-width-8 {
    max-width: 3.5rem
}

.max-width-9 {
    max-width: 4rem
}

.max-width-10 {
    max-width: 4.5rem
}

.max-width-15 {
    max-width: 7rem
}

.max-width-27 {
    max-width: 13rem
}

.max-width-35 {
    max-width: 17rem
}

.max-width-40 {
    max-width: 19.5rem
}

.height-40vh {
    height: 40vh
}

.height-60vh {
    height: 60vh
}

.height-100vh {
    height: 100vh
}

.height-380 {
    height: 23.75rem
}

.height-400 {
    height: 25rem
}

.height-450 {
    height: 28.125rem
}

.min-height-100vh {
    min-height: 100vh
}

.min-height-155 {
    min-height: 9.6875rem
}

.min-height-300 {
    min-height: 18.75rem
}

.min-height-380 {
    min-height: 23.75rem
}

.min-height-450 {
    min-height: 28.125rem
}

.min-height-550 {
    min-height: 34.375rem
}

.min-height-600 {
    min-height: 37.5rem
}

.min-height-620 {
    min-height: 38.75rem
}

@media (min-width: 576px) {
    .height-sm-100vh {
        height: 100vh
    }
}

@media (min-width: 768px) {
    .height-md-60vh {
        height: 60vh
    }

    .height-md-100vh {
        height: 100vh
    }

    .min-height-md-100vh {
        min-height: 100vh
    }
}

@media (min-width: 992px) {
    .height-lg-100vh {
        height: 100vh
    }

    .min-height-lg-auto {
        min-height: auto
    }

    .min-height-lg-100vh {
        min-height: 100vh
    }
}

@media (min-width: 1200px) {
    .min-height-xl-100vh {
        min-height: 100vh
    }
}

.font-size-1 {
    font-size: .71094rem
}

.font-size-2 {
    font-size: 1.21875rem
}

.font-size-3 {
    font-size: 1.625rem
}

.font-size-4 {
    font-size: 2.03125rem
}

.font-size-5 {
    font-size: 2.4375rem
}

@media (max-width: 991.98px) {
    .font-size-md-down-5 {
        font-size: 2.4375rem
    }
}

.font-weight-medium {
    font-weight: 500 !important
}

.font-weight-semi-bold {
    font-weight: 600 !important
}

.text-lh-sm {
    line-height: 1.2
}

.text-lh-md {
    line-height: 1.8
}

.text-white-70 {
    color: hsla(0, 0%, 100%, .7)
}

.text-white-70[href]:hover {
    color: #fff
}

.transition-3d-hover {
    transition: all .2s ease-in-out
}

.transition-3d-hover:focus, .transition-3d-hover:hover {
    transform: translateY(-3px)
}

.z-index-n1 {
    z-index: -1
}

.z-index-2 {
    z-index: 2
}

.z-index-4 {
    z-index: 4
}

@keyframes d {
    0% {
        opacity: 0;
        transform: translate3d(0, 25%, 0)
    }
    to {
        opacity: 1;
        transform: none
    }
}

.fadeInUp {
    animation-name: d
}

@keyframes e {
    0% {
        transform: translate3d(0, 10px, 0);
        visibility: visible
    }
    to {
        transform: translateZ(0)
    }
}

.slideInUp {
    animation-name: e
}

.u-cubeportfolio .u-cubeportfolio__item {
    font-size: .875rem;
    text-transform: capitalize;
    background-color: transparent;
    border: none;
    margin: 0;
    padding-left: .75rem;
    padding-right: .75rem
}

.u-cubeportfolio .u-cubeportfolio__item:first-child {
    padding-left: 0
}

.u-cubeportfolio .u-cubeportfolio__item:last-child {
    padding-right: 0
}

.u-cubeportfolio .u-cubeportfolio__item:hover {
    color: #1e2022
}

.u-cubeportfolio .u-cubeportfolio__item.cbp-filter-item-active {
    color: #3498db;
    background-color: transparent;
    border: transparent
}

.u-cubeportfolio .cbp-l-loadMore-stop {
    pointer-events: none;
    color: #77838f
}

.u-cubeportfolio .cbp-caption-zoom .cbp-caption-activeWrap {
    background-color: rgba(52, 152, 219, .9)
}

.u-cubeportfolio--reveal-v1 .cbp-l-caption-body {
    transform: translateY(-100%);
    transition: .4s ease-in-out
}

.u-cubeportfolio--reveal-v1 .cbp-caption:hover .cbp-l-caption-body {
    transform: translateY(0)
}

.custombox-lock {
    overflow: auto
}

.u-custombox-no-scroll.custombox-lock {
    margin-right: 1.0625rem;
    overflow: hidden
}

.u-datatable .dataTables_filter, .u-datatable .dataTables_info, .u-datatable .dataTables_length, .u-datatable .dataTables_paginate {
    display: none
}

.u-datatable__striped .odd {
    background-color: #f8fafd
}

.u-datatable .u-datatable__trigger {
    cursor: pointer
}

.u-datatable .u-datatable__trigger-icon:before {
    display: inline-block;
    font-family: Font Awesome\ 5 Pro;
    font-weight: 900;
    content: "\f107";
    margin-right: .75rem;
    transform: rotate(-180deg)
}

.u-datatable__content td[colspan] {
    padding-left: 0;
    padding-right: 0
}

.u-datatable .opened .u-datatable__trigger-icon:before {
    content: "\f106"
}

.u-datatable__thead-icon {
    display: block;
    line-height: .7;
    cursor: pointer;
    color: #bdc5d1
}

.u-datatable__thead-icon:hover {
    color: #3498db
}

.u-datepicker {
    position: relative
}

.u-datepicker .flatpickr-calendar {
    top: 3.75rem !important;
    left: 0 !important;
    box-shadow: 0 .375rem 1.5rem 0 rgba(140, 152, 164, .125)
}

.u-datepicker .flatpickr-calendar:after, .u-datepicker .flatpickr-calendar:before {
    display: none
}

.u-datepicker .flatpickr-months {
    position: relative;
    background-color: #3498db;
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    padding: .75rem
}

.u-datepicker .flatpickr-months .cur-month, .u-datepicker .flatpickr-months .cur-year {
    color: #fff
}

.u-datepicker .flatpickr-current-month, .u-datepicker .flatpickr-next-month, .u-datepicker .flatpickr-prev-month {
    padding: 0
}

.u-datepicker .flatpickr-next-month, .u-datepicker .flatpickr-prev-month {
    top: 50%;
    transform: translateY(-50%)
}

.u-datepicker .flatpickr-next-month.flatpickr-prev-month, .u-datepicker .flatpickr-prev-month.flatpickr-prev-month {
    left: .75rem
}

.u-datepicker .flatpickr-next-month.flatpickr-next-month, .u-datepicker .flatpickr-prev-month.flatpickr-next-month {
    right: .75rem
}

.u-datepicker .flatpickr-next-month svg, .u-datepicker .flatpickr-prev-month svg {
    fill: hsla(0, 0%, 100%, .7)
}

.u-datepicker .flatpickr-next-month:hover svg, .u-datepicker .flatpickr-prev-month:hover svg {
    fill: #fff
}

.u-datepicker .flatpickr-weekday {
    color: #1e2022;
    text-transform: uppercase;
    font-weight: 500
}

.u-datepicker .flatpickr-day {
    color: #1e2022;
    border-color: transparent
}

.u-datepicker .flatpickr-day:focus, .u-datepicker .flatpickr-day:hover {
    color: #3498db;
    background-color: rgba(52, 152, 219, .1)
}

.u-datepicker .flatpickr-day.inRange {
    background-color: #e7eaf3;
    box-shadow: -.3125rem 0 0 #e7eaf3, .3125rem 0 0 #e7eaf3
}

.u-datepicker .flatpickr-day.today {
    color: #fff;
    background-color: #3498db
}

.u-datepicker .flatpickr-day.nextMonthDay, .u-datepicker .flatpickr-day.prevMonthDay {
    color: #e7eaf3
}

.u-datepicker .flatpickr-day.nextMonthDay:focus, .u-datepicker .flatpickr-day.nextMonthDay:hover, .u-datepicker .flatpickr-day.prevMonthDay:focus, .u-datepicker .flatpickr-day.prevMonthDay:hover {
    color: #97a4af;
    background-color: #e7eaf3
}

.u-datepicker .flatpickr-day.disabled {
    color: #e7eaf3
}

.u-datepicker .flatpickr-day.disabled:hover {
    color: #97a4af;
    background-color: #e7eaf3
}

.u-datepicker .flatpickr-day.selected {
    border-color: #3498db
}

.u-datepicker .flatpickr-day.selected, .u-datepicker .flatpickr-day.selected:focus.nextMonthDay, .u-datepicker .flatpickr-day.selected:focus.prevMonthDay, .u-datepicker .flatpickr-day.selected:hover.nextMonthDay, .u-datepicker .flatpickr-day.selected:hover.prevMonthDay {
    color: #3498db;
    background-color: transparent
}

.u-datepicker .flatpickr-day.selected.endRange, .u-datepicker .flatpickr-day.selected.startRange {
    color: #fff;
    background-color: #3498db
}

.u-datepicker .numInputWrapper span.arrowDown, .u-datepicker .numInputWrapper span.arrowUp {
    display: none
}

.u-datepicker .numInputWrapper:hover {
    background-color: transparent
}

.u-datepicker--top .flatpickr-calendar {
    top: auto !important;
    bottom: 3.5rem !important
}

.u-dropzone {
    width: 100%;
    background-color: #f8fafd;
    border: 2px dashed #e7eaf3;
    border-radius: .25rem;
    cursor: pointer;
    padding: 3rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap
}

.u-dropzone .dz-message {
    width: 100%;
    text-align: center
}

.u-dropzone .dz-details {
    margin-bottom: 1rem
}

.u-dropzone .dz-file-preview {
    background-color: #fff;
    border-radius: .25rem;
    padding: 1rem;
    box-shadow: 0 3px 6px 0 rgba(140, 152, 164, .25)
}

.u-dropzone .dz-file-wrapper {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.u-dropzone .dz-filename {
    margin-bottom: .25rem
}

.u-dropzone .dz-img {
    max-width: 3.25rem;
    border: 1px solid #e7eaf3;
    border-radius: .25rem;
    padding: .25rem;
    margin-right: .75rem
}

.u-dropzone .dz-close-icon, .u-dropzone .dz-size {
    color: #77838f
}

.u-dropzone .dz-title {
    font-size: .60938rem;
    font-weight: 600
}

.u-dropzone .dz-size {
    font-size: 80%
}

.u-dropzone .dz-file-abbr {
    display: inline-block;
    width: 3.25rem;
    height: 3.25rem;
    line-height: 4;
    font-weight: 600;
    font-size: .8125rem;
    color: #3498db;
    text-align: center;
    background-color: rgba(52, 152, 219, .1);
    border-radius: .25rem;
    margin-right: .75rem
}

.u-dropzone [data-dz-thumbnail]:not([src]) {
    display: none;
    margin-bottom: 0
}

.u-dropzone .dz-progress {
    margin-bottom: 1rem
}

.u-dropzone .dz-processing .dz-error-mark, .u-dropzone .dz-processing .dz-success-mark, .u-dropzone.dz-started .u-dropzone__message {
    display: none
}

.u-dropzone .dz-processing.dz-error .dz-error-mark, .u-dropzone .dz-processing.dz-success .dz-success-mark {
    display: block
}

.u-dropzone .dz-processing .dz-error-mark {
    color: #de4437
}

.u-dropzone .dz-processing .dz-success-mark {
    color: #00c9a7
}

.u-fancybox-theme .fancybox-content {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0
}

.u-fancybox-theme .fancybox-slide--iframe .fancybox-content {
    position: static
}

.u-fancybox-theme .fancybox-bg {
    background-color: #1e2022
}

.fancybox-controls--canzoomIn .fancybox-placeholder, .fancybox-controls--canzoomOut .fancybox-placeholder {
    cursor: inherit
}

.fancybox-slide.has-animation {
    display: block
}

.fancybox-is-sliding .fancybox-slide.has-animation, .fancybox-slide--current.has-animation, .fancybox-slide--next.has-animation, .fancybox-slide--previous.has-animation {
    display: none
}

.fancybox-is-sliding .fancybox-slide.has-animation.animated, .fancybox-slide--current.has-animation.animated, .fancybox-slide--next.has-animation.animated, .fancybox-slide--previous.has-animation.animated {
    display: block
}

.u-blur-30 {
    filter: blur(30px)
}

.fileuploader {
    margin-top: 0
}

.u-fileuploader-input {
    display: block;
    cursor: pointer;
    background: #fff;
    border: 2px dashed #e7eaf3;
    border-radius: .25rem;
    padding: 2rem 0;
    text-align: center
}

.u-fileuploader-input__icon {
    display: block;
    color: #3498db;
    font-size: 2.5rem
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__icon, .u-fileuploader-input__inner {
    transition: all .3s ease-in-out
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__inner {
    opacity: .6
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__icon {
    transform: translateY(18px)
}

.u-fileuploader-input.fileuploader-dragging .u-fileuploader-input__btn {
    transform: none;
    opacity: 1
}

.dropdown-select.bootstrap-select li .dropdown-item.opt {
    padding-left: 1.5rem
}

.dropdown-select.bootstrap-select .bs-searchbox {
    padding-right: 1.5rem;
    padding-left: 1.5rem
}

.dropdown-select.bootstrap-select .dropdown-header .text {
    font-size: 80%;
    font-weight: 500;
    color: #1e2022;
    text-transform: uppercase
}

.dropdown-select .form-control {
    margin-bottom: .5rem
}

.note-modal-footer {
    height: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
    padding: 20px 30px
}

.note-modal-footer .note-btn {
    float: inherit
}

.u-summernote-editor .note-editor.note-frame {
    border-color: #e7eaf3
}

.u-summernote-editor .note-editor.note-frame .note-statusbar {
    background-color: #f8fafd;
    border-top-color: #e7eaf3
}

.u-summernote-editor .note-toolbar {
    background-color: #f8fafd
}

.u-summernote-editor .note-toolbar > .note-btn-group {
    border: 1px solid #e7eaf3;
    border-radius: .25rem
}

.u-summernote-editor .note-toolbar .note-dropdown-menu {
    min-width: 12.5rem;
    border-color: #e7eaf3;
    box-shadow: 0 0 35px rgba(140, 152, 164, .125);
    padding: .5rem
}

.u-summernote-editor .note-toolbar .note-color .note-dropdown-menu {
    min-width: 21.5rem
}

.u-summernote-editor .note-btn:focus, .u-summernote-editor .note-btn:hover {
    color: #3498db;
    background-color: transparent
}

.u-summernote-editor .note-dropdown-item:hover {
    background-color: #f8fafd
}

.u-tagsinput .bootstrap-tagsinput {
    width: 100%;
    border-color: #e7eaf3;
    border-radius: .25rem;
    box-shadow: none;
    padding: .25rem;
    padding-bottom: 0
}

.u-tagsinput .bootstrap-tagsinput:before {
    content: "|";
    display: inline-block;
    width: 1px;
    line-height: 1;
    font-size: .625rem;
    opacity: 0;
    padding: .75rem 0
}

.u-tagsinput .bootstrap-tagsinput .tag {
    position: relative;
    display: inline-block;
    font-size: .875rem;
    color: #77838f;
    background-color: rgba(119, 131, 143, .1);
    border-radius: .25rem;
    padding: .25rem 1.875rem .25rem .75rem;
    margin-bottom: .25rem;
    margin-right: 0
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role=remove] {
    position: absolute;
    right: .5rem;
    top: 50%;
    color: #77838f;
    font-size: 1.25rem;
    outline: 0;
    transform: translateY(-50%)
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role=remove]:after {
    content: "\00d7"
}

.u-tagsinput .bootstrap-tagsinput .tag [data-role=remove]:hover {
    color: #1e2022;
    box-shadow: none
}

.typed-cursor {
    opacity: 1;
    animation: f .7s infinite
}

@keyframes f {
    0% {
        opacity: 1
    }
    50% {
        opacity: 0
    }
    to {
        opacity: 1
    }
}

@media (max-width: 767.98px) {
    .container {
        padding-left: 15px;
        padding-right: 15px
    }
}

.h4, .h5, .h6, h4, h5, h6 {
    font-weight: 400
}

pre {
    padding: .75rem;
    border: 1px solid #e7eaf3;
    background-color: #f8f9fa;
    border-radius: .25rem;
    margin-bottom: .5rem
}

.heading-border {
    position: relative;
    padding-bottom: .5rem
}

.heading-border:after {
    position: absolute;
    bottom: 0;
    left: 0;
    content: "";
    width: 100%;
    border-bottom: 1px solid rgba(231, 234, 243, .2)
}

.search-result-title {
    font-size: 1.125rem
}

.text-size-1 {
    font-size: .875rem
}

.font-size-base {
    font-size: .8125rem !important
}

.text-size-address {
    font-size: 80%
}

@media (max-width: 991.98px) {
    .text-size-address {
        font-size: 73%
    }
}

@media (max-width: 767.98px) {
    .text-size-address {
        font-size: 67%
    }
}

@media (min-width: 576px) {
    .font-weight-sm-normal {
        font-weight: 400 !important
    }

    .font-weight-sm-bold {
        font-weight: 700 !important
    }
}

@media (max-width: 375px) {
    .d-sm-down-none {
        display: none !important
    }
}

.rounded-sm {
    border-radius: .125rem !important
}

.rounded-right-sm {
    border-top-right-radius: .125rem !important;
    border-bottom-right-radius: .125rem !important
}

@media (min-width: 768px) {
    .u-header__nav-separator {
        border-left: 1px solid #e7eaf3;
        width: 1px;
        height: 20px
    }

    .navbar-expand-md .u-header__nav-lang .u-header__nav-link {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 768px) {
    .u-header__nav-link-toggle--mobile:after {
        display: none
    }
}

.alert-dark {
    color: #3e444a;
    background-color: rgba(119, 131, 143, .2)
}

.table {
    margin-bottom: .25rem
}

.table td, .table th {
    white-space: nowrap
}

.table-md th {
    padding: .75rem
}

.table-md td {
    padding: 1rem .75rem
}

.table-text-normal td, .table-text-normal th {
    white-space: normal
}

@media (min-width: 768px) {
    .table-md-text-normal td, .table-md-text-normal th {
        white-space: normal
    }
}

.table-align-middle td, .table-align-middle th {
    vertical-align: middle
}

.table-hover tbody .tr-nonhover:hover {
    background-color: transparent
}

.card {
    box-shadow: 0 .5rem 1.2rem rgba(189, 197, 209, .2)
}

.card-header-title {
    color: #4a4f55;
    font-size: .8125rem;
    font-weight: 600;
    margin-bottom: 0
}

.card-pop-frame {
    border-top-width: 3px;
    border-top-color: #3498db
}

.collapsed .card-btn-arrow--up {
    transform: rotate(-180deg)
}

.card-arrow-more, .collapsed .card-arrow-less {
    display: none
}

.card-arrow-less, .collapsed .card-arrow-more {
    display: inline-block
}

.nav-custom .nav-link {
    color: rgba(74, 79, 85, .8);
    font-size: .8125rem;
    font-weight: 600;
    padding: .75rem;
    border-bottom: 2px solid transparent;
    border-radius: 0;
    transition: .3s;
    margin-bottom: -1px;
    margin-top: -1px
}

.nav-custom .nav-link:hover {
    color: #3498db
}

.nav-custom .nav-link.active {
    border-bottom-color: #5501FE;
}

.nav .nav-item {
    margin-right: .25rem;
    margin-left: .25rem;
    cursor: pointer
}

.nav .nav-item:first-child {
    margin-left: 0
}

.nav-pills-secondary .nav-item {
    margin-bottom: .25rem
}

.nav-pills-secondary .nav-link {
    padding: .25rem .5rem;
    border-radius: .125rem;
    border: 1px solid #d5dae2;
    color: #77838f
}

.nav-pills-secondary .nav-link:hover {
    color: #3498db
}

.nav-pills-secondary .nav-link.active, .nav-pills-secondary .show > .nav-link {
    background-color: #77838f;
    border-color: transparent
}

.nav-pills-secondary .nav-link.active:hover, .nav-pills-secondary .show > .nav-link:hover {
    color: #fff
}

@media (max-width: 767.98px) {
    .nav-classic-sm-down .nav-link {
        padding: .8rem .4rem
    }
}

.nav-page .nav-link {
    border-top-width: 2px;
    border-top-color: transparent
}

.nav-page .nav-link.active {
    border-top-color: #3498db
}

.u-indicator-vertical-dashed-item.u-indicator-vertical--solid-item:not(:last-child):after {
    border-left-style: solid;
    left: .8rem;
    bottom: -1rem
}

.form-control-sm {
    font-size: .8125rem;
    padding-left: .75rem;
    padding-right: .75rem
}

.form-control-plaintext.form-control-xs {
    padding-right: 0;
    padding-left: 0
}

.form-control-xs {
    height: calc(1.905rem + 2px);
    padding: .3rem .6rem;
    font-size: 95%
}

.form-control-xss {
    height: calc(1.5rem + 2px);
    padding: .2rem .4rem;
    font-size: 95%;
    border-radius: .125rem
}

.custom-select-xs {
    height: calc(1.75rem + 2px);
    padding: .375rem;
    padding-right: 1.15rem;
    font-size: 80%;
    background-position: right .375rem center;
    background-size: 7px 8px
}

.custom-select-xs.custom-select {
    width: auto
}

.custom-select-sm {
    padding-top: .58rem;
    padding-bottom: .65rem;
    border-radius: .125rem
}

.custom-arrow-select {
    background: #fff url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 151.717'%3E%3Cpath d='M115.285 147.693L4.025 36.433a13.739 13.739 0 0 1 0-19.431L17.002 4.025a13.74 13.74 0 0 1 19.408-.023l88.591 88.176 88.59-88.176a13.74 13.74 0 0 1 19.408.023l12.977 12.981a13.739 13.739 0 0 1 0 19.431l-111.26 111.256a13.741 13.741 0 0 1-19.432 0z' fill='%2377838f'/%3E%3C/svg%3E") no-repeat right 1rem center;
    background-size: 8px 15px
}

.custom-arrow-select:focus {
    border-color: #d5dae2
}

.input-group-sm .custom-arrow-select {
    padding-left: .6rem !important;
    padding-right: 2rem !important
}

.input-group-main .form-control {
    margin-right: 1px
}

.input-group-main .form-control:focus {
    box-shadow: none;
    border-color: #fff;
    border-left-color: #d5dae2
}

@media (max-width: 767.98px) {
    .input-group-main .form-control {
        border-top-left-radius: .125rem !important;
        border-bottom-left-radius: .125rem !important
    }
}

.form-control--focus-white:focus {
    border-color: #fff
}

.input-group-xs > .form-control, .input-group-xs > .input-group-append > .btn, .input-group-xs > .input-group-append > .input-group-text, .input-group-xs > .input-group-prepend > .btn, .input-group-xs > .input-group-prepend > .input-group-text {
    height: calc(1.905rem + 2px);
    padding: .4375rem .75rem;
    font-size: 75%
}

.input-group-xs.input-group-pill .input-group-prepend > .btn, .input-group-xs.input-group-pill .input-group-prepend > .input-group-text, .input-group-xs.input-group-pill > .form-control:first-child {
    padding-left: .75rem
}

.input-group-xs.input-group-pill .input-group-append > .btn, .input-group-xs.input-group-pill .input-group-append > .input-group-text {
    padding-right: .75rem
}

.input-group-xs > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group-xs > .custom-select:focus, .input-group-xs > .form-control:focus {
    z-index: 1
}

.input-group-sm > .form-control {
    padding-left: .65rem;
    padding-right: .65rem;
    font-size: .8125rem
}

.input-group-lg .input-group-append > .input-group-text, .input-group-lg .input-group-prepend > .input-group-text, .input-group-lg > .form-control, .input-group-text-size-lg .input-group-append > .input-group-text, .input-group-text-size-lg .input-group-prepend > .input-group-text, .input-group-text-size-lg > .form-control {
    font-size: 1.25rem
}

.discussion {
    display: inline-block;
    background-color: hsla(210, 8%, 46%, .1);
    color: hsla(0, 0%, 100%, .7);
    font-size: 80%;
    padding: .4375rem .875rem;
    transition: all .2s
}

.discussion:hover {
    color: #fff;
    background-color: hsla(210, 8%, 46%, .2)
}

.btn-group-sm > .btn, .btn-sm {
    font-size: .71094rem
}

.btn-warning {
    color: #fff
}

.btn-custom {
    color: #1e2022;
    font-size: .8125rem;
    border: 1px solid #d5dae2;
    background-color: transparent
}

.btn-custom-secondary {
    color: #77838f
}

.btn-custom-balance {
    width: 100%;
    padding-left: .6rem;
    padding-right: .6rem;
    text-align: left
}

.btn-custom-balance.dropdown-toggle:after {
    position: absolute;
    right: .6rem;
    top: 50%;
    transform: translateY(-50%);
    color: #77838f
}

.btn-custom-toggle {
    width: 100%;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    text-align: left
}

.btn-custom-toggle.dropdown-toggle:after {
    position: absolute;
    right: 1.125rem;
    top: 50%;
    transform: translateY(-50%);
    color: #77838f
}

.btn-outline-custom {
    color: #1e2022;
    background-color: #fff;
    border-color: #d5dae2
}

.btn-xs, .btn-xss {
    border-radius: .125rem
}

.btn-xss {
    font-size: .60938rem;
    padding: .3rem .6rem
}

.dropdown-balance {
    min-width: 262px
}

@media (min-width: 576px) {
    .dropdown-balance {
        min-width: 348px
    }
}

@media (min-width: 992px) {
    .dropdown-menu-lg-right {
        right: 0;
        left: auto !important
    }
}

.dropdown-toggle-change.active:after, .dropup .dropdown-toggle:after {
    content: "\f106"
}

.link-hover-secondary {
    color: #77838f
}

.link-hover-secondary:hover {
    color: #3498db
}

.link-hover {
    color: #1e2022;
    padding: .25rem .5rem
}

.link-hover, .link-hover__item {
    transition: all .1s ease-in-out
}

.link-hover__item {
    color: #77838f;
    display: block
}

.link-hover:hover .link-hover__item {
    color: #fff
}

.link-hover:hover {
    color: #fff;
    background-color: #3498db;
    border-radius: .125rem
}

.page-link {
    color: #3498db;
    transition: all .2s ease-in-out
}

.page-link, .page-link.page-item.disabled {
    background-color: rgba(52, 152, 219, .1)
}

.page-link:hover {
    background-color: #3498db;
    color: #fff
}

.page-item.disabled .page-link {
    background-color: rgba(52, 152, 219, .1)
}

.pagination .page-item:first-child {
    margin-left: 0
}

.pagination .page-item:last-child {
    margin-right: 0
}

.pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.bp-width {
    width: 120px
}

.u-label--xs {
    font-weight: 500
}

.u-label--price {
    background-color: rgba(119, 131, 143, .05);
    font-weight: 500;
    letter-spacing: .5px;
    font-size: .7rem;
    padding: .3rem .5rem
}

.u-label--value {
    font-size: .74094rem;
    padding: .3rem .5rem
}

.u-label--text-dark {
    color: #1e2022
}

.u-label--text-dark[type=button]:focus, .u-label--text-dark[type=button]:hover {
    color: #1e2022;
    background-color: rgba(119, 131, 143, .1)
}

.u-label--badge-out {
    position: relative;
    padding-right: .3rem;
    letter-spacing: .8px;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.u-label--badge-out:after {
    position: absolute;
    left: 100%;
    top: 0;
    content: "";
    border-top: .7rem solid transparent;
    border-bottom: .7rem solid transparent;
    border-left: .7rem solid rgba(119, 131, 143, .1);
    border-radius: .1rem
}

.u-label--badge-in {
    position: relative;
    padding-left: 9px;
    letter-spacing: .8px;
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.u-label--sm.u-label--badge-in {
    padding-left: 1.35rem
}

.u-label--sm.u-label--badge-in:after {
    border-top: .8rem solid transparent;
    border-bottom: .8rem solid transparent;
    border-left: .8rem solid #fff
}

.pos-top-n1 {
    position: relative;
    top: -1px
}

.copyright-bg {
    background-color: rgba(30, 32, 34, .99)
}

.hash-tag {
    max-width: 132px;
    display: inline-block;
    vertical-align: bottom
}

.hash-tag--xs {
    max-width: 130px
}

.hash-tag--md {
    max-width: 170px
}

.hash-tag--lg {
    max-width: 235px
}

@media (max-width: 575.98px) {
    .hash-tag-xs-down--md {
        max-width: 200px
    }
}

.custom-hash-tag {
    display: inline-block;
    vertical-align: bottom
}

@media (max-width: 767.98px) {
    .custom-hash-tag {
        max-width: 150px
    }
}

@media (min-width: 576px) {
    .custom-hash-tag {
        min-width: 230px
    }
}

@media (min-width: 992px) {
    .custom-hash-tag {
        max-width: 230px
    }
}

@media (min-width: 1200px) {
    .custom-hash-tag {
        max-width: 340px
    }
}

@media (min-width: 768px) {
    .mx-gutters-md-1 {
        margin-right: -1.5rem;
        margin-left: -1.5rem
    }

    .mx-gutters-md-1 > .col, .mx-gutters-md-1 > [class*=col-] {
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
}

@media (min-width: 992px) {
    .mx-gutters-lg-1 {
        margin-right: -1.5rem;
        margin-left: -1.5rem
    }

    .mx-gutters-lg-1 > .col, .mx-gutters-lg-1 > [class*=col-] {
        padding-right: 1.5rem;
        padding-left: 1.5rem
    }
}

.opacity-10 {
    opacity: .1
}

.opacity-15 {
    opacity: .15
}

.opacity-35 {
    opacity: .35
}

.opacity-40 {
    opacity: .5
}

.content-height {
    min-height: 550px
}

.hr-space {
    margin-top: .75rem;
    margin-bottom: .75rem;
    opacity: .75
}

.hr-space-xs {
    margin-top: .5rem;
    margin-bottom: .5rem;
    opacity: .75
}

.hr-space-lg {
    margin-top: 1.6rem;
    margin-bottom: 1.6rem;
    opacity: .75
}

@media (min-width: 768px) {
    .border-md-bottom {
        border-bottom: 1px solid #e7eaf3 !important
    }
}

.u-ver-divider--left:after {
    left: 0;
    border-left: 1px solid #e7eaf3;
    border-right: 0
}

.w-initial {
    width: auto
}

.bg-white-content {
    background-color: #fff
}

.bg-gray-750 {
    background-color: #586878
}

.bg-soft-primary {
    background-color: rgba(52, 152, 219, .1)
}

.bg-soft-secondary {
    background-color: rgba(119, 131, 143, .1)
}

.link-muted--primary {
    color: #3498db;
    border-color: #3498db
}

.link-muted--white {
    color: #fff;
    border-color: #fff
}

.link-muted--white:hover {
    color: hsla(0, 0%, 100%, .8);
    border-color: hsla(0, 0%, 100%, .8)
}

.text-break {
    word-break: break-word;
    white-space: normal
}

.text-break-all {
    word-break: break-all;
    white-space: normal
}

@media (min-width: 768px) {
    .text-md-break {
        word-break: break-word;
        white-space: normal
    }

    .text-md-nowrap {
        white-space: nowrap !important
    }
}

@media (min-width: 576px) {
    .text-sm-nowrap {
        white-space: nowrap !important
    }
}

.scrollbar-bottom-color {
    overflow: hidden;
    position: relative;
    padding-bottom: .3rem
}

.scrollbar-bottom-color:after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 1.5rem;
    background: linear-gradient(180deg, hsla(0, 0%, 100%, .5), hsla(0, 0%, 100%, .9))
}

.dl-space dl {
    margin-bottom: .75rem
}

.dl-space dl:last-child {
    margin-bottom: 0
}

.list-language .active {
    color: #3498db
}

.list-custom {
    border-bottom: 1px solid #e7eaf3;
    padding-bottom: .25rem;
    margin-bottom: .25rem
}

.list-custom--md {
    padding-bottom: 1rem;
    margin-bottom: 1rem
}

.list-custom:last-child {
    border-bottom: 0;
    padding-bottom: 0;
    margin-bottom: 0
}

.list-custom-divider {
    background-color: rgba(231, 234, 243, .5)
}

.list-xs-space > li {
    margin-bottom: .4rem
}

.list-xs-space > li:last-child {
    margin-bottom: 0
}

.list-sm-space > li {
    margin-bottom: .7rem
}

.list-sm-space > li:last-child {
    margin-bottom: 0
}

.list-md-space > li {
    margin-bottom: 1rem
}

.list-md-space > li:last-child {
    margin-bottom: 0
}

.list-group-sm .list-group-item {
    padding-top: .35rem;
    padding-bottom: .35rem
}

.list-group-lg .list-group-item {
    font-size: .75156rem;
    padding: .75rem
}

.gradient-half-primary-body-v1 {
    background-image: linear-gradient(150deg, #93c, #191129);
    background-repeat: repeat-x
}

.text-link {
    color: #1e2022
}

.text-link:hover {
    color: #3498db
}

.text-link-results {
    color: #208209 !important
}

.icon-star {
    color: #bdc5d1;
    cursor: pointer
}

.icon-star-active, .icon-star:hover {
    color: #e8b342
}

.u-md-avatar {
    width: 2.03125rem;
    height: 2.03125rem
}

.u-ether-avatar {
    width: 1.35rem;
    height: 1.35rem
}

.input-custom {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: -1
}

.input-custom:checked + .label-custom {
    color: #fff;
    background-color: #3498db
}

.label-custom {
    cursor: pointer !important
}

.label-custom--left:last-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.label-custom--right:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.label-custom:hover {
    color: #fff;
    background-color: #3498db
}

.event-day {
    color: #77838f;
    border: 2px solid #e7eaf3;
    border-radius: .25rem;
    text-align: center;
    padding: .25rem .5rem
}

.highcharts-container {
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif
}

.bootstrap-select-custom .btn-outline-custom {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

.bootstrap-select-custom__img {
    position: relative;
    top: -1px
}

.bootstrap-select-custom:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 70px
}

@media (min-width: 576px) {
    .bootstrap-select-custom:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
        min-width: 130px
    }
}

.bootstrap-select-text .dropdown-item {
    font-size: .8125rem
}

.bootstrap-select a.selected {
    color: #3498db
}

.bootstrap-select.show-tick span.check-mark {
    color: #bdc5d1 !important;
    font-size: 80% !important;
    top: 7px !important
}

.bootstrap-select > .dropdown-toggle.bs-placeholder {
    color: #1e2022 !important
}

.bootstrap-select.show > .dropdown-toggle.bs-placeholder, .bootstrap-select > .dropdown-toggle.bs-placeholder:active, .bootstrap-select > .dropdown-toggle.bs-placeholder:focus, .bootstrap-select > .dropdown-toggle.bs-placeholder:hover {
    color: #fff !important
}

.u-datepicker.u-datepicker--position-sm .flatpickr-calendar {
    top: 3rem !important
}

.range-slider {
    opacity: 0;
    height: 0
}

.cursor-pointer {
    cursor: pointer !important
}

.ml-n1 {
    margin-left: -.25rem
}

.my-n1 {
    margin-top: -.25rem;
    margin-bottom: -.25rem
}

@media (min-width: 768px) {
    .my-md-n1 {
        margin-top: -.25rem;
        margin-bottom: -.25rem
    }

    .mt-md-n9 {
        margin-top: -4rem
    }
}

.mb-card {
    margin-bottom: 15px
}

.wrapper-pmb-0 p {
    margin-bottom: 0
}

.bg-soft-secondary {
    background-color: rgba(119, 131, 143, .1) !important
}

.bg-soft-danger {
    background-color: rgba(222, 68, 55, .1) !important
}

.bg-soft-success {
    background-color: rgba(0, 201, 167, .1) !important
}

.color-strong {
    font-weight: 700
}

.color-strong.u-label--success {
    color: #02977e;
    background-color: rgba(0, 201, 167, .2)
}

.color-strong.u-label--warning {
    color: #b47d00;
    background-color: rgba(219, 154, 4, .2)
}

.color-strong.u-label--secondary {
    color: #626c76;
    background-color: rgba(119, 131, 143, .2)
}

@media (min-width: 768px) {
    .clipboard-hover__item {
        display: none
    }

    .clipboard-hover:hover .clipboard-hover__item {
        display: block
    }
}

.close.close-md {
    font-size: 1.5rem;
    font-weight: 400
}

.spinner-wrapper {
    position: absolute;
    content: " ";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: hsla(0, 0%, 100%, .8);
    z-index: 1
}

.spinner-wrapper__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1
}

@media (min-width: 768px) {
    .u-indicator-dots--steps:after {
        right: -1.1875rem
    }
}

.note-icon-caret {
    display: none
}

section.dark-mode {
    color: #fff;
    background-color: #190623 !important
}

section.dark-mode .h1 > a, section.dark-mode .h2 > a, section.dark-mode .h3 > a, section.dark-mode .h4 > a, section.dark-mode .h5 > a, section.dark-mode .h6 > a, section.dark-mode h1 > a, section.dark-mode h2 > a, section.dark-mode h3 > a, section.dark-mode h4 > a, section.dark-mode h5 > a, section.dark-mode h6 > a {
    color: #92adbf
}

section.dark-mode .h1 > a:hover, section.dark-mode .h2 > a:hover, section.dark-mode .h3 > a:hover, section.dark-mode .h4 > a:hover, section.dark-mode .h5 > a:hover, section.dark-mode .h6 > a:hover, section.dark-mode h1 > a:hover, section.dark-mode h2 > a:hover, section.dark-mode h3 > a:hover, section.dark-mode h4 > a:hover, section.dark-mode h5 > a:hover, section.dark-mode h6 > a:hover {
    color: #1d6fa5
}

section.dark-mode .h1.text-white > a, section.dark-mode .h2.text-white > a, section.dark-mode .h3.text-white > a, section.dark-mode .h4.text-white > a, section.dark-mode .h5.text-white > a, section.dark-mode .h6.text-white > a, section.dark-mode h1.text-white > a, section.dark-mode h2.text-white > a, section.dark-mode h3.text-white > a, section.dark-mode h4.text-white > a, section.dark-mode h5.text-white > a, section.dark-mode h6.text-white > a {
    color: #fff
}

section.dark-mode p {
    color: #a2b9c8
}

section.dark-mode .h1:not(.alert-heading), section.dark-mode .h2:not(.alert-heading), section.dark-mode .h3:not(.alert-heading), section.dark-mode .h4:not(.alert-heading), section.dark-mode .h5:not(.alert-heading), section.dark-mode .h6:not(.alert-heading), section.dark-mode h1:not(.alert-heading), section.dark-mode h2:not(.alert-heading), section.dark-mode h3:not(.alert-heading), section.dark-mode h4:not(.alert-heading), section.dark-mode h5:not(.alert-heading), section.dark-mode h6:not(.alert-heading) {
    color: #fff !important
}

section.dark-mode hr, section.dark-mode pre {
    /*border-color: #013558*/
}

section.dark-mode pre {
    color: #a2b9c8;
    background: #01263f
}

section.dark-mode .hs-mega-menu, section.dark-mode .hs-sub-menu, section.dark-mode .u-header__section {
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(94,36,123,1) 0%, rgba(92,26,159,1) 33%, rgba(85,0,255,1) 100%);
    box-shadow: none
}

section.dark-mode .u-header__nav-link, section.dark-mode .u-header__sub-menu-nav-link, section.dark-mode .u-header__sub-menu-title {
    color: #a2b9c8
}

section.dark-mode .u-header__nav-link:hover:not(.u-header__sub-menu-title), section.dark-mode .u-header__sub-menu-nav-link:hover:not(.u-header__sub-menu-title), section.dark-mode .u-header__sub-menu-title:hover:not(.u-header__sub-menu-title) {
    color: #3498db
}

section.dark-mode .u-header__nav-separator {
    border-color: #013558
}

section.dark-mode .nav-custom .nav-link {
    color: #fff
}

section.dark-mode .nav-custom .nav-link.active, section.dark-mode .nav-custom .nav-link:hover {
    color: #fff
}

section.dark-mode .nav-pills-secondary .nav-item {
    margin-bottom: .25rem
}

section.dark-mode .nav-pills-secondary .nav-link {
    border-color: #013558;
    color: #a2b9c8
}

section.dark-mode .nav-pills-secondary .nav-link:hover {
    color: #3498db
}

section.dark-mode .nav-pills-secondary .nav-link.active, section.dark-mode .nav-pills-secondary .show > .nav-link {
    background-color: rgba(52, 152, 219, .2)
}

section.dark-mode .nav-classic .nav-link, section.dark-mode .nav-pills-secondary .nav-link.active:hover, section.dark-mode .nav-pills-secondary .show > .nav-link:hover {
    color: #a2b9c8
}

section.dark-mode .close {
    color: rgba(162, 185, 200, .7);
    text-shadow: none
}

section.dark-mode .close:hover {
    color: #a2b9c8 !important
}

section.dark-mode .modal-content {
    background-color: #01263f
}

section.dark-mode .modal-footer, section.dark-mode .modal-header {
    border-color: #013558
}

section.dark-mode .card {
    border-color: #5a13b8 !important;
    background-color: #5b14b7 !important;
    box-shadow: 0 .5rem 1.2rem rgba(4, 76, 124, .2)
}

section.dark-mode .card-header {
    background-color: #5b14b7 !important;
    /*border-color: #013558*/
}

section.dark-mode .card-header-title {
    color: #a2b9c8
}

section.dark-mode .card-footer {
    background-color: #5b14b7 !important;
    /*border-color: #013558*/
}

section.dark-mode .card-btn {
    color: #a2b9c8
}

section.dark-mode .alert-text {
    color: #a2b9c8 !important
}

section.dark-mode .alert-info, section.dark-mode .alert-primary {
    color: #3498db !important;
    background-color: rgba(52, 152, 219, .1) !important
}

section.dark-mode .alert-success {
    color: #00c9a7 !important;
    background-color: rgba(0, 201, 167, .1) !important
}

section.dark-mode .alert-danger {
    color: #de4437 !important;
    background-color: rgba(222, 68, 55, .1) !important
}

section.dark-mode .alert-warning {
    color: #db9a04 !important;
    background-color: rgba(219, 154, 4, .1) !important
}

section.dark-mode .alert-secondary {
    background-color: #012137 !important;
    box-shadow: none !important
}

section.dark-mode .alert-dark {
    background-color: #01263f !important;
    box-shadow: none !important
}

section.dark-mode .alert-light {
    background-color: #012137 !important;
    box-shadow: none !important
}

section.dark-mode .u-label--secondary {
    color: #a2b9c8
}

section.dark-mode .u-label--dark {
    color: #a2b9c8;
    background: #012f4e
}

section.dark-mode .u-label--badge-in:after {
    border-left-color: #012137
}

section.dark-mode .list-custom-divider {
    color: #a2b9c8;
    background-color: #012137
}

section.dark-mode .color-strong.u-label--success {
    color: #00c9a7
}

section.dark-mode .color-strong.u-label--warning {
    color: #db9a04
}

section.dark-mode .list-group-item {
    background-color: transparent;
    border-color: #013558
}

section.dark-mode .list-group-item-action {
    color: #a2b9c8
}

section.dark-mode .list-group-item-action:focus, section.dark-mode .list-group-item-action:hover {
    color: #3498db;
    background-color: #012c49
}

section.dark-mode .list-group-item.active {
    color: #fff;
    background-color: #3498db
}

section.dark-mode .list-group-white .list-group-item, section.dark-mode .list-group-white .list-group-item-action {
    color: #a2b9c8
}

section.dark-mode .list-group-white .list-group-item-action[href], section.dark-mode .list-group-white .list-group-item[href] {
    color: rgba(162, 185, 200, .7)
}

section.dark-mode .list-group-white .list-group-item-action[href]:hover, section.dark-mode .list-group-white .list-group-item[href]:hover {
    color: #a2b9c8
}

section.dark-mode .list-group-white.list-group-striped .list-group-item:nth-child(2n) {
    background-color: rgba(162, 185, 200, .1)
}

section.dark-mode .list-group-white .active > .list-group-item {
    color: #a2b9c8
}

section.dark-mode .progress {
    background-color: #013558
}

section.dark-mode .u-indicator-vertical-dashed-item:not(:last-child):after {
    border-left-color: #013558
}

@media (min-width: 768px) {
    section.dark-mode .u-indicator-dots:after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 16'%3E %3Ccircle fill='rgba(52, 152, 219, 0.2)' cx='27.7' cy='8.1' r='7.7'/%3E %3Ccircle fill='rgba(52, 152, 219, 0.2)' cx='5' cy='8.1' r='5'/%3E %3Ccircle fill='rgba(52, 152, 219, 0.2)' cx='50' cy='8.1' r='5'/%3E %3C/svg%3E")
    }
}

section.dark-mode .btn-primary, section.dark-mode .btn-primary:not([href]), section.dark-mode .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: rgba(52, 152, 219, 0.4);
    border-color: transparent;
}

section.dark-mode .btn-primary, section.dark-mode .btn-primary:not([href]), section.dark-mode .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled):hover {
    box-shadow: none;
}

section.dark-mode .btn-primary:focus, section.dark-mode .btn-primary:hover, section.dark-mode .btn-primary:not([href]):focus, section.dark-mode .btn-primary:not([href]):hover, section.dark-mode .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled):focus, section.dark-mode .btn-primary:not([href]):not([href]):not(:disabled):not(.disabled):hover {
    color: #fff;
    background-color: #3498db
}

section.dark-mode .btn-soft-light[href].active, section.dark-mode .btn-soft-light[href]:active, section.dark-mode .btn-soft-light[href]:focus, section.dark-mode .btn-soft-light[href]:hover, section.dark-mode .btn-soft-light[type].active, section.dark-mode .btn-soft-light[type]:active, section.dark-mode .btn-soft-light[type]:focus, section.dark-mode .btn-soft-light[type]:hover {
    color: #a2b9c8;
    background: hsla(0, 0%, 100%, .2);
    box-shadow: none
}

section.dark-mode .btn-light {
    color: #a2b9c8;
    background: hsla(0, 0%, 100%, .2);
    border-color: #013558
}

section.dark-mode .btn-light:hover {
    box-shadow: 0 4px 11px hsla(0, 0%, 100%, .15)
}

section.dark-mode .btn-outline-secondary {
    color: #a2b9c8
}

section.dark-mode .btn-outline-secondary:hover {
    color: #fff
}

section.dark-mode .btn-custom {
    border-color: #013558
}

section.dark-mode .btn-custom, section.dark-mode .btn-soft-secondary {
    color: #a2b9c8
}

section.dark-mode .btn-custom-balance, section.dark-mode .btn-custom-secondary {
    border-color: rgba(52, 152, 219, .2)
}

section.dark-mode .btn-custom-balance:hover, section.dark-mode .btn-custom-secondary:hover {
    color: #3498db
}

section.dark-mode .btn-outline-custom {
    color: #a2b9c8;
    border-color: #013558;
    background: #01263f
}

section.dark-mode .btn-secondary:not([href]), section.dark-mode .btn-secondary:not([href]):not([href]):not(:disabled):not(.disabled) {
    background-color: #244464;
    border-color: #244464
}

section.dark-mode .u-go-to-modern {
    background-color: rgba(52, 152, 219, .2);
    color: #a2b9c8
}

section.dark-mode .dropdown-menu {
    background-color: #01263f
}

section.dark-mode .dropdown-item {
    color: #a2b9c8
}

section.dark-mode .dropdown-item.active, section.dark-mode .dropdown-item:hover {
    color: #3498db
}

section.dark-mode .dropdown-divider, section.dark-mode .list-custom {
    border-color: #013558
}

section.dark-mode .page-item.disabled .page-link {
    color: #fff
}

section.dark-mode .table td, section.dark-mode .table th {
    color: #a2b9c8;
    /*border-color: #013558*/
}

section.dark-mode .table .thead-light th {
    color: #5911be;
    /*background-color: #01263f !important*/
}

section.dark-mode .table-active, section.dark-mode .table-active > td, section.dark-mode .table-active > th, section.dark-mode .table-hover tbody tr:hover {
    background-color: transparent;
}

section.dark-mode .table-hover .table-active:hover, section.dark-mode .table-hover .table-active:hover > td, section.dark-mode .table-hover .table-active:hover > th {
    background-color: transparent;
}

section.dark-mode .form-control::-webkit-input-placeholder {
    color: #577c93
}

section.dark-mode .form-control::-moz-placeholder {
    color: #577c93
}

section.dark-mode .form-control::-ms-input-placeholder {
    color: #577c93
}

section.dark-mode .form-control::placeholder {
    color: #577c93
}

section.dark-mode .custom-select, section.dark-mode .form-control, section.dark-mode .input-group-text {
    color: #5911be !important;
}

section.dark-mode .u-has-error .form-control, section.dark-mode .u-has-error .input-group-text, section.dark-mode .u-has-error :focus, section.dark-mode .u-has-error:focus {
    border-color: rgba(222, 68, 55, .5) !important
}

section.dark-mode .u-has-success .form-control, section.dark-mode .u-has-success .input-group-text, section.dark-mode .u-has-success :focus, section.dark-mode .u-has-success:focus {
    border-color: rgba(0, 201, 167, .5) !important
}

section.dark-mode .custom-select {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='rgba(162, 185, 200, 0.9)' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
}

section.dark-mode .custom-arrow-select {
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 250 151.717'%3E%3Cpath d='M115.285 147.693L4.025 36.433a13.739 13.739 0 0 1 0-19.431L17.002 4.025a13.74 13.74 0 0 1 19.408-.023l88.591 88.176 88.59-88.176a13.74 13.74 0 0 1 19.408.023l12.977 12.981a13.739 13.739 0 0 1 0 19.431l-111.26 111.256a13.741 13.741 0 0 1-19.432 0z' fill='rgba(162, 185, 200, 0.9)'/%3E%3C/svg%3E")
}

section.dark-mode .checkbox-switch__slider {
    background-color: rgba(52, 152, 219, .2) !important
}

section.dark-mode .checkbox-switch__slider:before {
    background-color: #a2b9c8 !important
}

section.dark-mode .checkbox-switch__input:checked + .checkbox-switch__slider {
    background-color: #3498db !important
}

section.dark-mode .checkbox-switch__input:checked + .checkbox-switch__slider:before {
    background-color: #fff !important
}

section.dark-mode .custom-control-label:before {
    background-color: rgba(52, 152, 219, .2) !important
}

section.dark-mode .custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
    background-color: #3498db !important
}

section.dark-mode .scrollbar-bottom-color:after {
    background: linear-gradient(180deg, rgba(1, 38, 63, .1), #012137)
}

section.dark-mode .breadcrumb-item.active {
    color: #a2b9c8
}

section.dark-mode .link-muted {
    color: #7295ac;
    border-color: #7295ac
}

section.dark-mode .link-muted:hover {
    color: #3498db;
    border-color: #3498db
}

section.dark-mode .link-hover {
    color: #a2b9c8
}

section.dark-mode .link-hover__item {
    color: #7295ac
}

section.dark-mode .link-hover:hover {
    color: #fff
}

section.dark-mode .link-muted--white {
    color: rgba(162, 185, 200, .7);
    border-color: #a2b9c8
}

section.dark-mode .link-muted--white:hover {
    color: #a2b9c8
}

section.dark-mode .event-day {
    color: #a2b9c8;
    border-color: #013558
}

section.dark-mode .spinner-wrapper {
    background: rgba(1, 38, 63, .5)
}

section.dark-mode .icon-star {
    color: #a2b9c8
}

section.dark-mode .icon-star-active, section.dark-mode .icon-star:hover {
    color: #e8b342
}

section.dark-mode .highcharts-data-labels text, section.dark-mode .highcharts-legend tspan, section.dark-mode .highcharts-legend-navigation, section.dark-mode .highcharts-subtitle, section.dark-mode .highcharts-title, section.dark-mode .highcharts-yaxis text, section.dark-mode .highcharts-yaxis-title {
    color: #a2b9c8 !important;
    fill: #a2b9c8 !important
}

section.dark-mode tspan {
    text-decoration: none;
    text-shadow: none !important
}

section.dark-mode .highcharts-background {
    fill: transparent !important
}

section.dark-mode .highcharts-graph {
    stroke: #024471 !important
}

section.dark-mode .highcharts-axis-labels text, section.dark-mode .highcharts-tooltip text, section.dark-mode .highcharts-tooltip tspan {
    fill: #a2b9c8 !important
}

section.dark-mode .highcharts-tooltip-box {
    fill: rgba(52, 152, 219, .1);
    fill-opacity: .6;
    stroke-width: 0
}

section.dark-mode .highcharts-range-input text, section.dark-mode .highcharts-range-label text, section.dark-mode .highcharts-range-selector-buttons text {
    fill: #a2b9c8 !important
}

section.dark-mode .highcharts-crosshair-thin, section.dark-mode .highcharts-label-box, section.dark-mode .highcharts-tick, section.dark-mode .highcharts-xaxis .highcharts-axis-line {
    stroke: #013558 !important
}

section.dark-mode .highcharts-legend-item span {
    color: #a2b9c8 !important
}

section.dark-mode .highcharts-button-box {
    fill: rgba(52, 152, 219, .1) !important
}

section.dark-mode .highcharts-navigator-handle {
    fill: #012f4e !important
}

section.dark-mode .highcharts-navigator-handle, section.dark-mode .highcharts-navigator-outline {
    stroke: #024471 !important
}

section.dark-mode .highcharts-button-pressed .highcharts-button-box {
    fill: rgba(52, 152, 219, .2) !important
}

section.dark-mode .highcharts-range-selector {
    width: 95px !important;
    height: 22px !important;
    color: #a2b9c8 !important;
    background-color: #013558 !important;
    border-color: #013558 !important
}

section.dark-mode .highcharts-tooltip-box {
    fill: #013558 !important
}

section.dark-mode .highcharts-tooltip span {
    color: #a2b9c8 !important;
    background-color: #013558 !important
}

section.dark-mode .highslide-html {
    background-color: #013558 !important
}

section.dark-mode .highslide-body table.tokensummary td, section.dark-mode .highslide-body table.tokensummary th, section.dark-mode .highslide-heading, section.dark-mode .highslide-heading span {
    color: #a2b9c8
}

section.dark-mode #containerchart1 .highcharts-xaxis-grid path, section.dark-mode #containerchart2 .highcharts-yaxis-grid .highcharts-grid-line, section.dark-mode #containerchart3 .highcharts-yaxis-grid .highcharts-grid-line {
    stroke: #013558 !important
}

section.dark-mode #containerchart3 .highcharts-series path {
    fill: #01263f
}

section.dark-mode #containerchart4 .highcharts-yaxis-grid .highcharts-grid-line, section.dark-mode #containerchart5 .highcharts-yaxis-grid .highcharts-grid-line {
    stroke: #013558 !important
}

section.dark-mode #containerchart5 .highcharts-series path {
    fill: #01263f
}

section.dark-mode .mCSB_dragger_bar {
    background-color: rgba(119, 131, 143, .5) !important
}

section.dark-mode .bootstrap-select > .dropdown-toggle.bs-placeholder {
    color: #a2b9c8 !important
}

section.dark-mode .bootstrap-select.show > .dropdown-toggle.bs-placeholder {
    color: #fff !important
}

section.dark-mode .bootstrap-select .no-results {
    color: #a2b9c8;
    background: rgba(52, 152, 219, .2)
}

section.dark-mode .u-range-slider .irs-slider {
    background-color: #012137
}

section.dark-mode .u-range-slider .irs-line-left, section.dark-mode .u-range-slider .irs-line-mid, section.dark-mode .u-range-slider .irs-line-right {
    background-color: #013558
}

section.dark-mode .node text {
    fill: #a2b9c8
}

section.dark-mode #overlayMain {
    background: rgba(1, 38, 63, .4) !important
}

section.dark-mode .post-content .post-message p {
    color: #a2b9c8 !important
}

section.dark-mode .ace-dawn .ace_content, section.dark-mode .ace-dawn .ace_gutter {
    background: #01263f
}

section.dark-mode .ace-dawn .ace_gutter {
    color: #a2b9c8
}

section.dark-mode .ace-dawn.ace_focus .ace_gutter-cell {
    background: #012137
}

section.dark-mode .ace-dawn .ace_print-margin {
    background-color: #013558
}

section.dark-mode #codefund #cf, section.dark-mode #codefund .tooltiptext {
    color: #a2b9c8 !important
}

section.dark-mode #codefund #info-circle-light {
    fill: #a2b9c8 !important
}

section.dark-mode .u-dropzone {
    background-color: #01263f;
    border-color: #013558
}

section.dark-mode .u-dropzone .dz-file-preview {
    background-color: #012137;
    box-shadow: 0 3px 6px 0 rgba(4, 76, 124, .2)
}

section.dark-mode .u-dropzone .dz-close-icon, section.dark-mode .u-dropzone .dz-size {
    color: #a2b9c8
}

section.dark-mode .u-dropzone .dz-img {
    border-color: #013558
}

section.dark-mode .bootstrap-tagsinput {
    background-color: #01263f;
    border-color: #013558
}

section.dark-mode .bootstrap-tagsinput input, section.dark-mode .u-tagsinput .bootstrap-tagsinput .tag {
    color: #a2b9c8
}

section.dark-mode .u-tagsinput .bootstrap-tagsinput .tag [data-role=remove] {
    color: #7295ac
}

section.dark-mode .u-tagsinput .bootstrap-tagsinput .tag [data-role=remove]:hover {
    color: #a2b9c8
}

section.dark-mode .text-secondary {
    color: #fff !important
}

section.dark-mode .link-hover-secondary, section.dark-mode .text-dark, section.dark-mode .text-link, section.dark-mode .text-muted, section.dark-mode .text-white {
    color: #a2b9c8 !important
}

section.dark-mode .link-hover-secondary:hover, section.dark-mode .text-link.active, section.dark-mode .text-link:hover {
    color: #3498db !important
}

section.dark-mode .text-white-70 {
    color: rgba(162, 185, 200, .7)
}

section.dark-mode .text-white-70:hover {
    color: #a2b9c8
}

section.dark-mode .border, section.dark-mode .border-bottom, section.dark-mode .border-top, section.dark-mode .u-ver-divider--left:after, section.dark-mode .u-ver-divider:after {
    border-color: #013558 !important
}

section.dark-mode .bg-light {
    background-color: #0a0613 !important
}

section.dark-mode .bg-gray-750 {
    background-color: #012137 !important
}

section.dark-mode .bg-soft-secondary {
    background-color: rgba(52, 152, 219, .1)
}

section.dark-mode .bg-dark, section.dark-mode .bg-white-content {
    background-color: #012137 !important
}

section.dark-mode .gradient-half-primary-body-v1 {
    background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(94,36,123,1) 0%, rgba(92,26,159,1) 33%, rgba(85,0,255,1) 100%) !important
}

section.dark-mode .fill-white.curved-1-0 {
    fill: #012137 !important
}

section.dark-mode .VdKZR {
    border-top-color: #fff !important
}

section.dark-mode--blockscan a:hover {
    color: #3498db;
    opacity: .8
}

section.dark-mode--blockscan .page-link:hover {
    color: #fff;
    opacity: 1
}

section.dark-mode--blockscan .u-header__nav-link:hover:not(.u-header__sub-menu-title), section.dark-mode--blockscan .u-header__sub-menu-nav-link:hover:not(.u-header__sub-menu-title), section.dark-mode--blockscan .u-header__sub-menu-title:hover:not(.u-header__sub-menu-title) {
    opacity: 1
}

section.dark-mode #darkModaBtn {
    background-color: rgba(36, 5, 82, 0.33);
}

section.dark-mode a.btn {
    color: #fff;
    background-color: rgba(52, 152, 219, .1);
}

section.dark-mode a {
    color: #fff;
}

section.dark-mode a:hover {
    color: #ccc;
}

section.dark-mode .u-label--primary {
    color: #fff;
    background-color: #000;
}

section.dark-mode .btn-soft-info[type]:hover {
    background: transparent;
}
section.dark-mode .notfound .notfound-404 h2 {
    color: #000 !important;
}
